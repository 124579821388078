import { SportProvider } from '../../interfaces/SportProvider';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { Organization, OrganizationInvitation } from '../../interfaces/Organization';
import firebase from 'firebase';

export class FirebaseOrganizationInvitationTranslator
    implements FirebaseItemTranslator<OrganizationInvitation>
{
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    translate(snapshot, onSuccess, onFailure) {
        const { organization, email } = snapshot.val();
        this.sportProvider.resolverProvider
            .organizationResolver(organization)
            .asAPromise()
            .then((resolvedOrganization) => {
                const organizationInvitation: OrganizationInvitation = {
                    id: this.id,
                    email: email,
                    organization: resolvedOrganization,
                };
                onSuccess(organizationInvitation);
            })
            .catch((error) => {
                onFailure(
                    `failed to generate organization for invitation: ${this.id}, error: ${error}`
                );
            });
    }
}
