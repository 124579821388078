import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Competition } from '../../../models/interfaces/Competition';
import { Match } from '../../../models/interfaces/Match';
import { RosterEntry } from '../../../models/interfaces/RosterEntry';
import CompetitionLeadersView from '../../competitions/modules/CompetitionLeadersView';

interface Props {
    competition: Competition;
    matches?: Match[];
}

export default function EventCompetitionLeadersView({ competition }: Props) {
    const [competitionLeaders, setCompetitionLeaders] = useState<
        { title: string; players: { player: RosterEntry; text: string }[] }[] | undefined
    >(undefined);

    useEffect(() => {
        competition.leaderboardProvider
            .once()
            .then((leaderGroups) => {
                const leaderGroupPromises = leaderGroups.map((leaderGroup) => {
                    const playerSummaryPromises = leaderGroup.players.map((playerSummary) => {
                        return leaderGroup
                            .displayTextForStatResolvers(playerSummary.statResolvers)
                            .then((playerText) => {
                                return {
                                    player: playerSummary.player,
                                    text: playerText,
                                };
                            });
                    });
                    return Promise.allSettled(playerSummaryPromises)
                        .then((playerSummaryResults) => {
                            return playerSummaryResults.flatMap((playerSummaryResult) => {
                                if (playerSummaryResult.status === 'fulfilled') {
                                    return [playerSummaryResult.value];
                                } else {
                                    return [];
                                }
                            });
                        })
                        .then((inflatedPlayerSummaries) => {
                            return {
                                title: leaderGroup.title,
                                sortOrder: leaderGroup.sortOrder,
                                players: inflatedPlayerSummaries,
                            };
                        });
                });
                return Promise.all(leaderGroupPromises);
            })
            .then((inflatedLeaderGroups) => {
                setCompetitionLeaders(
                    inflatedLeaderGroups.sort((left, right) => {
                        return left.sortOrder - right.sortOrder;
                    })
                );
            });
    }, [competition]);
    return typeof competitionLeaders == 'undefined' ? (
        <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
    ) : competitionLeaders.length == 0 ? (
        <h5>
            <i>No leaders have been recorded for this division.</i>
        </h5>
    ) : (
        <CompetitionLeadersView competitionLeaders={competitionLeaders} />
    );
}
