import { Competition, CompetitionAttributes } from './Competition';
import { ListProvider } from './ListProvider';
import { PublicUser, User, WaterPoloAustraliaAdmin } from './User';
import { Resolver } from './Resolver';
import { EventRegistration } from './EventRegistration';
import { EventAdminInvitation } from './Events/EventAdminInvitation';
import { Team } from './Team';
import { TeamCategory, TeamDivision } from './TeamAttributes';
import { WaterPoloAustraliaEvent, WaterPoloAustraliaGame } from '../../pages/events/admin/WPA_support/WaterPoloAustraliaEventsPage';
import { Match } from './Match';
import { RevSportIntegration } from './RevSportIntegration';
import { EventVenue } from './Venue';

export interface UpdatableEventAttributes {
    name: string;
    competitionDateRange: DateRange;
}

export interface EventAttributes extends UpdatableEventAttributes {
    category?: TeamCategory;
    division?: TeamDivision;

    displayCompetitionDates: boolean;
}

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export interface Event {
    id: string;
    attributes: EventAttributes;

    competitionProvider: ListProvider<Resolver<Competition>>;
    enteredTeams: ListProvider<Resolver<Team>>;
    venues: ListProvider<Resolver<EventVenue>>;

    registration?: EventRegistration;

    redeemAdminInvitation: (token: string, user: User) => Promise<void>;
    adminForUser: (user: User) => Promise<EventAdmin>;
}

export function isEvent(candidate): candidate is Event {
    return (candidate as Event).attributes !== undefined;
}

export function isSameDay(left: Date, right: Date) {
    return (
        left.getDate() === right.getDate() &&
        left.getMonth() === right.getMonth() &&
        left.getFullYear() === right.getFullYear()
    );
}

export function sortEvents(left: Event, right: Event) {
    const leftHasDates = left.attributes.displayCompetitionDates;
    const rightHasDates = right.attributes.displayCompetitionDates;
    if (leftHasDates) {
        if (rightHasDates) {
            const leftStartDate = left.attributes.competitionDateRange.startDate;
            const rightStartDate = right.attributes.competitionDateRange.startDate;
            if (isSameDay(leftStartDate, rightStartDate)) {
                const leftEndDate = left.attributes.competitionDateRange.endDate;
                const rightEndDate = right.attributes.competitionDateRange.endDate;
                return leftEndDate < rightEndDate ? -1 : 1;
            } else {
                return leftStartDate < rightStartDate ? -1 : 1;
            }
        } else {
            return -1;
        }
    } else if (rightHasDates) {
        return 1;
    }
    if (left.attributes.name < right.attributes.name) {
        return -1;
    } else if (left.attributes.name > right.attributes.name) {
        return 1;
    } else {
        return 0;
    }
}

export enum ManageCompetitionError {
    unauthenticated,

    missingParameters,
    missingPermission,
    notFound,
    serverFailure,
}

export enum EventAdminError {
    unauthenticated,
    missingPermission,

    missingRequiredAttributes,
}

export enum EventRegistrationError {
    unauthenticated,
    missingPermission,

    serverFailure,
}

export enum EventExternalMatchSource {
    refTown,
    revSport,
    none,
}

export enum EventAdminPermission {
    canEditEvent = 'editEvent',
    canEditCompetitions = 'editCompetitions',
    canEditTeams = 'editTeams',
    canEditMatches = 'editMatches',
    canEditStats = 'editStats',
}

export interface EventAdmin {
    user: User;
    event: Event;
    externalMatchSource: EventExternalMatchSource;

    update: (attributes: UpdatableEventAttributes) => Promise<Event>;

    eventEditor?: EventEditor;
    competitionEditor?: EventCompetitionEditor;
    revSportIntegration?: RevSportIntegration;

    openRegistration: (maxNumberOfEntries: number, price?: number) => Promise<EventRegistration>;
    closeRegistration: () => Promise<void>;

    delete: () => Promise<void>;
}

export interface EventCompetitionEditor {
    addCompetition: (attributes: CompetitionAttributes) => Promise<Competition>;
    updateCompetition: (
        competition: Competition,
        attributes: CompetitionAttributes
    ) => Promise<Competition>;
    removeCompetition: (competition: Competition) => Promise<void>;
}

export interface EventEditor {
    adminProvider: ListProvider<Resolver<{ user: PublicUser; permission: EventAdminPermission }>>;
    adminInvitationProvider: ListProvider<Resolver<EventAdminInvitation>>;

    updateAdminPermission: (user: PublicUser, permission: EventAdminPermission) => Promise<void>;
    removeAdmin: (user: PublicUser) => Promise<void>;

    sendAdminInvitation: (
        email: string,
        permission: EventAdminPermission
    ) => Promise<EventAdminInvitation>;
    removeAdminInvitation: (invitation: EventAdminInvitation) => Promise<void>;
}