import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ResolverView from '../../components/containers/ResolverView';

import { User } from '../../models/interfaces/User';
import { SportProvider } from '../../models/interfaces/SportProvider';
import EventDetailPage from './EventDetailPage';
import { FeaturedEventView } from './featuredEvent/FeaturedEventView';
import { Container } from 'react-bootstrap';

interface Props {
    user?: User;
    sportProvider: SportProvider;
}
export default function EventDashboard({ user, sportProvider }: Props) {
    const eventRoute = useRouteMatch<{ eventID: string }>('/events/:eventID');
    if (eventRoute && eventRoute.params.eventID) {
        const eventID = eventRoute.params.eventID;
        return (
            <ResolverView
                resolver={sportProvider.eventResolver(eventID)}
                itemTranslator={(event) => {
                    return (
                        <Container>
                            <FeaturedEventView sportProvider={sportProvider} event={event} user={user}/>
                        </Container>
                    );
                }}
            />
        );
    } else {
        return <>Event not found</>;
    }
}

export function EmbeddableEventView({ sportProvider }: { sportProvider: SportProvider }) {
    const eventRoute = useRouteMatch<{ eventID: string }>('/embeddable/events/:eventID');
    if (eventRoute && eventRoute.params.eventID) {
        const eventID = eventRoute.params.eventID;
        return (
            <ResolverView
                resolver={sportProvider.eventResolver(eventID)}
                itemTranslator={(event) => {
                    return <EventDetailPage event={event} sportProvider={sportProvider} />;
                }}
            />
        );
    } else {
        return <>Invalid event ID</>;
    }
}
