import { useState, useEffect, useRef } from 'react';
import { Match, MatchStatus } from '../models/interfaces/Match';
import { MatchSegmentStats } from '../models/interfaces/Stat';

interface useMatchScoreProps {
    match: Match;
}

// leave this for use on team pages to see upcoming match & completed matches. Saves team pages from calling useMatchStats and asking the database for all stats when it only cares about the score. As we only display scores for completed matches this works great
export function useMatchScore({ match }: useMatchScoreProps) {
    const [lightTeamScore, setLightTeamScore] = useState<number | undefined>(undefined);
    const [darkTeamScore, setDarkTeamScore] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (match.status === MatchStatus.completed) {
            match.scoreForTeam(match.lightCapTeam).then((score) => {
                setLightTeamScore(score);
            });
        }
    }, [match]);

    useEffect(() => {
        if (match.status === MatchStatus.completed) {
            match.scoreForTeam(match.darkCapTeam).then((score) => {
                setDarkTeamScore(score);
            });
        }
    }, [match]);

    const finished = match.status === MatchStatus.completed ? true : false;

    const winningTeam = {
        light: !!(
            finished &&
            lightTeamScore !== undefined &&
            darkTeamScore !== undefined &&
            lightTeamScore > darkTeamScore
        ),
        dark: !!(
            finished &&
            lightTeamScore !== undefined &&
            darkTeamScore !== undefined &&
            lightTeamScore < darkTeamScore
        ),
    };
    return { lightTeamScore, darkTeamScore, winningTeam };
}

interface Props {
    match: Match;
    updateScoresImmediately: boolean;
}

export default function useMatchStats({ match, updateScoresImmediately }: Props) {
    const [matchStatsList, setMatchStats] = useState<MatchSegmentStats[] | undefined>(undefined);
    const [lightTeamScore, setLightTeamScore] = useState<number | undefined>(undefined);
    const [darkTeamScore, setDarkTeamScore] = useState<number | undefined>(undefined);
    const [updateStats, setUpdateStats] = useState<boolean>(false); //used to trigger updated stat list when stat is updated (as stat id doesn't change component currently does not re-render, this forcers a re-render with updated details)

    // useRef don't cause component re-render. used to keep track if match has updated for non admin users. Saves the API being called for completed matches just because time has passed
    const matchHasUpdatedRef = useRef(true);
    // this is state as we do want the hook to be called once the timeout is complete. If the matchHasUpdatedRef is also true at that time will request the API for new stats
    const [timeoutComplete, setTimeoutComplete] = useState<boolean>(true);

    // useEffect to get latest stats for NON-ADMINS, this delays any call for a period defined in setTimeout
    useEffect(() => {
        if (!updateScoresImmediately) {
            // only get past here if match has updated during the timeout period
            if (matchHasUpdatedRef.current && timeoutComplete) {
                // calls for latest match details
                match.statProvider.once().then((updatedMatchStats) => {
                    setMatchStats(updatedMatchStats);
                });

                // make matchHasUpdatedRef & timeoutComplete to be false
                matchHasUpdatedRef.current = false;
                setTimeoutComplete(false);

                // timeoutComplete to true after time period has completed
                setTimeout(() => {
                    setTimeoutComplete(true);
                    //timeout is in ms. Times by 1000 to get second to delay
                }, 1000 * 60);
            }
        }
    }, [match.statProvider, updateStats, updateScoresImmediately, timeoutComplete]);

    useEffect(() => {
        if (!updateScoresImmediately && !matchHasUpdatedRef.current) {
            // every time the match updates also update the ref it it was false. best to keep this separate from above logic as this will only change when match updates
            matchHasUpdatedRef.current = true;
        }
    }, [match, updateScoresImmediately]);

    // useEffect to get latest stats for ADMINS
    useEffect(() => {
        let listenerToken;
        if (updateScoresImmediately) {
            if (updateStats) {
                setUpdateStats(false);
            }

            listenerToken = match.statProvider.addListener((updatedMatchStats) => {
                setMatchStats(updatedMatchStats);
            });
        }

        return () => {
            listenerToken && match.statProvider.removeListener(listenerToken);
        };
    }, [match.statProvider, updateStats, updateScoresImmediately, timeoutComplete]);

    useEffect(() => {
        // score for team only working with completed match so lets not even try unless match is complete
        if (match.status === MatchStatus.completed) {
            match.scoreForTeam(match.lightCapTeam).then((score) => {
                score !== undefined && setLightTeamScore(score);
            });
        }
    }, [match]);

    useEffect(() => {
        if (match.status === MatchStatus.completed) {
            match.scoreForTeam(match.darkCapTeam).then((score) => {
                score !== undefined && setDarkTeamScore(score);
            });
        }
    }, [match]);

    function triggerUpdatedMatchStats() {
        setUpdateStats(true);
    }

    const finished = match.status === MatchStatus.completed ? true : false;

    // only used for completed matches so fine to use light & dark team score
    const winningTeam = {
        light: !!(
            finished &&
            lightTeamScore !== undefined &&
            darkTeamScore !== undefined &&
            lightTeamScore > darkTeamScore
        ),
        dark: !!(
            finished &&
            lightTeamScore !== undefined &&
            darkTeamScore !== undefined &&
            lightTeamScore < darkTeamScore
        ),
    };

    return {
        matchStatsList,
        lightTeamScore,
        darkTeamScore,
        winningTeam,
        triggerUpdatedMatchStats,
    };
}
