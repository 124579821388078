import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SportProvider } from '../../models/interfaces/SportProvider';
import { Player } from '../../models/interfaces/Player';
import { CapNumber, Name, Position, RosterEntry } from '../../models/interfaces/RosterEntry';
import CustomToolTip from '../../components/containers/CustomToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    sportProvider: SportProvider;
    editPlayer?: Player | RosterEntry;
    handleSubmit: (
        name: Name,
        capNumber: CapNumber,
        position: Position,
        resetValues: () => void,
        handleCancel: () => void,
        handleError: (message: any) => void
    ) => void;
    handleCancel?: () => void;
    resetEditPlayer: () => void;
    lockNamePolicy?: LockNamePolicy;
    addingPlayer: boolean;
}

interface LockNamePolicy {
    locked: boolean;
    message: string;
}

const schema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    number: yup.string().required('Number is required'),
    position: yup.string().required('Position is required'),
});

interface formValues {
    firstName: string;
    lastName: string;
    number: string;
    position: string;
}

const INITIALFORMVALUES: formValues = {
    firstName: '',
    lastName: '',
    number: '1',
    position: 'GK',
};

const PlayerFormView = ({
    sportProvider,
    editPlayer,
    handleSubmit,
    handleCancel,
    resetEditPlayer,
    lockNamePolicy,
    addingPlayer,
}: Props) => {
    const [addPlayerForm, setAddPlayerForm] = useState(false);

    // if a player has been provided to edit move form to edit mode
    useEffect(() => {
        if (!!editPlayer) {
            setAddPlayerForm(true);
        }
    }, [editPlayer, setAddPlayerForm]);

    return (
        <div className="py-2">
            {addPlayerForm ? (
                <PlayerForm
                    sportProvider={sportProvider}
                    editPlayer={editPlayer}
                    handleSubmit={handleSubmit}
                    handleCancel={() => {
                        setAddPlayerForm(false);
                        if (handleCancel) {
                            handleCancel();
                        }
                    }}
                    resetEditPlayer={resetEditPlayer}
                    lockNamePolicy={lockNamePolicy}
                    addingPlayer={addingPlayer}
                />
            ) : (
                <Button className="btn-block" onClick={() => setAddPlayerForm(true)}>
                    Add Player
                </Button>
            )}
        </div>
    );
};

export const PlayerForm = ({
    sportProvider,
    editPlayer,
    handleSubmit,
    handleCancel,
    resetEditPlayer,
    lockNamePolicy,
    addingPlayer,
}: Props) => {
    const positions = sportProvider.playerPositions;
    const numbers = sportProvider.playerCapNumbers;
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<formValues>(INITIALFORMVALUES);

    // if a player has been provided to edit move form to edit mode

    const lockName = lockNamePolicy ? lockNamePolicy.locked : false;
    useEffect(() => {
        if (!!editPlayer) {
            const { firstName, lastName } = editPlayer.name;

            setInitialValues({
                //if first and last name values are locked & they would fail the schema give them a blank value so that the validation will  pass and the form can submit
                firstName: lockName && firstName.length === 0 ? ' ' : firstName,
                lastName: lockName && lastName.length === 0 ? ' ' : lastName,
                number: editPlayer.capNumber.displayName,
                // Player does not have a position
                position: editPlayer.position.displayName,
            });
        } else {
            setInitialValues(INITIALFORMVALUES);
        }
    }, [editPlayer, lockNamePolicy]);

    const clearPlayer = useCallback(() => {
        // ensurer's that if you were editing a player that player is deselected and a new player can be added
        resetEditPlayer();
    }, [resetEditPlayer]);

    const firstNameRef = useRef<HTMLInputElement | null>(null);

    const resetFirstNameFocus = useCallback(() => {
        if (firstNameRef?.current) firstNameRef.current.focus();
    }, []);

    const handleError = useCallback((message: string) => {
        setErrorMessage(message);
    }, []);

    const handleOnSubmit = useCallback(
        (name: Name, capNumber: CapNumber, position: Position, resetForm: () => void) => {
            const resetValues = () => {
                resetForm();
                resetFirstNameFocus();
            };

            handleSubmit(name, capNumber, position, resetValues, clearPlayer, handleError);
        },
        [handleCancel, handleError, handleSubmit, resetFirstNameFocus]
    );

    const [showNameLockMessage, setShowNameLockMessage] = useState(false);

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={({ firstName, lastName, number, position }, actions) => {
                const capNumberObj = numbers.find((num) => num.displayName === number);
                const positionObj = positions.find((pos) => pos.displayName === position);

                if (capNumberObj && positionObj)
                    handleOnSubmit(
                        { firstName, lastName },
                        capNumberObj,
                        positionObj,
                        actions.resetForm
                    );
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <h5>
                                {editPlayer
                                    ? `Edit Player: ${editPlayer.name.firstName} ${editPlayer.name.lastName}`
                                    : 'Add New Player'}
                            </h5>
                        </Col>
                    </Row>
                    {lockNamePolicy?.message && (
                        <Form.Row>
                            <CustomToolTip
                                placement={'right'}
                                displayText={lockNamePolicy.message}
                                id={'cannot update player'}
                                show={showNameLockMessage}
                            >
                                <Button
                                    variant="outline-info"
                                    onClick={() => {
                                        setShowNameLockMessage(!showNameLockMessage);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faExclamationCircle as IconProp} /> Name
                                    update not supported
                                </Button>
                            </CustomToolTip>
                        </Form.Row>
                    )}

                    <Form.Row>
                        <Form.Group controlId="firstName" as={Col} sm={6}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                isValid={touched.firstName && !errors.firstName}
                                isInvalid={touched.firstName && !!errors.firstName}
                                placeholder="First name"
                                autoFocus
                                ref={firstNameRef}
                                readOnly={lockName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="lastName" as={Col} sm={6}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                isValid={touched.lastName && !errors.lastName}
                                isInvalid={touched.lastName && !!errors.lastName}
                                placeholder="Last name"
                                readOnly={lockName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="number" sm={6}>
                            <Form.Label>Number</Form.Label>
                            <Form.Control
                                as="select"
                                name="number"
                                value={values.number}
                                onChange={handleChange}
                                isValid={touched.number && !errors.number}
                                isInvalid={touched.number && !!errors.number}
                                placeholder="1"
                                custom
                            >
                                {numbers &&
                                    numbers.map((num) => (
                                        <option key={num.displayName}>{num.displayName}</option>
                                    ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.number}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                                as="select"
                                name="position"
                                value={values.position}
                                onChange={handleChange}
                                isValid={touched.position && !errors.position}
                                isInvalid={touched.position && !!errors.position}
                                placeholder="Attacker"
                                custom
                            >
                                {positions &&
                                    positions.map((position) => (
                                        <option key={position.displayName}>
                                            {position.displayName}
                                        </option>
                                    ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.position}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    {errorMessage}
                    <div className="d-flex justify-content-between">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                clearPlayer();
                                if (handleCancel) {
                                    handleCancel();
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={addingPlayer}>
                            {addingPlayer ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : !!editPlayer ? (
                                'Update Player'
                            ) : (
                                'Save Player'
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PlayerFormView;
