import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Card, Col, Row, ListGroup, Spinner, Button } from 'react-bootstrap';
import { Match, MatchStatus } from '../../models/interfaces/Match';

import MatchView from '../../components/containers/MatchView';
import MultiItemCarousel from '../../components/containers/MultiItemCarousel';

const HeaderFrame = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
`;

interface Props {
    matches: Match[] | undefined;
    accessoryView?: ReactElement;
    showCompetition: boolean;
    compressed?: boolean;
    toggleCompressed?: () => void;
}

export default function UpcomingMatchesView({
    matches,
    accessoryView,
    showCompetition,
    compressed,
    toggleCompressed,
}: Props) {
    let matchViews = matches
        ?.filter((match) => match.status !== MatchStatus.completed)
        .sort((a, b) => {
            let aDate = a.date;
            let bDate = b.date;
            if (aDate < bDate) {
                return -1;
            }
            return 0;
        })
        .map((match) => {
            return (
                <div className='upcomingMatch'>
                    <a href={`/matches/${match.id}`}>
                        <MatchView
                            key={match.id}
                            match={match}
                            showScore={match.status === MatchStatus.completed}
                            showCompetition={showCompetition}
                        />
                    </a>
                </div>
            );
        });

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <HeaderFrame>
                            Upcoming Matches {accessoryView}{' '}
                            {toggleCompressed && matchViews && matchViews.length > 1 && (
                                <Button
                                    variant="link"
                                    style={{ boxShadow: 'none' }}
                                    onClick={toggleCompressed}
                                >
                                    {compressed ? 'Expand' : 'Collapse'}
                                </Button>
                            )}
                        </HeaderFrame>
                    </Card.Header>
                    <>
                        {matches === undefined ? (
                            <Card.Body>
                                <Card.Text>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Card.Text>
                            </Card.Body>
                        ) : matchViews && matchViews.length > 0 ? (
                            <div className='upcomingMatchContainer'>
                                <MultiItemCarousel matches={matchViews} compressed={compressed} />
                            </div>
                        ) : (
                            <Card.Body>
                                <Card.Text>No matches scheduled</Card.Text>
                            </Card.Body>
                        )}
                    </>
                </Card>
            </Col>
        </Row>
    );
}
