import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ResolverView from '../../components/containers/ResolverView';

import { User } from '../../models/interfaces/User';
import { SportProvider } from '../../models/interfaces/SportProvider';
import CompetitionDetailPage from './CompetitionDetailPage';

interface Props {
    user?: User;
    sportProvider: SportProvider;
}
export default function CompetitionDashboard({ user, sportProvider }: Props) {
    const competitionRoute = useRouteMatch<{ competitionID: string }>(
        '/competitions/:competitionID'
    );
    if (competitionRoute && competitionRoute.params.competitionID) {
        const competitionID = competitionRoute.params.competitionID;
        return (
            <ResolverView
                resolver={sportProvider.competitionResolver(competitionID)}
                itemTranslator={(competition) => {
                    return (
                        <CompetitionDetailPage
                            user={user}
                            sportProvider={sportProvider}
                            competition={competition}
                        />
                    );
                }}
            />
        );
    } else {
        return <>Under Construction</>;
    }
}
