import { createContext, FunctionComponent, useState, useCallback, useContext } from 'react';
import { Toast } from 'react-bootstrap';

type BackgroundColor =
    | 'bg-primary'
    | 'bg-secondary'
    | 'bg-success'
    | 'bg-danger'
    | 'bg-warning'
    | 'bg-info'
    | 'bg-light'
    | 'bg-dark'
    | 'bg-body'
    | 'bg-white'
    | 'bg-transparent';

interface CreateToastProps {
    message: string;
    delay?: number;
    backgroundColor?: BackgroundColor;
    autohide?: boolean;
}

interface IToastContext {
    createToast: ({ message, delay, backgroundColor }: CreateToastProps) => void;
    hideToast: () => void;
}

const ToastContext = createContext<IToastContext>({
    createToast: () => null,
    hideToast: () => null,
});

export const ToastProvider: FunctionComponent = ({ children }) => {
    const [message, setMessage] = useState<null | string>(null);
    const [show, setShow] = useState(false);
    const [delay, setDelay] = useState(3000);
    const [autohide, setAutohide] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState<BackgroundColor>('bg-white');

    const hideToast = useCallback(() => {
        setShow(false);
    }, []);

    const createToast = useCallback(
        ({ message, delay, backgroundColor, autohide }: CreateToastProps) => {
            setMessage(message);
            delay ? setDelay(delay) : setDelay(3000);
            backgroundColor ? setBackgroundColor(backgroundColor) : setBackgroundColor('bg-white');
            autohide === false ? setAutohide(false) : setAutohide(true);
            setShow(true);
        },
        []
    );

    return (
        <ToastContext.Provider value={{ createToast, hideToast }}>
            <>
                {children}
                <Toast
                    onClose={hideToast}
                    show={show}
                    delay={delay}
                    autohide={autohide}
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                    }}
                    className={backgroundColor}
                >
                    <Toast.Header>{message}</Toast.Header>
                </Toast>
            </>
        </ToastContext.Provider>
    );
};

export function useToast() {
    return useContext(ToastContext);
}
