import { useState } from 'react';
import { Col, Row, Table, Spinner } from 'react-bootstrap';
import { CompetitionEntryAdmin } from '../../models/interfaces/Competitions/CompetitionEntry';
import { RosterEntry } from '../../models/interfaces/RosterEntry';
import { SportProvider } from '../../models/interfaces/SportProvider';
import PlayerFormView from '../teams/PlayerForm';

interface Props {
    sportProvider: SportProvider;
    competitionEntryAdmin: CompetitionEntryAdmin;
    playerList: RosterEntry[] | undefined;
}

export default function CompetitionEntryRosterForm({
    sportProvider,
    competitionEntryAdmin,
    playerList,
}: Props) {
    return (
        <Col>
            <Row>
                <Col>
                    {playerList ? (
                        <PlayerTable
                            sportProvider={sportProvider}
                            playerList={playerList}
                            competitionEntryAdmin={competitionEntryAdmin}
                        />
                    ) : (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </Col>
            </Row>
        </Col>
    );
}

interface PlayerTableProps {
    sportProvider: SportProvider;
    playerList: RosterEntry[];
    competitionEntryAdmin: CompetitionEntryAdmin;
}

function PlayerTable({ sportProvider, playerList, competitionEntryAdmin }: PlayerTableProps) {
    const [submittingUpdate, setSubmittingUpdate] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<RosterEntry | undefined>(undefined);
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        <Row className="align-items-center">
                            <Col>
                                <h3>Players</h3>
                                <>Click a player to edit</>
                            </Col>
                            <Col xs="auto">
                                <PlayerFormView
                                    sportProvider={sportProvider}
                                    resetEditPlayer={() => {}}
                                    addingPlayer={submittingUpdate}
                                    handleSubmit={(
                                        name,
                                        capNumber,
                                        position,
                                        resetValues,
                                        handleCancel,
                                        handleError
                                    ) => {
                                        setSubmittingUpdate(true);
                                        competitionEntryAdmin
                                            .addRosterEntry(name, capNumber, position)
                                            .then(() => {
                                                resetValues();
                                            })
                                            .catch((error) => {
                                                handleError(error);
                                            })
                                            .finally(() => {
                                                setSubmittingUpdate(false);
                                            });
                                    }}
                                />
                            </Col>
                        </Row>
                    </th>
                </tr>
            </thead>
            <tbody>
                {playerList.map((player) => (
                    // capNumber needs to be included in the key so that when a new number is submitted the row will also update
                    <tr
                        key={`${player.id}-${player.capNumber.databaseValue}`}
                        onClick={() => {
                            if (player.id !== selectedPlayer?.id) {
                                setSelectedPlayer(player);
                            }
                        }}
                    >
                        <td>
                            {selectedPlayer && selectedPlayer.id == player.id ? (
                                <PlayerFormView
                                    sportProvider={sportProvider}
                                    lockNamePolicy={
                                        selectedPlayer.backingPlayer
                                            ? {
                                                  locked: true,
                                                  message: `The name has been set by a coach or player. To have a different player at #${selectedPlayer.capNumber.displayName}, please change this player's number and then create a new player.`,
                                              }
                                            : undefined
                                    }
                                    addingPlayer={submittingUpdate}
                                    editPlayer={selectedPlayer}
                                    resetEditPlayer={() => {
                                        setSelectedPlayer(undefined);
                                    }}
                                    handleSubmit={(
                                        name,
                                        capNumber,
                                        position,
                                        resetValues,
                                        handleCancel,
                                        handleError
                                    ) => {
                                        setSubmittingUpdate(true);
                                        competitionEntryAdmin
                                            .updateRosterEntry(
                                                selectedPlayer,
                                                name,
                                                capNumber,
                                                position,
                                                selectedPlayer.externalID
                                            )
                                            .then(() => {
                                                resetValues();
                                                setSelectedPlayer(undefined);
                                            })
                                            .catch((error) => {
                                                handleError(error);
                                            })
                                            .finally(() => {
                                                setSubmittingUpdate(false);
                                            });
                                    }}
                                />
                            ) : (
                                <>
                                    {player.capNumber.displayName}. {player.name.firstName}{' '}
                                    {player.name.lastName}
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
