import { Dropdown, Spinner } from 'react-bootstrap';
import { MatchEntry } from '../../../models/interfaces/MatchEntry';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import styled from 'styled-components';
import { CompetitionEntry } from '../../../models/interfaces/Competitions/CompetitionEntry';

const StyledDropDownItem = styled(Dropdown.Item)`
    border-left: solid 0.3rem ${({ color }) => color};
    margin-left: 1rem;
    width: calc(100% - 1rem);
`;
const StyledDropDownToggle = styled(Dropdown.Toggle)`
    white-space: normal;
`;
interface Props {
    teams: CompetitionEntry[] | undefined;
    handleSelect: (value: CompetitionEntry) => void;
    title: string;
    disabled?: boolean;
    dropdownId?: string;
}

const SelectTeamDropDown = ({ teams, handleSelect, title, disabled, dropdownId }: Props) => {
    if (!teams) return <Spinner animation="border" />;
    return (
        <Dropdown>
            <StyledDropDownToggle id={dropdownId ?? ''} disabled={disabled}>{title}</StyledDropDownToggle>
            <Dropdown.Menu>
                {teams.map((team, index) => {
                    return (
                        <StyledDropDownItem
                            id={`${`${dropdownId}-` ?? ''}team-${index}`}
                            key={team.id}
                            onSelect={() => handleSelect(team)}
                            color={parseTeamColor(team.color)}
                        >
                            {team.name}
                        </StyledDropDownItem>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SelectTeamDropDown;
