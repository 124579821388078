import React from 'react';
import styled from 'styled-components';
import { Match, MatchStatus } from '../../models/interfaces/Match';
import { Row, Col, Container } from 'react-bootstrap';
import { useMatchScore } from '../../helpers/useMatchStats';
import { parseTeamColor } from '../../helpers/parseTeamColor';

interface Props {
    match: Match;
    showScore: boolean;
    showCompetition: boolean;
}

const DateLabel = styled.div`
    white-space: nowrap;
`;

const StyledRow = styled(Row)<{ color: string }>`
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: inset 0.3rem 0 0 0 ${({ color }) => color};
    padding: 0 1rem;
`;

const StyledCol = styled(Col)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export default function MatchView({ match, showScore, showCompetition }: Props) {
    //custom hook
    const { lightTeamScore, darkTeamScore, winningTeam } = useMatchScore({ match });

    const inProgressRow =
        match.status === MatchStatus.inProgress ? (
            <Row noGutters className="justify-content-center">
                <Col xs="auto">
                    <i>
                        <strong>In Progress</strong>
                    </i>
                </Col>
            </Row>
        ) : null;

    const showCompetitionRow = showCompetition ? (
        <Row noGutters className="justify-content-center mb-1">
            <Col xs="auto">
                <div className="d-flex justify-content-center text-muted">
                    {match.competition?.attributes.name}
                </div>
            </Col>
        </Row>
    ) : null;

    const inProgressOffset = inProgressRow || match.status === MatchStatus.completed ? '' : 'my-3';

    return (
        <Container key={match.id} fluid className="my-1 px-0">
            {inProgressRow}
            {showCompetitionRow}
            <Row noGutters className={inProgressOffset}>
                <Col xs={9} className="justify-content-center">
                    <StyledRow color={parseTeamColor(match.lightCapTeam.color)} noGutters>
                        <StyledCol>
                            <span
                                className={`font-weight-${winningTeam.light ? 'bold' : 'normal'}`}
                            >
                                {match.lightCapTeam.name}
                            </span>
                        </StyledCol>
                        {showScore && (
                            <Col
                                className={`px-0 font-weight-${
                                    winningTeam.light ? 'bold' : 'normal'
                                }`}
                                xs="auto"
                            >
                                {lightTeamScore}
                            </Col>
                        )}
                    </StyledRow>
                    <StyledRow
                        color={parseTeamColor(match.darkCapTeam.color)}
                        className="justify-content align-items-center"
                        noGutters
                    >
                        <StyledCol>
                            <span className={`font-weight-${winningTeam.dark ? 'bold' : 'normal'}`}>
                                {match.darkCapTeam.name}
                            </span>
                        </StyledCol>
                        {showScore && (
                            <Col
                                className={`px-0 font-weight-${
                                    winningTeam.dark ? 'bold' : 'normal'
                                }`}
                                xs="auto"
                            >
                                {darkTeamScore}
                            </Col>
                        )}
                    </StyledRow>
                </Col>
                <Col lg={3} className="pl-3 pl-lg-0">
                    <Row>
                        <Col>
                            <DateLabel className="text-lg-right">
                                {match.date.toLocaleDateString()}
                            </DateLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DateLabel className="text-lg-right">
                                {match.date.toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </DateLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
