import { useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { Competition, isCompetition } from '../../../models/interfaces/Competition';
import { Event } from '../../../models/interfaces/Event';
import useListProviderToArray from '../../../helpers/useListProviderToArray';
import { SportProvider } from '../../../models/interfaces/SportProvider';

interface Props {
    event: Event;
    sportProvider: SportProvider;
    handleSelectCompetition: (competition: Competition) => void;
}

const SelectCompetition = ({ event, sportProvider, handleSelectCompetition }: Props) => {
    const competitionArray = useListProviderToArray({
        provider: event.competitionProvider,
        isType: isCompetition,
    });

    useEffect(() => {
        // if only one competition just accept that competition and move on
        if (competitionArray?.length === 1) {
            handleSelectCompetition(competitionArray[0]);
        }
    }, [competitionArray, handleSelectCompetition]);

    if (!competitionArray)
        return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

    if (competitionArray.length === 0)
        return <div>No Competitions for {event.attributes.name} found</div>;

    return (
        <>
            <div>Select Competition</div>
            <ListGroup className="d-flex flex-grow-1">
                {competitionArray.map((competition) => {
                    const attributes = competition.attributes;
                    return (
                        <ListGroup.Item
                            key={competition.id}
                            action
                            onClick={() => {
                                handleSelectCompetition(competition);
                            }}
                        >
                            <h6>
                                {attributes.name !== event.attributes.name
                                    ? `${attributes.name} - `
                                    : ''}
                                {`${sportProvider.displayTextForTeamOrCompetitionAttributes(
                                    attributes
                                )} `}
                            </h6>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </>
    );
};

export default SelectCompetition;
