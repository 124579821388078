
import TeamView from '../../../components/containers/TeamView';
import { TeamRecord } from '../../../models/interfaces/Competition';

interface TeamRecordViewProps {
    teamRecord: TeamRecord;
}

const TeamRecordView = ({ teamRecord }: TeamRecordViewProps) => {
    return (
        <TeamView
            name={teamRecord.team.name}
            accessoryView={
                <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                    {teamRecord.wins} - {teamRecord.losses}
                    {teamRecord.draws > 0 ? ` - ${teamRecord.draws}` : null}
                </div>
            }
        />
    );
};

export default TeamRecordView;
