import { CompetitionPlayerStatDescription } from '../../../interfaces/ViewDescriptions/MatchViewDescription';
import { StatTypeDescription, PerformerTypes } from '../../../interfaces/Stat';
import { WaterPoloGoal, WaterPoloSave } from './WaterPoloStats';

export const WaterPoloCompetitionLeaderDescriptions: CompetitionPlayerStatDescription[] = [
    {
        id: 'offensiveLeaders',
        title: 'Offensive Leaders',
        sortOrder: 0,
        weights: [
            {
                statDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloGoal
                ),
                weight: 1,
            },
        ],
        textFormatter: (statResolvers) => {
            return Promise.resolve(`${statResolvers.length}G`);
        },
    },
    {
        id: 'defensiveLeaders',
        title: 'Defensive Leaders',
        sortOrder: 1,
        weights: [
            {
                statDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloSave
                ),
                weight: 1,
            },
        ],
        textFormatter: (statResolvers) => {
            return Promise.resolve(`${statResolvers.length}S`);
        },
    },
];
