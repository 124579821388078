import { CompetitionEntry } from '../../../../interfaces/Competitions/CompetitionEntry';
import { Competition } from '../../../../interfaces/Competition';
import { Team } from '../../../../interfaces/Team';
import { TeamColor } from '../../../../interfaces/TeamAttributes';
import { ListProvider } from '../../../../interfaces/ListProvider';
import { Resolver } from '../../../../interfaces/Resolver';
import { Match } from '../../../../interfaces/Match';
import { Player } from '../../../../interfaces/Player';
import { RosterEntry } from '../../../../interfaces/RosterEntry';
import { ListProviderMapping, ResolverMapping } from './ListProviderMapping';
import { FilteringListProvider } from './FilteringListProvider';

export class TeamCompetitionEntry implements CompetitionEntry {
    id: string;
    competition: Competition;
    name: string;
    abbreviation: string;
    color: TeamColor;
    backingTeam?: Team;
    rosterProvider: ListProvider<Resolver<RosterEntry>>;
    matchProvider: ListProvider<Resolver<Match>>;

    constructor(team: Team, competition: Competition, overrideBackingTeam?: Team) {
        this.id = team.id;
        this.competition = competition;
        this.name = team.name;
        this.abbreviation = team.abbreviation;
        this.color = team.color;
        this.backingTeam = overrideBackingTeam ?? team;
        this.rosterProvider = new ListProviderMapping<Resolver<Player>, Resolver<RosterEntry>>(
            team.playerProvider,
            (playerResolver) => {
                return new ResolverMapping<Player, RosterEntry>(
                    playerResolver.id,
                    playerResolver,
                    (player) => {
                        return Promise.resolve({
                            id: player.id,
                            name: player.name,
                            capNumber: player.capNumber,
                            position: player.position,
                            team: this as any,
                            isArchived: false,
                        });
                    }
                );
            }
        );
        this.matchProvider = new FilteringListProvider<Resolver<Match>>(
            competition.matchProvider,
            (matchResolver: Resolver<Match>) => {
                return matchResolver.asAPromise().then((match) => {
                    return match.lightCapTeam.id === this.id || match.darkCapTeam.id === this.id;
                });
            }
        );
    }

    adminForUser(user) {
        return Promise.reject('Admin for Legacy Competition Entries not supported');
    }
}
