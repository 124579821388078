import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Team } from '../../../models/interfaces/Team';
import TeamRosterView from '../../teams/TeamRosterView';
import { Player, isPlayer } from '../../../models/interfaces/Player';
import useListProviderToArray from '../../../helpers/useListProviderToArray';
import { RegistrationData } from '../../interfaces/EventUI';
import { SportProvider } from '../../../models/interfaces/SportProvider';
import { RosterEntry } from '../../../models/interfaces/RosterEntry';

interface Props {
    team: Team;
    handleSelect: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
    eventRoster?: Player[];
    sportProvider: SportProvider;
}

const SelectedTeamRoster = ({ team, handleSelect, eventRoster, sportProvider }: Props) => {
    // a set of player id's is required as the same player in the eventRoster & team roster is two different objects. By capturing the ID in a set we can quickly look up to see if the player we are trying to add is already in the set.
    const [playerRosterIDS, setPlayerRosterIDS] = useState<Set<string> | undefined>();
    const [availableCapNumbers, setAvailableCapNumbers] = useState<string[]>(
        sportProvider.playerCapNumbers.map((capObject) => capObject.databaseValue)
    );

    const memorizedRosterSort = useCallback((a: Player, b: Player) => {
        if (a.capNumber.sortValue < b.capNumber.sortValue) return -1;
        if (a.capNumber.sortValue < b.capNumber.sortValue) return 1;
        return 0;
    }, []);

    const roster = useListProviderToArray({
        provider: team.playerProvider,
        isType: isPlayer,
        sortFn: memorizedRosterSort,
    });

    // use effect updates the roster ids and available cap numbers
    useEffect(() => {
        if (eventRoster) {
            setPlayerRosterIDS(new Set(eventRoster.map((player) => player.id)));
            setAvailableCapNumbers(() => {
                const capNumbers = sportProvider.playerCapNumbers.map(
                    (capObject) => capObject.databaseValue
                );
                // for players in the team remove the cap number as an option
                const freeNumbers = new Set(capNumbers);
                eventRoster.forEach((player) => {
                    const playerNumber = player.capNumber.databaseValue;
                    if (freeNumbers.has(playerNumber)) freeNumbers.delete(playerNumber);
                });
                return [...freeNumbers];
            });
        }
    }, [eventRoster, sportProvider]);

    const checkCapNumberCollision = useCallback(
        (player: Player) => {
            return !availableCapNumbers.includes(player.capNumber.databaseValue);
        },
        [availableCapNumbers]
    );

    // if there was a collision update player to take the next available number
    const avoidCapNumberCollision = useCallback(
        (player: Player, idx: number = 0, updatedCapAvailability?: string[]) => {
            // avoid the assigning players to the display value of cap 1A.
            const firstCap = updatedCapAvailability
                ? updatedCapAvailability[0]
                : availableCapNumbers[0];
            const firstCapDisplayIs1A = firstCap === '1';
            idx += firstCapDisplayIs1A ? 1 : 0;

            // if collision player's new cap number will be the next available. Use index for bulk addition
            const nextAvailableNumber = updatedCapAvailability
                ? updatedCapAvailability[idx]
                : availableCapNumbers[idx];
            const availableCapNumber = sportProvider.playerCapNumbers.filter(
                (capNumber) => capNumber.databaseValue === nextAvailableNumber
            )[0];

            const copyPlayer = Object.assign({}, player);
            copyPlayer.capNumber = availableCapNumber;
            return copyPlayer;
        },
        [availableCapNumbers, sportProvider.playerCapNumbers]
    );

    const handleSelectPlayer = useCallback(
        (player: Player) => {
            let rosterToSubmit = eventRoster && [...eventRoster];
            if (rosterToSubmit && playerRosterIDS) {
                if (!playerRosterIDS.has(player.id)) {
                    const capCollision = checkCapNumberCollision(player as Player);
                    if (capCollision) {
                        const adjustedPlayer = avoidCapNumberCollision(player as Player);
                        rosterToSubmit?.push(adjustedPlayer);
                    } else {
                        rosterToSubmit?.push(player as Player);
                    }
                }
            } else {
                rosterToSubmit = [player as Player];
            }
            handleSelect('roster', rosterToSubmit);
        },
        [
            eventRoster,
            handleSelect,
            playerRosterIDS,
            checkCapNumberCollision,
            avoidCapNumberCollision,
        ]
    );

    const handleSelectAll = useCallback(() => {
        if (roster) {
            let rosterToSubmit = eventRoster && [...eventRoster];
            if (rosterToSubmit && playerRosterIDS) {
                const collisionQueue: Player[] = [];
                // need to keep track of this outside state as it won't be updated in state when we want to use it. When the roster is updated the state will update
                const updatedCapAvailability = new Set(availableCapNumbers);
                // first check if each player's cap has a collision. If no collision add to roster if there is a collision add to array for calculation of adjusted cap
                roster.forEach((player) => {
                    if (!playerRosterIDS.has(player.id)) {
                        const capCollision = checkCapNumberCollision(player);
                        if (capCollision) {
                            // if collision place in queue to be sorted after players have taken their preferred number
                            collisionQueue.push(player);
                        } else {
                            // if no collision with preferred cap number player takes that cap number and remove from available list
                            rosterToSubmit?.push(player);
                            updatedCapAvailability.delete(player.capNumber.databaseValue);
                        }
                    }
                });
                if (collisionQueue.length > 0) {
                    // players who had a collision now look for the last available numbers and take them
                    collisionQueue.forEach((player, idx) => {
                        const adjustedPlayer = avoidCapNumberCollision(player, idx, [
                            ...updatedCapAvailability,
                        ]);
                        rosterToSubmit?.push(adjustedPlayer);
                    });
                }
            } else {
                rosterToSubmit = [...roster];
            }
            handleSelect('roster', rosterToSubmit);
        }
    }, [
        handleSelect,
        roster,
        eventRoster,
        playerRosterIDS,
        checkCapNumberCollision,
        avoidCapNumberCollision,
        availableCapNumbers,
    ]);

    const selectAllButton = useMemo(() => {
        return roster && roster.length !== 0 ? (
            <Button onClick={handleSelectAll}>Add All Players</Button>
        ) : undefined;
    }, [roster, handleSelectAll]);

    const selectAllButtonObject = useMemo(() => {
        return selectAllButton
            ? { buttonElement: selectAllButton, position: 'top' as 'top' }
            : undefined;
    }, [selectAllButton]);

    return (
        <TeamRosterView
            teamPlayers={roster}
            cardHeader={`${team.name} Roster`}
            listButton={selectAllButtonObject}
            handleSelectPlayer={handleSelectPlayer}
        />
    );
};

export default SelectedTeamRoster;
