import { useMemo } from 'react';
import { Match, MatchStatus } from '../../models/interfaces/Match';
import MatchView from '../../components/containers/MatchView';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import TransitionListGroupItem from '../../components/containers/TransitionListGroupItem';
import { useHistory } from 'react-router-dom';
import {
    useExpandableCard,
} from '../../context/useExpandableCardContext';

interface Props {
    matches: Match[] | undefined;
    showCompetition: boolean;
}

function CompletedMatchDisplay({ matches, showCompetition }: Props) {
    const history = useHistory();
    const { expanded } = useExpandableCard();

    // slice & save the data to render
    const filteredMatchedToRender = useMemo(() => {
        if (matches) {
            if (!expanded) {
                // if slice is changed update showExpanded variable in CompletedMatchView component below
                return matches.slice(0, 3);
            } else {
                return matches;
            }
        }
    }, [matches, expanded]);

    if (!matches)
        return (
            <Card.Body>
                <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
            </Card.Body>
        );

    if (matches.length === 0) return <Card.Body>No matches have been completed</Card.Body>;

    return (
        <ListGroup className='myListView' variant='flush' >
            {/* move to render all matches then the TransitionListGroupItem is responsible to check if item should be displayed or not */}
            {filteredMatchedToRender &&
                matches &&
                matches.map((match) => {
                    return (
                        <TransitionListGroupItem
                            key={match.id}
                            action
                            onClick={() => {
                                history.push(`/matches/${match.id}`);
                            }}
                            display={filteredMatchedToRender.includes(match)}
                        >
                            <MatchView
                                match={match}
                                showScore={true}
                                showCompetition={showCompetition}
                            />
                        </TransitionListGroupItem>
                    );
                })}
        </ListGroup>
    );
}

//ExpandableCardContextProvider needs to wrap component to use the internal context
export default function CompletedMatchView({ matches, showCompetition }: Props) {
    const filteredMatched = useMemo(() => {
        return matches
            ?.filter((match) => match.status === MatchStatus.completed)
            .sort((a, b) => {
                let aDate = a.date;
                let bDate = b.date;
                if (aDate > bDate) {
                    return -1;
                }
                return 0;
            });
    }, [matches]);
    return (
        <Card>
            <Card.Header>Completed Matches</Card.Header>
            <CompletedMatchDisplay matches={filteredMatched} showCompetition={showCompetition} />
        </Card>
    );
}
