import { UpdatingResolver } from '../../interfaces/Resolver';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import firebase from 'firebase';

export class FirebaseUpdatingItemResolver<T> implements UpdatingResolver<T> {
    id: string;
    resolvedObject?: T | string;

    databaseReference: firebase.database.Reference;
    itemTranslator: FirebaseItemTranslator<T>;

    constructor(
        id: string,
        databaseReference: firebase.database.Reference,
        itemTranslator: FirebaseItemTranslator<T>
    ) {
        this.id = id;
        this.resolvedObject = undefined;
        this.databaseReference = databaseReference;
        this.itemTranslator = itemTranslator;
    }

    addListener(onUpdate: (update: T | string) => void) {
        return this.databaseReference.on(
            'value',
            (snapshot) => {
                this.itemTranslator.translate(
                    snapshot,
                    (translatedItem) => {
                        this.resolvedObject = translatedItem;
                        onUpdate(translatedItem);
                    },
                    (error) => {
                        const errorMessage = `Failed to update resolver: ${this.id} with error: ${error}`;
                        this.resolvedObject = errorMessage;
                        onUpdate(errorMessage);
                    }
                );
            },
            (error) => {
                const errorMessage = `Failed to update resolver: ${this.id} with error: ${error}`;
                this.resolvedObject = errorMessage;
                onUpdate(errorMessage);
            }
        );
    }
    cancel(token: any) {
        if (token) {
            this.databaseReference.off('value', token);
        }
    }
}
