import { ReactNode } from 'react';
import styled from 'styled-components';
import { SportProvider } from '../../models/interfaces/SportProvider';

const ContentView = styled.div`
    flex-grow: 1;
    height: 100%;
    width: 100%;
`;

const DetailViewContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const DetailViewContent = styled.div`
    height: 100%;
    width: 100%;
`;

interface Props {
    titleView?: string | ReactNode;
    sportProvider: SportProvider;
    accessoryView?: ReactNode;
    children: ReactNode;
}

export default function DetailPageContainer({
    children,
}: Props) {
    return (
        <ContentView>
            <DetailViewContainer>
                <DetailViewContent>{children}</DetailViewContent>
            </DetailViewContainer>
        </ContentView>
    );
}
