import axios, { AxiosError } from 'axios';
import { EventAdminPermission, EventEditor } from '../../interfaces/Event';
import { EventAdminInvitation } from '../../interfaces/Events/EventAdminInvitation';
import { ListProvider } from '../../interfaces/ListProvider';
import { Resolver } from '../../interfaces/Resolver';
import { PublicUser, User } from '../../interfaces/User';
import { FirebaseBackedEvent } from '../FirebaseBackedEvent';
import { FirebaseBackedListProvider } from '../FirebaseBackedListProvider';

export class FirebaseBackedEventEditor implements EventEditor {
    user: User;
    event: FirebaseBackedEvent;
    adminProvider: ListProvider<Resolver<{ user: PublicUser; permission: EventAdminPermission }>>;
    adminInvitationProvider: ListProvider<Resolver<EventAdminInvitation>>;

    constructor(user: User, event: FirebaseBackedEvent) {
        this.user = user;
        this.event = event;
        this.adminProvider = new FirebaseBackedListProvider(
            event.database.ref(`events/${event.id}/admins`),
            (adminID) => {
                return event.sportProvider.resolverProvider.eventAdminPermissionResolver(
                    event.id,
                    adminID
                );
            }
        );
        this.adminInvitationProvider = new FirebaseBackedListProvider(
            event.database.ref(`events/${event.id}/adminInvitations`),
            (invitationID) => {
                return event.sportProvider.resolverProvider.eventAdminInvitationResolver(
                    invitationID
                );
            }
        );
    }

    updateAdminPermission(publicUser: PublicUser, permission: EventAdminPermission) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    userToken: token,
                    eventID: this.event.id,
                    userID: publicUser.id,
                    permission,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/updatePermission';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Update Permission failed with: ${response.statusText}`);
                }
            });
    }

    removeAdmin(user: PublicUser) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    userToken: token,
                    eventID: this.event.id,
                    userID: user.id,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/removeAdmin';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Update Permission failed with: ${response.statusText}`);
                }
            });
    }

    sendAdminInvitation(email: string, permission: EventAdminPermission) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    email: email,
                    userToken: token,
                    eventID: this.event.id,
                    permission,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/adminInvitations/send';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 201) {
                    const invitationID = response.data['invitationKey'];
                    return this.event.sportProvider.resolverProvider
                        .eventAdminInvitationResolver(invitationID)
                        .asAPromise();
                } else {
                    return Promise.reject(
                        `eventAdmin.sendAdminInvitation failed with: ${response.statusText}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 409) {
                    return Promise.reject(
                        `Event admin invitation failed due to a duplicate invitation or current admin`
                    );
                } else {
                    return Promise.reject(`Event admin invitation failed with: ${error.message}`);
                }
            });
    }

    removeAdminInvitation(invitation: EventAdminInvitation) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    invitationID: invitation.id,
                    userToken: token,
                    eventID: this.event.id,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/adminInvitations/remove';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `eventAdmin.removeAdminInvitation failed with: ${response.statusText}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                return Promise.reject(error.message);
            });
    }
}
