import { SportProvider } from '../../interfaces/SportProvider';
import { Organization } from '../../interfaces/Organization';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { FirebaseBackedOrganization } from '../FirebaseBackedOrganization';
import firebase from 'firebase';

export class FirebaseOrganizationTranslator implements FirebaseItemTranslator<Organization> {
    id: string;
    database: firebase.database.Database;
    private sportProvider: SportProvider;

    constructor(id: string, sportProvider: SportProvider, database: firebase.database.Database) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
    }
    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (Organization) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val();
        const name = val?.name || undefined;
        if (name) {
            const organization = new FirebaseBackedOrganization(
                this.id,
                this.database,
                this.sportProvider,
                name
            );
            onSuccess(organization);
        } else {
            const error = "Could not load organization's name";
            onFailure(error);
        }
    }
}
