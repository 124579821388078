import { Competition } from '../../../interfaces/Competition';
import {
    CompetitionAdmin,
    CompetitionAdminPermissions,
    CompetitionMatchManager,
    CompetitionTeamManager,
} from '../../../interfaces/Competitions/CompetitionAdmin';
import { User } from '../../../interfaces/User';
import { FirebaseBackedCompetition } from '../../FirebaseBackedCompetition';
import { FirebaseBackedListProvider } from '../../FirebaseBackedListProvider';
import { FirebaseBackedCompetitionMatchManager } from './FirebaseBackedCompetitionMatchManager';
import { FirebaseBackedCompetitionTeamManager } from './FirebaseBackedCompetitionTeamManager';

export class FirebaseBackedCompetitionAdmin implements CompetitionAdmin {
    user: User;
    competition: Competition;

    teamManager?: CompetitionTeamManager;
    matchManager?: CompetitionMatchManager;

    constructor(
        user: User,
        competition: FirebaseBackedCompetition,
        permissions: Set<CompetitionAdminPermissions>
    ) {
        this.user = user;
        this.competition = competition;
        const sportProvider = competition.sportProvider;
        const database = competition.database;

        if (permissions.has(CompetitionAdminPermissions.entryManagment)) {
            const entryRequestPath = `competitions/${competition.id}/entryRequests`;
            const entryRequestProvider = new FirebaseBackedListProvider(
                competition.database.ref(entryRequestPath),
                (requestID) => {
                    return competition.sportProvider.competitionEntryRequestResolver(
                        competition.id,
                        requestID
                    );
                }
            );
            const entryInvitationProvider = new FirebaseBackedListProvider(
                competition.database.ref(`competitions/${competition.id}/entryInvitations`),
                (invitationID) => {
                    return competition.sportProvider.competitionInvitationResolver(invitationID);
                }
            );
            this.teamManager = new FirebaseBackedCompetitionTeamManager(
                user,
                competition,
                entryRequestProvider,
                entryInvitationProvider
            );
        }

        if (permissions.has(CompetitionAdminPermissions.matchManagement)) {
            this.matchManager = new FirebaseBackedCompetitionMatchManager(user, competition);
        }
    }
}
