import { Team } from '../models/interfaces/Team';

export const sortTeamBasedOnSelectedTeamAttributes = (a: Team, b: Team, backingTeam: Team) => {
    // sort with priority of division
    if (
        a.attributes.division.databaseValue === backingTeam.attributes.division.databaseValue &&
        b.attributes.division.databaseValue !== backingTeam.attributes.division.databaseValue
    )
        return -1;
    if (
        a.attributes.division.databaseValue !== backingTeam.attributes.division.databaseValue &&
        b.attributes.division.databaseValue === backingTeam.attributes.division.databaseValue
    )
        return 1;
    // sort with second priority of category
    if (
        a.attributes.category.databaseValue === backingTeam.attributes.category.databaseValue &&
        b.attributes.category.databaseValue !== backingTeam.attributes.category.databaseValue
    )
        return -1;
    if (
        a.attributes.category.databaseValue !== backingTeam.attributes.category.databaseValue &&
        b.attributes.category.databaseValue === backingTeam.attributes.category.databaseValue
    )
        return 1;
    // sort with third priority of gender
    if (
        a.attributes.gender.databaseValue === backingTeam.attributes.gender.databaseValue &&
        b.attributes.gender.databaseValue !== backingTeam.attributes.gender.databaseValue
    )
        return -1;
    if (
        a.attributes.gender.databaseValue !== backingTeam.attributes.gender.databaseValue &&
        b.attributes.gender.databaseValue === backingTeam.attributes.gender.databaseValue
    )
        return 1;
    // finally sort based on team name
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
};
