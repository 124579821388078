
import styled from 'styled-components';
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default function UnavailablePage() {
    return (
            <Container>
                <h2>CB Water Polo is temporarily unavailable, please try again later</h2>
            </Container>
        );
}