import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/multiItemCarousel.css';

interface Props {
    matches: JSX.Element[];
    compressed?: boolean;
}

export default function MultiItemCarousel({ matches, compressed }: Props) {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: compressed ? 1 : 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 765 },
            items: compressed ? 1 : 3,
        },
        tablet: {
            breakpoint: { max: 768, min: 375 },
            items: compressed ? 1 : 2,
        },
        mobile: {
            breakpoint: { max: 375, min: 0 },
            items: compressed ? 1 : 1,
        },
    };

    return (
        <Carousel
            responsive={responsive}
            ssr
            keyBoardControl={true}
            // either create custom arrows, make arrows false (removes arrows) or style arrows with CSS ( easiest option)
            renderButtonGroupOutside={true}
        >
            {matches}
        </Carousel>
    );
}
