import { ReactChild, CSSProperties, MouseEventHandler } from 'react';
import { ListGroup } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import '../../components/styles/transitionEffectStyles.css';

interface Props {
    display: boolean;
    onClick: MouseEventHandler;
    style?: CSSProperties;
    action?: boolean;
    children: ReactChild;
}

const TransitionListGroupItem = ({ display, children, ...props }: Props) => {
    return (
        <CSSTransition in={display} timeout={300} classNames="display2" unmountOnExit>
            <ListGroup.Item {...props}>{children}</ListGroup.Item>
        </CSSTransition>
    );
};

export default TransitionListGroupItem;
