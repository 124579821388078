import { ReactChild, CSSProperties, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import '../../components/styles/transitionEffectStyles.css';

interface Props {
    color: string;
}
const StyledListGroupItem = styled(ListGroup.Item)<Props>`
    
`;

const StyledTeamDiv = styled.div<Props>`
    ${({ color }) =>
        color.length === 0
        ? ''
        : `box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-shadow: inset 0.3rem 0 0 0 ${color};
padding-left: 1rem;`}
`;

interface TransitionProps extends Props {
    display: boolean;
    onClick: MouseEventHandler;
    style?: CSSProperties;
    action?: boolean;
    children: ReactChild;
}

const TransitionStyledListGroupItem = ({ display, children, ...props }: TransitionProps) => {
    return (
        <CSSTransition in={display} timeout={300} classNames="display2" unmountOnExit>
            <StyledListGroupItem {...props}>{children}</StyledListGroupItem>
        </CSSTransition>
    );
};

export { TransitionStyledListGroupItem };
export { StyledTeamDiv }
export default StyledListGroupItem;
