import { Competition } from '../../interfaces/Competition';
import {
    CompetitionEntry,
    CompetitionEntryAdmin,
} from '../../interfaces/Competitions/CompetitionEntry';
import { ListProvider } from '../../interfaces/ListProvider';
import { Match } from '../../interfaces/Match';
import { Resolver } from '../../interfaces/Resolver';
import { Name, User } from '../../interfaces/User';
import { RosterEntry, CapNumber, Position } from '../../interfaces/RosterEntry';
import { SportProvider } from '../../interfaces/SportProvider';
import { Team } from '../../interfaces/Team';
import { TeamColor } from '../../interfaces/TeamAttributes';
import { FirebaseBackedListProvider } from '../FirebaseBackedListProvider';
import { FirebaseItemResolver } from '../translators/FirebaseItemResolver';
import axios from 'axios';
import { Player } from '../../interfaces/Player';
import firebase from 'firebase';

interface OptionalData {
    backingTeam?: Team;
    externalID?: string | undefined;
}

interface RosterEntryData {
    name: Name,
    capNumber: CapNumber,
    position: Position,
    isArchived: boolean,
    backingPlayer?: Player,
    externalID ?: string
}

export class FirebaseBackedCompetitionEntry implements CompetitionEntry {
    id: string;
    sportProvider: SportProvider;
    database: firebase.database.Database;
    competition: Competition;
    backingTeam?: Team;
    name: string;
    abbreviation: string;
    color: TeamColor;
    rosterProvider: ListProvider<Resolver<RosterEntry>>;
    matchProvider: ListProvider<Resolver<Match>>;
    externalID?: string | undefined;
    constructor(
        id: string,
        sportProvider: SportProvider,
        database: firebase.database.Database,
        competition: Competition,
        name: string,
        abbreviation: string,
        color: TeamColor,
        optionalData: OptionalData
    ) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
        this.competition = competition;
        this.backingTeam = optionalData.backingTeam;
        this.externalID = optionalData.externalID
        this.name = name;
        this.abbreviation = abbreviation;
        this.color = color;
        const rosterDatabaseRef = database.ref(
            `competitions/${competition.id}/entries/${id}/roster`
        );
        this.rosterProvider = new FirebaseBackedListProvider(rosterDatabaseRef, (rosterID) => {
            const rosterEntryRef = rosterDatabaseRef.child(rosterID);
            return new FirebaseItemResolver(rosterID, rosterEntryRef, {
                translate: (snapshot, onSuccess, onFailure) => {
                    const { capNumber, position } = snapshot.val();
                    const isArchived = snapshot.val()['isArchived'];
                    var name = snapshot.val()['name'];
                    if (!name) {
                        name = {};
                    }
                    var firstName = name['firstName'];
                    var lastName = name['lastName'];
                    if (!firstName && !lastName) {
                        lastName = '(No Name Provided)';
                    }
                    const resolvedCapNumber = this.sportProvider.capNumber(capNumber);
                    if (!resolvedCapNumber) {
                        onFailure('competition roster entry missing cap number');
                        return;
                    }

                    const playerPosition = this.sportProvider.playerPosition(position);
                    if (!playerPosition) {
                        onFailure('competition roster entry missing position');
                        return;
                    }

                    const externalID = snapshot.val()['externalID']

                    const backingPlayerID = snapshot.val()['backingPlayer'];
                    if (backingPlayerID) {
                        this.sportProvider
                            .playerResolver(backingPlayerID)
                            .asAPromise()
                            .then((backingPlayer) => {
                                const rosterEntry: RosterEntry = {
                                    id: rosterID,
                                    name: backingPlayer.name,
                                    position: playerPosition,
                                    capNumber: resolvedCapNumber,
                                    team: this,
                                    isArchived,
                                    backingPlayer,
                                    externalID
                                };
                                onSuccess(rosterEntry);
                            })
                            .catch((error) => {
                                const rosterEntry: RosterEntry = {
                                    id: rosterID,
                                    name: { firstName: firstName, lastName: lastName },
                                    position: playerPosition,
                                    capNumber: resolvedCapNumber,
                                    team: this,
                                    isArchived,
                                    externalID
                                };
                                onSuccess(rosterEntry);
                            });
                    } else {
                        const rosterEntry: RosterEntry = {
                            id: rosterID,
                            name: { firstName: firstName, lastName: lastName },
                            position: playerPosition,
                            capNumber: resolvedCapNumber,
                            team: this,
                            isArchived,
                            externalID
                        };
                        onSuccess(rosterEntry);
                    }
                },
            });
        });
        const matchesDatabaseRef = database.ref(
            `competitions/${competition.id}/entries/${id}/matches`
        );
        this.matchProvider = new FirebaseBackedListProvider(matchesDatabaseRef, (matchID) => {
            return sportProvider.matchResolver(matchID);
        });
    }

    adminForUser(user) {
        return this.competition.adminForUser(user).then(() => {
            return new FirebasebackedCompetitionEntryAdmin(user, this);
        });
    }
}

class FirebasebackedCompetitionEntryAdmin implements CompetitionEntryAdmin {
    user: User;
    competitionEntry: FirebaseBackedCompetitionEntry;
    constructor(user: User, competitionEntry: FirebaseBackedCompetitionEntry) {
        this.user = user;
        this.competitionEntry = competitionEntry;
    }

    addRosterEntry(name: Name, capNumber: CapNumber, position: Position) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                competitionID: this.competitionEntry.competition.id,
                competitionEntryID: this.competitionEntry.id,
                rosterEntryMetadata: {
                    name: name,
                    capNumber: capNumber.databaseValue,
                    position: position.databaseValue,
                },
                userToken: token,
            };
            var apiPath = '/api/competitionEntries/addRosterEntry';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;

                if (status === 201) {
                    const rosterID = (response.data || {})['rosterEntryID'];
                    if (!rosterID) {
                        return Promise.reject('addRosterEntry did not return an ID');
                    } else {
                        return this.competitionEntry.sportProvider
                            .rosterEntryResolver(
                                this.competitionEntry.competition.id,
                                this.competitionEntry.id,
                                rosterID
                            )
                            .asAPromise();
                    }
                } else {
                    return Promise.reject(
                        `competitionEntryAdmin.addRosterEntry failed with: ${response.statusText}`
                    );
                }
            });
        });
    }

    updateRosterEntry(
        rosterEntry: RosterEntry,
        name: Name,
        capNumber: CapNumber,
        position: Position,
        externalID ?: string
    ) {
        return this._updateRosterEntry(
            rosterEntry,
            {
                name,
                capNumber,
                position,
                isArchived: false,
                backingPlayer: rosterEntry.backingPlayer,
                externalID: externalID
            }
        );
    }

    setBackingPlayer(rosterEntry: RosterEntry, player?: Player) {
        return this._updateRosterEntry(
            rosterEntry,
            {
                name: rosterEntry.name,
                capNumber: rosterEntry.capNumber,
                position: rosterEntry.position,
                isArchived: rosterEntry.isArchived,
                backingPlayer: player,
                externalID: rosterEntry.externalID
            }
        );
    }

    archiveRosterEntry(rosterEntry: RosterEntry) {
        return this._updateRosterEntry(
            rosterEntry,
            {
                name: rosterEntry.name,
                capNumber: rosterEntry.capNumber,
                position: rosterEntry.position,
                isArchived: true,
                backingPlayer: rosterEntry.backingPlayer,
                externalID: rosterEntry.externalID
            }
        ).then(() => {
            return Promise.resolve();
        });
    }

    _updateRosterEntry(
        rosterEntry: RosterEntry,
        data: RosterEntryData
    ) {
        return this.user.fetchVerificationToken().then((token) => {
            const rosterID = rosterEntry.id;
            var rosterEntryMetadata = {
                name: data.name,
                capNumber: data.capNumber.databaseValue,
                position: data.position.databaseValue,
                isArchived: data.isArchived,
            };
            if (data.backingPlayer) {
                rosterEntryMetadata['backingPlayer'] = data.backingPlayer.id;
            }
            if (data.externalID) {
                rosterEntryMetadata['externalID'] = data.externalID
            }
            const body = {
                competitionID: this.competitionEntry.competition.id,
                competitionEntryID: this.competitionEntry.id,
                rosterEntryID: rosterID,
                rosterEntryMetadata: rosterEntryMetadata,
                userToken: token,
            };
            var apiPath = '/api/competitionEntries/updateRosterEntry';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;

                if (status === 200) {
                    return this.competitionEntry.sportProvider
                        .rosterEntryResolver(
                            this.competitionEntry.competition.id,
                            this.competitionEntry.id,
                            rosterID
                        )
                        .asAPromise();
                } else {
                    return Promise.reject(
                        `competitionEntryAdmin.updateRosterEntry failed with: ${response.statusText}`
                    );
                }
            });
        });
    }
}
