import { PlayerMetadata } from './RosterEntry';
import { ClaimPlayerRequest, Team } from './Team';

import { PlayerStats } from './PlayerStatProvider';
import { StatTypeDescribing } from './Stat';
import { PublicUser, User } from './User';

export interface Player extends PlayerMetadata {
    team: Team;
    backingUser?: PublicUser;

    createClaimRequest(user: User): Promise<ClaimPlayerRequest>;
    matchStatProvider(statTypes: StatTypeDescribing[]): Promise<PlayerStats[]>;
}

export function isPlayer(candidate: any): candidate is Player {
    return (
        (candidate as Player).team !== undefined &&
        (candidate as Player).matchStatProvider !== undefined
    );
}
