import { Card, Col, Row, Spinner } from "react-bootstrap";
import { CompetitionPickerListView } from "./CompetitionPickerViews";
import { Competition } from "../../../models/interfaces/Competition";
import { useEffect, useState } from "react";
import { RosterEntry } from "../../../models/interfaces/RosterEntry";
import { ExpandableCardContextProvider } from "../../../context/useExpandableCardContext";
import CompetitionLeadersDisplay from "../../competitions/modules/CompetitionLeadersDisplay";

interface Props {
    competitions: Competition[];
    selectedCompetition: Competition;
    onSelectCompetition: (Competition) => void;
}

export function EventLeadersView({ competitions, selectedCompetition, onSelectCompetition }: Props) {
    const [competitionLeaders, setCompetitionLeaders] = useState<
        { title: string; players: { player: RosterEntry; text: string }[] }[] | undefined
    >(undefined);

    useEffect(() => {
        setCompetitionLeaders(undefined);
        
        selectedCompetition.leaderboardProvider
            .once()
            .then((leaderGroups) => {
                const leaderGroupPromises = leaderGroups.map((leaderGroup) => {
                    const playerSummaryPromises = leaderGroup.players.map((playerSummary) => {
                        return leaderGroup
                            .displayTextForStatResolvers(playerSummary.statResolvers)
                            .then((playerText) => {
                                return {
                                    player: playerSummary.player,
                                    text: playerText,
                                };
                            });
                    });
                    return Promise.allSettled(playerSummaryPromises)
                        .then((playerSummaryResults) => {
                            return playerSummaryResults.flatMap((playerSummaryResult) => {
                                if (playerSummaryResult.status === 'fulfilled') {
                                    return [playerSummaryResult.value];
                                } else {
                                    return [];
                                }
                            });
                        })
                        .then((inflatedPlayerSummaries) => {
                            return {
                                title: leaderGroup.title,
                                sortOrder: leaderGroup.sortOrder,
                                players: inflatedPlayerSummaries,
                            };
                        });
                });
                return Promise.all(leaderGroupPromises);
            })
            .then((inflatedLeaderGroups) => {
                setCompetitionLeaders(
                    inflatedLeaderGroups.sort((left, right) => {
                        return left.sortOrder - right.sortOrder;
                    })
                );
            });
    }, [selectedCompetition]);


    return (
        <div style={{width: '100%', paddingTop: '10px'}}>
            <Row noGutters className="matchContainer" style={{gap: '10px', padding: '20px'}}>
            <Col xs={4}>
                <CompetitionPickerListView
                    competitions={competitions}
                    selectedCompetition={selectedCompetition}
                    onCompetitionSelect={onSelectCompetition}
                />
            </Col>
            <Col>
                <>
                    {!competitionLeaders ? (
                        <Card>
                            <Card.Body>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Card.Body>
                        </Card>
                    ) : (
                        competitionLeaders.reduce((hasLeaders, currentValue) => {
                            if (hasLeaders) {
                                return true;
                            } else {
                                return currentValue.players.length > 0;
                            }
                        }, false) ? 
                            competitionLeaders.map((leaderType) => {
                                return (
                                    <ExpandableCardContextProvider
                                        key={leaderType.title}
                                        headerText={leaderType.title}
                                        buttonDisplayOptions={{
                                            expanded: `Collapse ${leaderType.title}`,
                                            collapsed: `Show All ${leaderType.title}`,
                                        }}
                                        showExpand={leaderType.players.length > 5}
                                    >
                                        <CompetitionLeadersDisplay leaderType={leaderType} />
                                    </ExpandableCardContextProvider>
                                );
                            })
                            : <>No individual statistics have been recorded for this division.</>
                    )}
                </>
            </Col>
            </Row>
        </div>
    );
}