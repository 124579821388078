import { Card, ListGroup, Spinner, Row, Col } from 'react-bootstrap';
import StyledListGroupItem from '../../../components/containers/StyledListGroupItem';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import { Organization } from '../../../models/interfaces/Organization';
import { Team } from '../../../models/interfaces/Team';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as filledCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { SportProvider } from '../../../models/interfaces/SportProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    organization: Organization;
    organizationTeams: Team[] | undefined;
    selectedTeam: Team;
    handleClick: (team: Team) => void;
    sportProvider: SportProvider;
}

const SelectTeamForRoster = ({
    organization,
    organizationTeams,
    selectedTeam,
    handleClick,
    sportProvider,
}: Props) => {
    return (
        <Card>
            <Card.Header>{organization.name} Teams</Card.Header>
            <Card.Body className="py-3">
                <Card.Subtitle>Select a team to view roster</Card.Subtitle>
            </Card.Body>
            {!organizationTeams ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
                <ListGroup>
                    {organizationTeams.map((team) => (
                        <StyledListGroupItem
                            key={team.id}
                            color={parseTeamColor(team.color)}
                            onClick={() => handleClick(team)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Row>
                                <Col>{team.name}</Col>
                                <Col xs="auto">
                                    <FontAwesomeIcon
                                        icon={
                                            (selectedTeam.id === team.id
                                                ? filledCircle
                                                : faCircle) as IconProp
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-muted small">
                                    {sportProvider.displayTextForTeamOrCompetitionAttributes(
                                        team.attributes
                                    )}
                                </Col>
                            </Row>
                        </StyledListGroupItem>
                    ))}
                </ListGroup>
            )}
        </Card>
    );
};

export default SelectTeamForRoster;
