import { Col, Row, Card, Spinner } from 'react-bootstrap';
import { ExpandableCardContextProvider } from '../../../context/useExpandableCardContext';
import { CompetitionLeaders } from '../../interfaces/CompetitionLeadersUI';
import CompetitionLeadersDisplay from './CompetitionLeadersDisplay';

export interface Props {
    competitionLeaders: CompetitionLeaders[] | undefined;
}

export default function CompetitionLeadersView({ competitionLeaders }: Props) {
    return (
        <Row>
            <Col>
                {!competitionLeaders ? (
                    <Card>
                        <Card.Body>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Card.Body>
                    </Card>
                ) : (
                    competitionLeaders.map((leaderType) => {
                        return (
                            <ExpandableCardContextProvider
                                key={leaderType.title}
                                headerText={leaderType.title}
                                buttonDisplayOptions={{
                                    expanded: `Collapse ${leaderType.title}`,
                                    collapsed: `Show All ${leaderType.title}`,
                                }}
                                showExpand={leaderType.players.length > 5}
                            >
                                <CompetitionLeadersDisplay leaderType={leaderType} />
                            </ExpandableCardContextProvider>
                        );
                    })
                )}
            </Col>
        </Row>
    );
}
