import React from 'react';
import { PlayerMetadata } from '../../models/interfaces/RosterEntry';

interface Props {
    player: PlayerMetadata;
}

export default function PlayerView({ player }: Props) {
    return (
        <span>
            {`#${player.capNumber.displayName}. ${player.name.firstName} ${player.name.lastName}`}
        </span>
    );
}
