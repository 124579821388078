import { Competition } from '../Competition';
import { Match, MatchMetadata, MatchSegmentOption } from '../Match';
import { CompetitionEntry, CompetitionEntryMetadata } from './CompetitionEntry';
import { ListProvider } from '../ListProvider';
import { Resolver } from '../Resolver';
import { CompetitionEntryRequest } from './CompetitionEntryRequest';
import { PlayerMetadata } from '../RosterEntry';
import { User } from '../User';
import { Team } from '../Team';

export enum RemoveTeamError {
    missingPermission,
    serverFailure,
}

export interface CompetitionEntryInvitation {
    id: string;
    email: string;
    competitionEntry: CompetitionEntry;

    linkURL: string;
}

export enum CompetitionAdminPermissions {
    entryManagment = 'ENTRY_MANAGER',
    matchManagement = 'MATCH_MANAGER',
}

export interface CompetitionTeamManager {
    competition: Competition;

    entryRequestProvider: ListProvider<Resolver<CompetitionEntryRequest>>;

    acceptEntryRequest: (request: CompetitionEntryRequest) => Promise<CompetitionEntry>;

    entryInvitationProvider: ListProvider<Resolver<CompetitionEntryInvitation>>;
    inviteEntryAdmin: (
        email: string,
        competitionEntry: CompetitionEntry
    ) => Promise<CompetitionEntryInvitation>;
    removeEntryAdminInvitation: (invitation: CompetitionEntryInvitation) => Promise<void>;

    createEntry: (
        metadata: CompetitionEntryMetadata,
        roster: PlayerMetadata[]
    ) => Promise<CompetitionEntry>;
    addTeamEntry: (team: Team) => Promise<CompetitionEntry>;
    removeEntry: (team: CompetitionEntry, removeMatches: boolean) => Promise<void>;
}

export interface CompetitionMatchManager {
    user: User;
    createMatch: (
        metadata: MatchMetadata,
        lightCapTeam: CompetitionEntry,
        darkCapTeam: CompetitionEntry,
        regularTimeMatchSegmentOption: MatchSegmentOption,
        durationForRegularTimeMatchSegments: number,
        defaultNumberOfTimeouts: number,
        externalID?: string
    ) => Promise<Match>;

    removeMatch: (match: Match) => Promise<void>;
}

export interface CompetitionAdmin {
    user: User;
    competition: Competition;

    teamManager?: CompetitionTeamManager;
    matchManager?: CompetitionMatchManager;
}
