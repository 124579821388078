import { MatchEntry } from '../../../../interfaces/MatchEntry';
import { TeamColor } from '../../../../interfaces/TeamAttributes';
import { Team } from '../../../../interfaces/Team';
import { Player } from '../../../../interfaces/Player';
import { ListProviderMapping, ResolverMapping } from './ListProviderMapping';
import { RosterEntry } from '../../../../interfaces/RosterEntry';
import { ListProvider } from '../../../../interfaces/ListProvider';
import { Resolver } from '../../../../interfaces/Resolver';
import { User } from '../../../../interfaces/User';
import { Competition } from '../../../../interfaces/Competition';
import { CompetitionEntry } from '../../../../interfaces/Competitions/CompetitionEntry';
import { TeamCompetitionEntry } from './TeamCompetitionEntry';

export class TeamMatchEntry implements MatchEntry {
    id: string;
    name: string;
    abbreviation: string;
    color: TeamColor;

    rosterProvider: ListProvider<Resolver<RosterEntry>>;

    constructor(team: Team) {
        this.id = team.id;
        this.name = team.name;
        this.abbreviation = team.abbreviation;
        this.color = team.color;

        this.rosterProvider = new ListProviderMapping<Resolver<Player>, Resolver<RosterEntry>>(
            team.playerProvider,
            (playerResolver) => {
                return new ResolverMapping<Player, RosterEntry>(
                    playerResolver.id,
                    playerResolver,
                    (player) => {
                        return Promise.resolve({
                            id: player.id,
                            name: player.name,
                            capNumber: player.capNumber,
                            position: player.position,
                            team: this,
                            isArchived: false,
                        });
                    }
                );
            }
        );
    }

    adminForUser(user) {
        return Promise.reject('Legacy Match Entry does not support admin updates');
    }
}
