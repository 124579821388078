import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ListView from '../../../components/containers/ListView';
import MatchView from '../../../components/containers/MatchView';
import StyledListGroupItem from '../../../components/containers/StyledListGroupItem';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import { Competition } from '../../../models/interfaces/Competition';
import { Match } from '../../../models/interfaces/Match';
import TeamRecordView from '../../competitions/modules/TeamRecordView';

interface Props {
    competition: Competition;
    matches?: Match[];
}

export default function EventCompetitionStandingsView({ competition }: Props) {
    const history = useHistory();
    return (
        <ListView
            key={competition.id}
            listProvider={competition.standingsProvider}
            isFlush={true}
            noContentView={
                <h5 className="mt-1">
                    <i>No teams have registered for this division, check back soon!</i>
                </h5>
            }
            listItemTranslator={(teamRecord) => {
                return (
                    <StyledListGroupItem
                        key={teamRecord.team.id}
                        action
                        onClick={() => {
                            history.push(
                                `/competitions/${competition.id}/entries/${teamRecord.team.id}`
                            );
                        }}
                        color={parseTeamColor(teamRecord.team.color)}
                    >
                        <TeamRecordView teamRecord={teamRecord} />
                    </StyledListGroupItem>
                );
            }}
        />
    );
}
