import { useRouteMatch } from 'react-router-dom';
import { SportProvider } from '../../models/interfaces/SportProvider';
import CompetitionEntryDetailPage from './CompetitionEntryDetailPage';
import { User } from '../../models/interfaces/User';
import ResolverView from '../../components/containers/ResolverView';

interface Props {
    user?: User;
    sportProvider: SportProvider;
}

// similar to Team pages but for only the one competition
export default function CompetitionEntryDashboard({ user, sportProvider }: Props) {
    const competitionEntryRoute = useRouteMatch<{
        competitionID: string;
        entryID: string;
    }>('/competitions/:competitionID/entries/:entryID');
    if (competitionEntryRoute && competitionEntryRoute.params.entryID) {
        const entryID = competitionEntryRoute.params.entryID;
        const competitionID = competitionEntryRoute.params.competitionID;
        return (
            <ResolverView
                resolver={sportProvider.competitionEntryResolver(competitionID, entryID)}
                itemTranslator={(competitionEntry) => {
                    return (
                        <CompetitionEntryDetailPage
                            user={user}
                            competitionEntry={competitionEntry}
                            sportProvider={sportProvider}
                        />
                    );
                }}
            />
        );
    } else {
        return <>Under Construction</>;
    }
}
