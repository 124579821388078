import {
    Competition,
    CompetitionAttributes,
    CompetitionDates,
    CompetitionPlayerLeaderboard,
    TeamRecord,
} from '../interfaces/Competition';
import {
    CompetitionAdmin,
    CompetitionAdminPermissions,
    RemoveTeamError,
} from '../interfaces/Competitions/CompetitionAdmin';
import { SportProvider } from '../interfaces/SportProvider';
import { PublicUser, User } from '../interfaces/User';
import { Event, EventAdminPermission } from '../interfaces/Event';
import { ListProvider } from '../interfaces/ListProvider';
import { Player } from '../interfaces/Player';
import { Resolver } from '../interfaces/Resolver';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';

import { Match } from '../interfaces/Match';

import FirebaseBackedCompetitionStandingsProvider from './FirebaseBackedCompetitionStandingsProvider';
import axios from 'axios';
import { CompetitionPlayerStatDescription } from '../interfaces/ViewDescriptions/MatchViewDescription';
import { CompetitionEntry } from '../interfaces/Competitions/CompetitionEntry';
import { ResolverMapping } from './sports/waterpolo/LegacySupport/ListProviderMapping';
import { Team } from '../interfaces/Team';
import { TeamCompetitionEntry } from './sports/waterpolo/LegacySupport/TeamCompetitionEntry';
import { FirebaseBackedCompetitionLeadersProvider } from './FirebaseBackedCompetitionLeadersProvider';
import { RosterEntry } from '../interfaces/RosterEntry';
import { PlayerRosterEntry } from './sports/waterpolo/LegacySupport/PlayerRosterEntry';
import firebase from 'firebase';
import { RegistrationRosterEntry } from '../interfaces/EventRegistration';
import { FirebaseBackedCompetitionAdmin } from './competitions/admin/FirebaseBackedCompetitionAdmin';

export class FirebaseBackedCompetition implements Competition {
    id: string;
    event: Event;
    sportProvider: SportProvider;
    database: firebase.database.Database;
    attributes: CompetitionAttributes;
    adminProvider: ListProvider<Resolver<PublicUser>>;
    teamProvider: ListProvider<Resolver<CompetitionEntry>>;
    matchProvider: ListProvider<Resolver<Match>>;
    standingsProvider: ListProvider<TeamRecord>;
    leaderboardProvider: ListProvider<CompetitionPlayerLeaderboard>;
    isLegacyEvent: boolean;
    competitionDates: CompetitionDates;

    constructor(
        id: string,
        sportProvider: SportProvider,
        database: firebase.database.Database,
        attributes: CompetitionAttributes,
        playerLeaderDescriptions: CompetitionPlayerStatDescription[],
        event: Event,
        isLegacyEvent: boolean,
        competitionDates: CompetitionDates
    ) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.event = event;
        this.database = database;
        this.attributes = attributes;
        this.adminProvider = new FirebaseBackedListProvider(
            database.ref(`competitions/${id}/admins`),
            (userID) => {
                return sportProvider.resolverProvider.publicUserResolver(userID);
            }
        );

        this.matchProvider = new FirebaseBackedListProvider(
            database.ref(`competitions/${id}/matches`),
            (matchID) => {
                return sportProvider.matchResolver(matchID);
            }
        );

        this.standingsProvider = new FirebaseBackedCompetitionStandingsProvider(
            database,
            this,
            sportProvider
        );
        this.isLegacyEvent = isLegacyEvent;

        if (isLegacyEvent) {
            this.teamProvider = new FirebaseBackedListProvider(
                this.database.ref(`competitions/${this.id}/teams`),
                (teamID) => {
                    return new ResolverMapping<Team, CompetitionEntry>(
                        teamID,
                        sportProvider.teamResolver(teamID),
                        (team) => {
                            return Promise.resolve(new TeamCompetitionEntry(team, this));
                        }
                    );
                }
            );
        } else {
            this.teamProvider = new FirebaseBackedListProvider(
                this.database.ref(`competitions/${this.id}/entries`),
                (competitionEntryID) => {
                    return this.sportProvider.competitionEntryResolver(this.id, competitionEntryID);
                }
            );
        }

        if (isLegacyEvent) {
            this.leaderboardProvider = new FirebaseBackedCompetitionLeadersProvider(
                this.database.ref(`competitions/${this.id}/cachedData/playerLeaders`),
                playerLeaderDescriptions,
                (playerID, _) => {
                    return new ResolverMapping<Player, RosterEntry>(
                        playerID,
                        this.sportProvider.playerResolver(playerID),
                        (player) => {
                            return Promise.resolve(new PlayerRosterEntry(player, this));
                        }
                    ).asAPromise();
                },
                (statID) => {
                    return this.sportProvider.statResolver(statID);
                }
            );
        } else {
            this.leaderboardProvider = new FirebaseBackedCompetitionLeadersProvider(
                this.database.ref(`competitions/${this.id}/cachedData/playerLeaders`),
                playerLeaderDescriptions,
                (playerID, teamID) => {
                    return this.sportProvider
                        .rosterEntryResolver(this.id, teamID, playerID)
                        .asAPromise();
                },
                (statID) => {
                    return this.sportProvider.statResolver(statID);
                }
            );
        }
        this.competitionDates = competitionDates;
    }

    redeemInvitation(
        user: User,
        competitionEntryInvitationID: string,
        rosterEntries: RegistrationRosterEntry[],
        backingTeam: Team
    ) {
        return user.fetchVerificationToken().then((token) => {
            var body = {
                userToken: token,
                invitationID: competitionEntryInvitationID,
                backingTeamID: backingTeam.id,
                roster: rosterEntries.reduce((currentRosterData, player) => {
                    currentRosterData[player.id] = {
                        name: player.name,
                        capNumber: player.capNumber.databaseValue,
                        position: player.position.databaseValue,
                        backingPlayer: player.id,
                    };
                    return currentRosterData;
                }, {}),
            };
            var apiPath = '/api/competitions/redeemInvitation';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const competitionEntryID = response.data.entryID;
                if (response.status === 200 && competitionEntryID) {
                    return this.sportProvider
                        .competitionEntryResolver(this.id, competitionEntryID)
                        .asAPromise();
                } else if (response.status === 403) {
                    return Promise.reject(RemoveTeamError.missingPermission);
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    adminForUser(user: User) {
        return this.sportProvider.resolverProvider
            .eventAdminPermissionResolver(this.event.id, user.id)
            .asAPromise()
            .then(({ user: _, permission }) => {
                var permissions: Set<CompetitionAdminPermissions> = new Set([]);
                if (
                    permission == EventAdminPermission.canEditEvent ||
                    permission == EventAdminPermission.canEditCompetitions ||
                    permission == EventAdminPermission.canEditTeams
                ) {
                    permissions.add(CompetitionAdminPermissions.entryManagment);
                    permissions.add(CompetitionAdminPermissions.matchManagement);
                } else if (permission == EventAdminPermission.canEditMatches) {
                    permissions.add(CompetitionAdminPermissions.matchManagement);
                }
                if (permissions.size == 0) {
                    return Promise.reject('No permissions for user');
                } else {
                    const competitionAdmin = new FirebaseBackedCompetitionAdmin(
                        user,
                        this,
                        permissions
                    );
                    return Promise.resolve(competitionAdmin);
                }
            });
    }
}
