import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { Player } from '../../interfaces/Player';
import { SportProvider } from '../../interfaces/SportProvider';
import { FirebaseBackedPlayer } from '../FirebaseBackedPlayer';
import firebase from 'firebase';

export class FirebasePlayerTranslator implements FirebaseItemTranslator<Player> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    translate(snapshot, onSuccess: (resolvedItem: Player) => void, onFailure) {
        const playerVal = snapshot.val() || {};
        const { name, teamOrder, position, teamID } = playerVal;
        var { firstName, lastName } = name;
        if (!firstName && !lastName) {
            lastName = '(No Name Provided)';
        }
        const capNumber = this.sportProvider.capNumber(teamOrder);
        const playerPosition = this.sportProvider.playerPosition(position);
        const backingUserID = playerVal['backingUser'];
        if (capNumber && playerPosition && teamID) {
            const teamPromise = this.sportProvider.teamResolver(teamID).asAPromise();
            if (backingUserID) {
                const userPromise = this.sportProvider.resolverProvider
                    .publicUserResolver(backingUserID)
                    .asAPromise();
                Promise.all([teamPromise, userPromise])
                    .then(([team, backingUser]) => {
                        const player = new FirebaseBackedPlayer(
                            this.id,
                            backingUser.name,
                            capNumber,
                            playerPosition,
                            team,
                            backingUser
                        );
                        onSuccess(player);
                    })
                    .catch((error) => {
                        onFailure(error);
                    });
            } else {
                teamPromise
                    .then((resolvedTeam) => {
                        const player = new FirebaseBackedPlayer(
                            this.id,
                            {
                                firstName: firstName,
                                lastName: lastName,
                            },
                            capNumber,
                            playerPosition,
                            resolvedTeam
                        );
                        onSuccess(player);
                    })
                    .catch(() => {
                        onFailure('Failed to resolve team');
                    });
            }
        } else {
            onFailure('Missing player values');
        }
    }
}
