import { useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import useListProviderToArray from '../../../helpers/useListProviderToArray';
import { isOrganization } from '../../../models/interfaces/Organization';
import { User } from '../../../models/interfaces/User';
import { RegistrationData } from '../../interfaces/EventUI';

interface Props {
    user: User;
    handleSelect: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
}

const SelectOrganization = ({ user, handleSelect }: Props) => {
    const organizationArray = useListProviderToArray({
        provider: user.organizationListProvider,
        isType: isOrganization,
    });

    useEffect(() => {
        // if only one organization just accept that organization and move on
        if (organizationArray?.length === 1) {
            handleSelect('organization', organizationArray[0]);
        }
    }, [organizationArray, handleSelect]);

    if (!organizationArray)
        return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

    if (organizationArray.length === 0) return <div>No Organizations found</div>;

    return (
        <>
            <div>Select Organization</div>
            <ListGroup className="d-flex flex-grow-1">
                {organizationArray.map((organization) => {
                    return (
                        <ListGroup.Item
                            key={organization.id}
                            action
                            onClick={() => {
                                handleSelect('organization', organization);
                            }}
                        >
                            <h6>{organization.name}</h6>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </>
    );
};

export default SelectOrganization;
