import { Card, ListGroup, Spinner } from 'react-bootstrap';
import { PlayerMetadata } from '../../models/interfaces/RosterEntry';
import PlayerList from '../players/PlayerList';

interface Props<EntryType> {
    teamPlayers: EntryType[] | undefined;
    cardHeader?: string;
    headerAccessoryView?: JSX.Element;
    listButton?: { buttonElement: JSX.Element; position: 'top' | 'bottom' };
    handleSelectPlayer?: (player: any) => void;
    handleDeletePlayer?: (player: any, toggleState: () => void) => void;
    admin?: boolean;
}

export default function TeamRosterView<EntryType extends PlayerMetadata>({
    teamPlayers,
    cardHeader = 'Roster',
    headerAccessoryView,
    listButton,
    handleSelectPlayer,
    handleDeletePlayer,
    admin,
}: Props<EntryType>) {
    return (
        <Card>
            <Card.Header>
                <span>{cardHeader}</span>
                {headerAccessoryView && headerAccessoryView}
            </Card.Header>
            {listButton && listButton.position === 'top' && listButton.buttonElement}
            <ListGroup className='myListView'>
                {teamPlayers ? (
                    teamPlayers.map((player) => {
                        return (
                            <PlayerList
                                key={player.id}
                                player={player}
                                handleSelectPlayer={handleSelectPlayer}
                                handleDeletePlayer={handleDeletePlayer}
                                admin={admin}
                            />
                        );
                    })
                ) : (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </ListGroup>
            {listButton && listButton.position === 'bottom' && listButton.buttonElement}
        </Card>
    );
}
