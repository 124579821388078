import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { Name, PublicUser, User } from '../interfaces/User';
import { TeamAttributes, TeamColor } from '../interfaces/TeamAttributes';
import { SportProvider } from '../interfaces/SportProvider';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';
import { FirebaseItemResolver, FirebaseItemTranslator } from './translators/FirebaseItemResolver';
import { FirebasePublicUserTranslator } from './translators/FirebasePublicUserTranslator';
import { AccessGroup } from '../interfaces/AccessGroup';
import {
    ClaimPlayerInvitation,
    ClaimPlayerRequest,
    Team,
    TeamAdmin,
    UpdatePlayerResponse,
} from '../interfaces/Team';
import { Competition } from '../interfaces/Competition';
import { Player } from '../interfaces/Player';
import { Match } from '../interfaces/Match';
import { CapNumber, Position } from '../interfaces/RosterEntry';
import { CompetitionEntry } from '../interfaces/Competitions/CompetitionEntry';
import { CombinedListProvider } from './sports/waterpolo/LegacySupport/CombinedListProvider';
import { TeamCompetitionEntry } from './sports/waterpolo/LegacySupport/TeamCompetitionEntry';
import { ResolverMapping } from './sports/waterpolo/LegacySupport/ListProviderMapping';
import { CompetitionEntryRequest } from '../interfaces/Competitions/CompetitionEntryRequest';
import {
    SimpleColumnFormat,
    AttemptColumnFormat,
    MatchTableColumnDescription,
    PlayerTableColumnDescription,
} from '../interfaces/ViewDescriptions/TableColumnDescription';
import axios from 'axios';
import firebase from 'firebase';
import { getPublicURL } from '../../config/values';
import BuildUrl from 'build-url';

export class FirebaseBackedTeam implements Team {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    accessGroup: AccessGroup;
    name: string;
    abbreviation: string;
    attributes: TeamAttributes;

    color: TeamColor;
    playerProvider: ListProvider<Resolver<Player>>;
    competitionEntryProvider: ListProvider<Resolver<CompetitionEntry>>;

    matchStatTableColumnDescriptions: MatchTableColumnDescription[];
    playerStatTableColumnDescriptions: PlayerTableColumnDescription[];

    constructor(
        id: string,
        sportProvider: SportProvider,
        database: firebase.database.Database,
        accessGroup: AccessGroup,
        name: string,
        abbreviation: string,
        attributes: TeamAttributes,
        color: TeamColor,
        matchStatTableColumnDescriptions: MatchTableColumnDescription[],
        playerStatTableColumnDescriptions: PlayerTableColumnDescription[]
    ) {
        this.id = id;
        this.accessGroup = accessGroup;
        this.name = name;
        this.abbreviation = abbreviation;
        this.attributes = attributes;
        this.color = color;

        this.database = database;
        this.sportProvider = sportProvider;
        this.matchStatTableColumnDescriptions = matchStatTableColumnDescriptions;
        this.playerStatTableColumnDescriptions = playerStatTableColumnDescriptions;

        this.playerProvider = new FirebaseBackedListProvider<Player>(
            this.database.ref(`teams/${this.id}/roster`),
            (playerID) => {
                return this.sportProvider.playerResolver(playerID);
            }
        );
        const primaryCompetitionEntryProvider = new FirebaseBackedListProvider<CompetitionEntry>(
            this.database.ref(`teams/${this.id}/competitionEntries`),
            (competitionEntryID) => {
                const teamCompetitionEntryRef = this.database.ref(
                    `teams/${this.id}/competitionEntries/${competitionEntryID}`
                );
                return new FirebaseItemResolver(competitionEntryID, teamCompetitionEntryRef, {
                    translate: (snapshot, onSuccess, onFailure) => {
                        const competitionID = snapshot.val();
                        return this.sportProvider
                            .competitionEntryResolver(competitionID, competitionEntryID)
                            .asAPromise()
                            .then((competitionEntry) => {
                                onSuccess(competitionEntry);
                            })
                            .catch((error) => {
                                onFailure(error);
                            });
                    },
                });
            }
        );

        const legacyCompetitionEntryProvider = new FirebaseBackedListProvider<CompetitionEntry>(
            this.database.ref(`teams/${this.id}/competitions`),
            (competitionID) => {
                const competitionResolver = this.sportProvider.competitionResolver(competitionID);
                return new ResolverMapping<Competition, CompetitionEntry>(
                    competitionID,
                    competitionResolver,
                    (competition) => {
                        return Promise.resolve(new TeamCompetitionEntry(this, competition));
                    }
                );
            }
        );

        this.competitionEntryProvider = new CombinedListProvider(
            primaryCompetitionEntryProvider,
            legacyCompetitionEntryProvider
        );
    }

    resolvedMatchStatColumns(
        columns: MatchTableColumnDescription[],
        matches: Match[]
    ): Promise<{
        totalRow: string[];
        matchRows: (SimpleColumnFormat | AttemptColumnFormat)[][];
    }> {
        const body = {
            teamID: this.id,
            columnIDs: columns.map((column) => {
                return column.id;
            }),
            matchIDs: matches.map((match) => {
                return match.id;
            }),
        };
        var apiPath = '/api/teams/matchColumns';
        if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
            apiPath = 'http://localhost:3000' + apiPath;
        }
        return axios
            .post(apiPath, body)
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    const data = response.data;
                    const resolvedColumns: (SimpleColumnFormat | AttemptColumnFormat)[][] = data;
                    const totalRow: string[] = columns.map((column, columnIndex) => {
                        const allColumnData = resolvedColumns.map((matchColumns) => {
                            if (columnIndex < matchColumns.length) {
                                return matchColumns[columnIndex];
                            } else {
                                const emptyFormat: SimpleColumnFormat = {
                                    totalCount: 0,
                                    text: '',
                                };
                                return emptyFormat;
                            }
                        });
                        return column.totalFormatter(allColumnData ?? []);
                    });

                    return Promise.resolve({ totalRow, matchRows: resolvedColumns });
                } else {
                    return Promise.reject(
                        `Failed to resolve match columns: Received status: ${status} ${response}.`
                    );
                }
            })
            .catch((error) => {
                return Promise.reject(`Failed to resolve match columns: Received error: ${error}.`);
            });
    }

    resolvedPlayerStatColumns(
        columns: PlayerTableColumnDescription[],
        players: Player[],
        matches: Match[]
    ) {
        const body = {
            teamID: this.id,
            matchIDs: matches.map((match) => {
                return match.id;
            }),
            playerIDs: players.map((player) => {
                return player.id;
            }),
            columnIDs: columns.map((column) => {
                return column.id;
            }),
        };
        var apiPath = '/api/teams/playerColumns';
        if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
            apiPath = 'http://localhost:3000' + apiPath;
        }
        return axios
            .post(apiPath, body)
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    const data = response.data;
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(
                        `Failed to resolve player columns: Received status: ${status}.`
                    );
                }
            })
            .catch((error) => {
                return Promise.reject(
                    `Failed to resolve player columns: Received error: ${error}.`
                );
            });
    }

    adminForUser(user: User) {
        return new Promise<TeamAdmin>((resolve, reject) => {
            const organizationID = this.accessGroup.organizationID;
            if (organizationID) {
                const organizationUserRef = this.database.ref(
                    `organizations/${organizationID}/users`
                );
                const adminProvider = new FirebaseBackedListProvider<PublicUser>(
                    organizationUserRef,
                    (userID) => {
                        const userPath = `users/${userID}/public`;
                        return new FirebaseItemResolver(
                            userID,
                            this.database.ref(userPath),
                            new FirebasePublicUserTranslator(
                                userID,
                                this.database,
                                this.sportProvider
                            )
                        );
                    }
                );
                adminProvider.once().then((userResolvers) => {
                    const userResolver = userResolvers.find((resolver) => {
                        return resolver.id === user.id;
                    });
                    if (userResolver) {
                        const admin = new TeamAdminImplementation(user, this);
                        resolve(admin);
                    } else {
                        reject('Admin provider missing permission');
                    }
                });
            } else {
                const legacyAdminProvider = new FirebaseBackedListProvider<PublicUser>(
                    this.database.ref(`accessGroups/${this.accessGroup.id}/users`),
                    (userID) => {
                        const userPath = `users/${userID}/public`;
                        return new FirebaseItemResolver(
                            userID,
                            this.database.ref(userPath),
                            new FirebasePublicUserTranslator(
                                userID,
                                this.database,
                                this.sportProvider
                            )
                        );
                    }
                );

                legacyAdminProvider.once().then((userResolvers) => {
                    const userResolver = userResolvers.find((resolver) => {
                        return resolver.id === user.id;
                    });
                    if (userResolver) {
                        const admin = new TeamAdminImplementation(user, this);
                        resolve(admin);
                    } else {
                        reject('Legacy admin provider missing permission');
                    }
                });
            }
        });
    }
}

class FirebaseBackedClaimPlayerInvitationTranslator
    implements FirebaseItemTranslator<ClaimPlayerInvitation>
{
    invitationID: string;
    sportProvider: SportProvider;
    constructor(invitationID: string, sportProvider: SportProvider) {
        this.invitationID = invitationID;
        this.sportProvider = sportProvider;
    }
    translate(snapshot, onSuccess, onFailure) {
        const val = snapshot.val();
        const playerID = val['playerID'];
        const email = val['email'];

        this.sportProvider
            .playerResolver(playerID)
            .asAPromise()
            .then((player) => {
                const app_base_url = getPublicURL();
                const linkURL = BuildUrl(app_base_url, {
                    path: `redeemClaimPlayerInvitation/${this.invitationID}`,
                });
                const invitation: ClaimPlayerInvitation = {
                    id: this.invitationID,
                    player,
                    email,

                    linkURL,
                };
                onSuccess(invitation);
            })
            .catch((error) => {
                onFailure(error);
            });
    }
}

class TeamAdminImplementation implements TeamAdmin {
    user: User;
    team: FirebaseBackedTeam;
    deactivatedPlayerProvider: ListProvider<Resolver<Player>>;
    pendingRequestProvider: ListProvider<Resolver<CompetitionEntryRequest>>;
    claimPlayerRequestProvider: ListProvider<Resolver<ClaimPlayerRequest>>;
    claimPlayerInvitationProvider: ListProvider<Resolver<ClaimPlayerInvitation>>;

    constructor(user: User, team: FirebaseBackedTeam) {
        this.user = user;
        this.team = team;
        const deactivatedPlayerRef = team.database.ref(`/teams/${team.id}/deactivatedPlayers`);
        this.deactivatedPlayerProvider = new FirebaseBackedListProvider(
            deactivatedPlayerRef,
            (playerID) => {
                return this.team.sportProvider.playerResolver(playerID);
            }
        );
        const pendingRequestDatabase = team.database.ref(`/teams/${team.id}/pendingEntryRequests`);
        this.pendingRequestProvider = new PendingEntryRequestProvider(
            pendingRequestDatabase,
            team.sportProvider
        );

        const claimRequestsRef = team.database.ref(`/teams/${team.id}/playerClaimRequests`);
        this.claimPlayerRequestProvider = new FirebaseBackedListProvider(
            claimRequestsRef,
            (claimRequestID) => {
                const claimRequestRef = team.database.ref(
                    `/teams/${team.id}/playerClaimRequests/${claimRequestID}`
                );
                return new FirebaseItemResolver(claimRequestID, claimRequestRef, {
                    translate: (snapshot, onSuccess, onFailure) => {
                        const val = snapshot.val() || {};
                        const userID = val['requestingUserID'];
                        const playerID = val['playerID'];
                        const email = val['email'];
                        const playerPromise = this.team.sportProvider
                            .playerResolver(playerID)
                            .asAPromise();
                        const userPromise = this.team.sportProvider.resolverProvider
                            .publicUserResolver(userID)
                            .asAPromise();
                        Promise.all([playerPromise, userPromise])
                            .then(([player, user]) => {
                                const claimRequest: ClaimPlayerRequest = {
                                    player,
                                    email,
                                    backingUser: user,
                                };
                                onSuccess(claimRequest);
                            })
                            .catch((error) => {
                                onFailure(error);
                            });
                    },
                });
            }
        );

        const claimInvitationsRef = team.database.ref(`/teams/${team.id}/playerClaimInvitations`);
        this.claimPlayerInvitationProvider = new FirebaseBackedListProvider(
            claimInvitationsRef,
            (invitationID) => {
                const claimInvitationRef = team.database.ref(
                    `claimPlayerInvitations/${invitationID}`
                );
                return new FirebaseItemResolver(
                    invitationID,
                    claimInvitationRef,
                    new FirebaseBackedClaimPlayerInvitationTranslator(
                        invitationID,
                        this.team.sportProvider
                    )
                );
            }
        );
    }

    acceptClaimRequest(request: ClaimPlayerRequest): Promise<Player> {
        const player = request.player;
        return this.updatePlayer(
            player,
            request.backingUser.name,
            player.capNumber,
            player.position,
            request.backingUser
        ).then((updatePlayerResponse) => {
            return updatePlayerResponse.players[0];
        });
    }

    sendClaimInvitation(player: Player, email: string): Promise<ClaimPlayerInvitation> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                email,
                teamID: this.team.id,
                playerID: player.id,
            };
            var apiPath = '/api/teams/sendClaimPlayerInvitation';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    const invitationID = response.data.invitationID;
                    if (response.status == 201) {
                        const claimInvitationRef = this.team.database.ref(
                            `claimPlayerInvitations/${invitationID}`
                        );
                        return new FirebaseItemResolver<ClaimPlayerInvitation>(
                            invitationID,
                            claimInvitationRef,
                            new FirebaseBackedClaimPlayerInvitationTranslator(
                                invitationID,
                                this.team.sportProvider
                            )
                        ).asAPromise();
                    } else {
                        return Promise.reject(`Failed create an invitation: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        });
    }

    rejectClaimRequest(request: ClaimPlayerRequest): Promise<void> {
        return Promise.reject('not yet implemented');
    }

    addPlayer(name: Name, capNumber: CapNumber, position: Position, backingUser?: PublicUser) {
        return this.addPlayers([{ name, capNumber, position, backingUser }]).then(
            (addedPlayers) => {
                return {
                    players: addedPlayers[0],
                    updatedCompetitionEntries: addedPlayers.updatedCompetitionEntries,
                    skippedCompetitionEntries: addedPlayers.skippedCompetitionEntries,
                };
            }
        );
    }

    addPlayers(
        playerData: {
            name: Name;
            capNumber: CapNumber;
            position: Position;
            backingUser?: PublicUser;
        }[]
    ): Promise<UpdatePlayerResponse> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                teamID: this.team.id,
                players: playerData.map((player) => {
                    var playerDatabaseValues = {
                        accessGroup: this.team.accessGroup.id,
                        teamID: this.team.id,
                        name: {
                            firstName: player.name.firstName,
                            lastName: player.name.lastName,
                        },
                        position: player.position.databaseValue,
                        teamOrder: player.capNumber.databaseValue,
                    };
                    if (player.backingUser) {
                        playerDatabaseValues['backingUser'] = player.backingUser.id;
                    }
                    return playerDatabaseValues;
                }),
            };
            var apiPath = '/api/teams/addPlayers';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    const status = response.status;
                    const playerIDs = response.data.playerIDs;
                    const updatedCompetitionEntryIDs = response.data.syncedEntries;
                    const skippedCompetitionEntryIDs = response.data.skippedCompetitionEntries;
                    if (status === 201 && Array.isArray(playerIDs)) {
                        const playerPromises = playerIDs.map((playerID) => {
                            return this.team.sportProvider.playerResolver(playerID).asAPromise();
                        });
                        return Promise.all(playerPromises).then((players) => {
                            return {
                                players,
                                updatedCompetitionEntries: updatedCompetitionEntryIDs.map(
                                    (competitionEntryID) => {
                                        return this.team.sportProvider
                                            .competitionEntryResolver(
                                                competitionEntryID.competitionID,
                                                competitionEntryID.entryID
                                            )
                                            .asAPromise();
                                    }
                                ),
                                skippedCompetitionEntries: skippedCompetitionEntryIDs.map(
                                    (competitionEntryID) => {
                                        return this.team.sportProvider
                                            .competitionEntryResolver(
                                                competitionEntryID.competitionID,
                                                competitionEntryID.entryID
                                            )
                                            .asAPromise();
                                    }
                                ),
                            };
                        });
                    } else {
                        return Promise.reject(`Failed to add players: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        });
    }

    updatePlayer(
        player: Player,
        name: Name,
        capNumber: CapNumber,
        position: Position,
        backingUser?: PublicUser
    ) {
        return this.user.fetchVerificationToken().then((userToken) => {
            var updatedPlayerData = {
                name: {
                    firstName: name.firstName,
                    lastName: name.lastName,
                },
                position: position.databaseValue,
                teamOrder: capNumber.databaseValue,
            };
            if (backingUser) {
                updatedPlayerData['backingUser'] = backingUser.id;
            }
            const body = {
                userToken,
                teamID: this.team.id,
                playerID: player.id,
                playerMetadata: updatedPlayerData,
            };
            var apiPath = '/api/teams/updatePlayer';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    const status = response.status;
                    const updatedCompetitionEntryIDs = response.data.syncedEntries;
                    const skippedCompetitionEntryIDs = response.data.skippedCompetitionEntries;
                    if (status === 200) {
                        return this.team.sportProvider
                            .playerResolver(player.id)
                            .asAPromise()
                            .then((player) => {
                                return {
                                    players: [player],
                                    updatedCompetitionEntries: updatedCompetitionEntryIDs.map(
                                        (competitionEntryID) => {
                                            return this.team.sportProvider
                                                .competitionEntryResolver(
                                                    competitionEntryID.competitionID,
                                                    competitionEntryID.entryID
                                                )
                                                .asAPromise();
                                        }
                                    ),
                                    skippedCompetitionEntries: skippedCompetitionEntryIDs.map(
                                        (competitionEntryID) => {
                                            return this.team.sportProvider
                                                .competitionEntryResolver(
                                                    competitionEntryID.competitionID,
                                                    competitionEntryID.entryID
                                                )
                                                .asAPromise();
                                        }
                                    ),
                                };
                            });
                    } else {
                        return Promise.reject(`Failed to add players: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        });
    }
    reactivatePlayers(players: Player[]): Promise<void> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                teamID: this.team.id,
                playerIDs: players.map((player) => {
                    return player.id;
                }),
            };
            var apiPath = '/api/teams/reactivatePlayers';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Failed to delete players: ${response.status}`);
                }
            });
        });
    }

    removePlayer(player: Player) {
        return this.removePlayers([player]);
    }
    removePlayers(players: Player[]): Promise<void> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                teamID: this.team.id,
                playerIDs: players.map((player) => {
                    return player.id;
                }),
            };
            var apiPath = '/api/teams/removePlayers';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Failed to delete players: ${response.status}`);
                }
            });
        });
    }
}

class PendingEntryRequestProvider implements ListProvider<Resolver<CompetitionEntryRequest>> {
    databaseReference: firebase.database.Reference;
    sportProvider: SportProvider;

    constructor(databaseReference: firebase.database.Reference, sportProvider: SportProvider) {
        this.databaseReference = databaseReference;
        this.sportProvider = sportProvider;
    }

    addListener(callback) {
        return this.databaseReference.on('value', (snapshot) => {
            const ids = this.childIDsFromSnapshot(snapshot);
            const resolvers = ids.map((idPair) => {
                return this.sportProvider.competitionEntryRequestResolver(
                    idPair.competitionID,
                    idPair.requestID
                );
            });
            callback(resolvers);
        });
    }

    removeListener(token: any) {
        this.databaseReference.off('value', token);
    }

    once() {
        return new Promise<Array<Resolver<CompetitionEntryRequest>>>((resolve, reject) => {
            this.databaseReference.once(
                'value',
                (snapshot) => {
                    const ids = this.childIDsFromSnapshot(snapshot);
                    const resolvers = ids.map((idPair) => {
                        return this.sportProvider.competitionEntryRequestResolver(
                            idPair.competitionID,
                            idPair.requestID
                        );
                    });
                    resolve(resolvers);
                },
                (error) => {
                    reject('failed to generate resolvers: ' + error);
                }
            );
        });
    }

    childIDsFromSnapshot(snapshot: firebase.database.DataSnapshot) {
        const val = snapshot.val();
        var ids: { competitionID: string; requestID: string }[] = [];
        for (const requestID in val) {
            if (typeof requestID === 'string' && typeof val[requestID] === 'string') {
                ids.push({
                    competitionID: val[requestID],
                    requestID: requestID,
                });
            }
        }
        return ids;
    }
}
