import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { EventCreationMetadata, Name, User, VendorAccountContext, WaterPoloAustraliaAdmin } from '../interfaces/User';

import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';
import { Organization } from '../interfaces/Organization';
import { FirebaseItemResolver } from './translators/FirebaseItemResolver';
import { FirebaseBackedOrganization } from './FirebaseBackedOrganization';
import { SportProvider } from '../interfaces/SportProvider';
import { v4 as uuid } from 'uuid';
import { Event } from '../interfaces/Event';
import { EventPaymentSession } from '../interfaces/Events/EventPaymentSession';
import axios from 'axios';
import { Player } from '../interfaces/Player';
import { VendorAccount, VendorOnboarding } from '../interfaces/Vendors/VendorAccount';
import moment from 'moment';
import { WaterPoloAustraliaAdminSupport } from './RevSportIntegrationSupport';

export class FirebaseBackedUser implements User {
    id: string;
    name: Name;
    private sportProvider: SportProvider;
    firebaseDatabase: firebase.database.Database;
    firebaseAuth: firebase.auth.Auth;
    firebaseUser: firebase.User;

    waterPoloAustraliaAdmin: WaterPoloAustraliaAdmin;

    email: string;
    backedPlayers: ListProvider<Resolver<Player>>;
    eventProvider: ListProvider<Resolver<Event>>;
    organizationListProvider: ListProvider<Resolver<Organization>>;

    constructor(
        id: string,
        name: Name,
        sportProvider: SportProvider,
        firebaseDatabase: firebase.database.Database,
        firebaseAuth: firebase.auth.Auth,
        firebaseUser: firebase.User,

        backedPlayers: ListProvider<Resolver<Player>>,
        email: string
    ) {
        this.id = id;
        this.name = name;
        this.sportProvider = sportProvider;
        this.firebaseDatabase = firebaseDatabase;
        this.firebaseAuth = firebaseAuth;
        this.firebaseUser = firebaseUser;
        this.email = email;
        const userOrganizationReference = firebaseDatabase.ref(`users/${id}/organizations`);
        this.organizationListProvider = new FirebaseBackedListProvider(
            userOrganizationReference,
            (organizationID) => {
                const organizationReference = firebaseDatabase.ref(
                    `organizations/${organizationID}`
                );
                return new FirebaseItemResolver(organizationID, organizationReference, {
                    translate: (snapshot, onSuccess, onFailure) => {
                        const val = snapshot.val() || {};
                        const name = val['name'];
                        if (name) {
                            const organization = new FirebaseBackedOrganization(
                                organizationID,
                                firebaseDatabase,
                                sportProvider,
                                name
                            );
                            onSuccess(organization);
                        } else {
                            const error = "Could not load organization's name";
                            onFailure(error);
                        }
                    },
                });
            }
        );

        const userEventReference = firebaseDatabase.ref(`/users/${id}/events`);
        this.eventProvider = new FirebaseBackedListProvider(userEventReference, (eventID) => {
            return sportProvider.eventResolver(eventID);
        });
        this.backedPlayers = backedPlayers;

        this.waterPoloAustraliaAdmin = new WaterPoloAustraliaAdminSupport(this, this.sportProvider)
    }

    fetchVerificationToken() {
        return this.firebaseUser.getIdToken();
    }

    createOrganization(name: string) {
        const organizationID = uuid();
        var userObject = {};
        userObject[this.id] = true;
        const organizationObject = {
            name: name,
            users: userObject,
        };
        const organizationRef = this.firebaseDatabase.ref(`organizations/${organizationID}`);
        return organizationRef.set(organizationObject).then(() => {
            const userOrganizationReference = this.firebaseDatabase.ref(
                `users/${this.id}/organizations/${organizationID}`
            );
            return userOrganizationReference.set(true).then(() => {
                return new FirebaseItemResolver<Organization>(organizationID, organizationRef, {
                    translate: (snapshot, onSuccess, onFailure) => {
                        const val = snapshot.val() || {};
                        const name = val['name'];
                        if (name) {
                            const organization = new FirebaseBackedOrganization(
                                organizationID,
                                this.firebaseDatabase,
                                this.sportProvider,
                                name
                            );
                            onSuccess(organization);
                        } else {
                            const error = "Could not load organization's name";
                            onFailure(error);
                        }
                    },
                }).asAPromise();
            });
        });
    }

    createEventPaymentSession(metadata: EventCreationMetadata) {
        return this.fetchVerificationToken().then((token) => {
            var eventMetadata = {
                eventName: metadata.name,
                eventCategory: metadata.category.databaseValue,
                competitionStartDate: moment(metadata.dateRange.startDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
                competitionEndDate: moment(metadata.dateRange.endDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
            };
            if (metadata.division) {
                eventMetadata['eventDivision'] = metadata.division.databaseValue;
            }
            const body = {
                userToken: token,
                eventMetadata,
            };
            var apiPath = '/api/users/createEventPaymentSession';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 201) {
                    const responseData = response.data || {};
                    const url = responseData['checkoutURL'];
                    const eventPaymentSession: EventPaymentSession = {
                        paymentURL: url,
                    };
                    return eventPaymentSession;
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    validateEventPaymentSession(paymentID: string): Promise<Event> {
        return this.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                sessionID: paymentID,
            };
            var apiPath = '/api/users/validateEventPaymentSession';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    const responseData = response.data || {};
                    const eventID = responseData['eventID'];
                    return this.sportProvider.eventResolver(eventID).asAPromise();
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    createEvent(metadata: EventCreationMetadata, registrationPrice: number): Promise<Event> {
        return this.fetchVerificationToken().then((token) => {
            var eventMetadata = {
                eventName: metadata.name,
                eventCategory: metadata.category.databaseValue,
                competitionStartDate: moment(metadata.dateRange.startDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
                competitionEndDate: moment(metadata.dateRange.endDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
            };
            if (metadata.division) {
                eventMetadata['eventDivision'] = metadata.division.databaseValue;
            }
            const body = {
                userToken: token,
                eventMetadata,
                registrationPrice: registrationPrice * 100,
            };
            var apiPath = '/api/users/createEvent';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 201) {
                    const responseData = response.data || {};
                    const eventID = responseData['eventID'];
                    return this.sportProvider.eventResolver(eventID).asAPromise();
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    hasPendingClaimForPlayer(player: Player): Promise<boolean> {
        return this.firebaseDatabase
            .ref(`/users/${this.id}/playerClaimRequests/${player.id}`)
            .once('value')
            .then((snapshot) => {
                return snapshot.exists();
            });
    }

    redeemClaimPlayerInvitation(invitationID: string): Promise<Player> {
        return this.fetchVerificationToken()
            .then((token) => {
                const body = {
                    userToken: token,
                    invitationID,
                };
                var apiPath = '/api/users/redeemClaimPlayerInvitation';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body).then((response) => {
                    if (response.status === 200) {
                        const responseData = response.data || {};
                        const playerID = responseData['playerID'];
                        return this.sportProvider.playerResolver(playerID).asAPromise();
                    } else {
                        return Promise.reject(response);
                    }
                });
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    fetchVendorAccount(
        allowsAccountCreation: boolean,
        generateLinks: boolean,
        accountContext: VendorAccountContext
    ): Promise<[VendorAccount, (VendorOnboarding | undefined)?]> {
        return this.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                allowsAccountCreation,
                generateLinks,
                accountContext,
            };
            var apiPath = '/api/users/vendorAccount';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    const responseData = response.data || {};

                    const chargesEnabled = responseData['chargesEnabled'];
                    const payoutsEnabled = responseData['payoutsEnabled'];
                    const onboardingURL = responseData['onboardingURL'];
                    const vendorOnboarding: VendorOnboarding | undefined = onboardingURL
                        ? { onboardingURL }
                        : undefined;

                    return Promise.resolve([
                        {
                            chargesEnabled,
                            payoutsEnabled,
                        },
                        vendorOnboarding,
                    ]);
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    leaveEvent(event: Event) {
        return Promise.reject('remove event not implemented');
    }

    signOut() {
        return this.firebaseAuth.signOut();
    }
}
