import { SportProvider } from '../../interfaces/SportProvider';
import { Competition, CompetitionAttributes } from '../../interfaces/Competition';
import { FirebaseBackedCompetition } from '../FirebaseBackedCompetition';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import firebase from 'firebase';
import moment from 'moment';

export class FirebaseCompetitionTranslator implements FirebaseItemTranslator<Competition> {
    id: string;
    database: firebase.database.Database;
    private sportProvider: SportProvider;

    constructor(id: string, sportProvider: SportProvider, database: firebase.database.Database) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
    }
    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (Competition) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val() || {};
        const name = val['name'];
        const teamCategoryDatabaseValue = val['teamCategory'];
        const teamDivisionDatabaseValue = val['teamDivision'];
        const teamGenderDatabaseValue = val['gender'];
        const updateTeamRosterAutomatically = val['updateTeamRosterAutomatically'] || false;

        const teamTypes = this.sportProvider.teamValues(
            teamCategoryDatabaseValue,
            teamDivisionDatabaseValue
        );
        const { gender } = this.sportProvider.teamGender(teamGenderDatabaseValue);

        if (
            typeof name === 'string' &&
            name.length > 0 &&
            teamTypes.category &&
            teamTypes.division &&
            gender
        ) {
            const competitionAttributes: CompetitionAttributes = {
                name: name,
                gender: gender,
                category: teamTypes.category,
                division: teamTypes.division,
                externalID: val['externalID'],
                isIndexed: !!val['isIndexed'],
                updateTeamRosterAutomatically,
            };
            var firstScheduledMatchDateString = val['firstScheduledMatchDate'];
            var firstScheduledMatchDate: Date | undefined = undefined;
            if (firstScheduledMatchDateString) {
                firstScheduledMatchDate = moment(
                    lastMatchDateString,
                    'YYYY-MM-DD HH:mm:ss Z'
                ).toDate();
            }

            var lastMatchDateString = val['lastMatchCompletedDate'];
            var lastCompletedMatchDate: Date | undefined = undefined;
            if (lastMatchDateString) {
                lastCompletedMatchDate = moment(
                    lastMatchDateString,
                    'YYYY-MM-DD HH:mm:ss Z'
                ).toDate();
            }

            const eventID = val['event'];
            if (eventID) {
                const isLegacyEvent = !!val['teams'];

                const eventResolver = this.sportProvider.eventResolver(eventID);
                eventResolver.asAPromise().then((event) => {
                    const competition = new FirebaseBackedCompetition(
                        this.id,
                        this.sportProvider,
                        this.database,
                        competitionAttributes,
                        this.sportProvider.competitionLeaderDescriptions,
                        event,
                        isLegacyEvent,
                        {
                            scheduledRange: event.attributes.competitionDateRange,
                            firstScheduledMatchDate: firstScheduledMatchDate,
                            lastCompletedMatchDate: lastCompletedMatchDate,
                        }
                    );
                    onSuccess(competition);
                });
            } else {
                onFailure('Could not find an event for this competition');
            }
        } else {
            onFailure('missing competition attributes');
        }
    }
}
