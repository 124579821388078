import { useEffect, useState } from "react";
import { SportProvider } from "../../models/interfaces/SportProvider"
import { Event } from "../../models/interfaces/Event";
import { Spinner } from "react-bootstrap";
import { FeaturedEventView } from "./featuredEvent/FeaturedEventView";
import { User } from "../../models/interfaces/User";

interface Props {
    sportProvider: SportProvider
    user ?: User
}

export function FeaturedEventsView({ sportProvider, user }: Props) {
    const [featuredEvents, setFeaturedEvents] = useState<Event[] | undefined>(
        undefined
    );
    useEffect(() => {
        sportProvider.fanResolverProviders.featuredEventProvider.once().then((resolvers) => {
            return Promise.all(
                resolvers.map((resolver) => {
                    return resolver.asAPromise();
                })
            ).then((events) => {
                setFeaturedEvents(events);
            });
        });
    }, [sportProvider.fanResolverProviders]);


    return (
        featuredEvents ?
            (featuredEvents.length > 0 ?
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {featuredEvents.map((event) => {
                        return <>
                            <FeaturedEventView key={event.id} sportProvider={sportProvider} event={event} user={user} />
                            <hr />
                        </>
                    })}
                </div> :
                <>No featured tournamnets</>
            ) :
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Spinner animation="border" />
                <h4>Loading</h4>
            </div>
    )
}
