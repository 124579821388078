import { ListProvider, ListProviderCallback } from '../../../../interfaces/ListProvider';

export class FilteringListProvider<T> implements ListProvider<T> {
    listProvider: ListProvider<T>;
    isIncludedFunction: (T) => Promise<boolean>;

    constructor(listProvider: ListProvider<T>, isIncludedFunction: (T) => Promise<boolean>) {
        this.listProvider = listProvider;
        this.isIncludedFunction = isIncludedFunction;
    }

    addListener(callback: ListProviderCallback<T>) {
        return this.listProvider.addListener((items) => {
            this.handleItems(items).then((filteredItems) => {
                callback(filteredItems);
            });
        });
    }

    removeListener(token) {
        this.listProvider.removeListener(token);
    }

    once() {
        return this.listProvider.once().then((items) => {
            return this.handleItems(items);
        });
    }

    handleItems(items: T[]) {
        const mappedItems = items.map((item) => {
            const isIncludedPromise = this.isIncludedFunction(item);
            return isIncludedPromise.then((isIncluded) => {
                return {
                    isIncluded: isIncluded,
                    item: item,
                };
            });
        });
        return Promise.all(mappedItems).then((evaluatedItems) => {
            return evaluatedItems.reduce((includedItems, currentItem) => {
                if (currentItem.isIncluded) {
                    return includedItems.concat([currentItem.item]);
                } else {
                    return includedItems;
                }
            }, new Array<T>());
        });
    }
}
