import { DateRange, Event, EventAttributes } from './Event';
import { ListProvider } from './ListProvider';
import { Resolver } from './Resolver';
import { Organization } from './Organization';
import { Player } from './Player';
import { VendorAccount, VendorOnboarding } from './Vendors/VendorAccount';
import { EventPaymentSession } from './Events/EventPaymentSession';
import { TeamCategory, TeamDivision } from './TeamAttributes';
import { RevSportEvent } from './RevSportIntegration';

export class Name {
    firstName: string;
    lastName: string;

    constructor(firstName: string, lastName: string) {
        this.firstName = firstName;
        this.lastName = lastName;
    }
}

export function isPublicUser(candidate: any): candidate is PublicUser {
    return (
        (candidate as PublicUser).name !== undefined &&
        (candidate as PublicUser).backedPlayers !== undefined
    );
}

export interface PublicUser {
    id: string;
    name: Name;

    backedPlayers: ListProvider<Resolver<Player>>;
}

export enum VendorAccountContext {
    account = 'vendor_account',
    createEvent = 'create_event',
}

export function vendorAccountContextForString(value: string) {
    if (value === 'vendor_account') {
        return VendorAccountContext.account;
    } else if (value === 'create_event') {
        return VendorAccountContext.createEvent;
    } else {
        return undefined;
    }
}

export interface EventCreationMetadata {
    name: string;
    category: TeamCategory;
    division?: TeamDivision;

    dateRange: DateRange;
}

export interface EventCreator {
    fetchVendorAccount: (
        allowsAccountCreation: boolean,
        generateLinks: boolean,
        accountContext: VendorAccountContext
    ) => Promise<[VendorAccount, VendorOnboarding?]>;

    createEventPaymentSession: (metadata: EventCreationMetadata) => Promise<EventPaymentSession>;
    createEvent: (metadata: EventCreationMetadata, registrationPrice: number) => Promise<Event>;
    validateEventPaymentSession: (paymentID: string) => Promise<Event>;
    leaveEvent: (event: Event) => Promise<void>;
    eventProvider: ListProvider<Resolver<Event>>;
}

export interface PlayerBacker {
    hasPendingClaimForPlayer: (player: Player) => Promise<boolean>;
    redeemClaimPlayerInvitation: (invitationID: string) => Promise<Player>;
}

export interface User extends PublicUser, EventCreator, PlayerBacker {
    email: string;
    fetchVerificationToken(): Promise<string>;

    createOrganization(name: string): Promise<Organization>;
    organizationListProvider: ListProvider<Resolver<Organization>>;
    
    waterPoloAustraliaAdmin: WaterPoloAustraliaAdmin

    signOut: () => Promise<void>;
}

export interface RevSportAccount {
    id: string
    name: string
}

export interface WaterPoloAustraliaAdmin {
    fetchRevSportAccounts: () => Promise<RevSportAccount[]>
    // addRevSportAccount: (name: string, apiKey: string, secretKey: string) => Promise<RevSportAccount>
    fetchRevSportEvents: (account: RevSportAccount) => Promise<RevSportEvent[]>
}
