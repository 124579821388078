import { Resolver } from '../../interfaces/Resolver';
import firebase from 'firebase';
import moment from 'moment';

export interface FirebaseItemTranslator<T> {
    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (T: T) => void,
        onFailure: (string) => void
    ): void;
}

export function dateFromFirebaseString(dateString): Date | undefined {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss Z').toDate();
}

export class FirebaseItemResolver<T> implements Resolver<T> {
    id: string;
    hasResolved: boolean;
    resolvedObject?: T;

    databaseReference: firebase.database.Reference;
    itemTranslator: FirebaseItemTranslator<T>;

    constructor(id, databaseReference, itemTranslator: FirebaseItemTranslator<T>) {
        this.id = id;
        this.hasResolved = false;
        this.databaseReference = databaseReference;
        this.itemTranslator = itemTranslator;
    }

    resolve(onSuccess: (resolvedObject: T) => void, onFailure: (errorMessage: string) => void) {
        this.databaseReference.once(
            'value',
            (snapshot) => {
                this.itemTranslator.translate(
                    snapshot,
                    (translatedItem) => {
                        this.resolvedObject = translatedItem;
                        this.hasResolved = true;
                        onSuccess(translatedItem);
                    },
                    (errorMessage) => {
                        this.hasResolved = true;
                        onFailure(errorMessage);
                    }
                );
            },
            (errorMessage) => {
                this.hasResolved = true;
                onFailure(
                    `Failed to retrieve data at path: ${this.databaseReference.toString()}, errorMessage: ${errorMessage}`
                );
            }
        );
    }

    cancel(token: any) {
        if (token) {
            this.databaseReference.off('value', token);
        }
    }

    asAPromise() {
        return new Promise<T>((resolvePromise, rejectPromise) => {
            this.resolve(
                (returnedObject) => {
                    resolvePromise(returnedObject);
                },
                (errorMessage) => {
                    rejectPromise(errorMessage);
                }
            );
        });
    }
}
