import { StatType } from '../../../interfaces/Stat';

export const WaterPoloGoal: StatType = {
    databaseValue: 'GOAL',
    displayName: 'Goal',
    abbreviation: 'G',
    scoreValue: 1,
};

export const WaterPoloSave: StatType = {
    databaseValue: 'SAVE',
    displayName: 'Save',
    abbreviation: 'S',
    scoreValue: 0,
};

export const WaterPoloBlock: StatType = {
    databaseValue: 'BLOCK',
    displayName: 'Block',
    abbreviation: 'B',
    scoreValue: 0,
};

export const WaterPoloShot = {
    databaseValue: 'SHOT',
    displayName: 'Shot',
    abbreviation: 'SH',
    scoreValue: 0,
};

export const WaterPoloEjectionDrawn = {
    databaseValue: 'EJECTION_DRAWN',
    displayName: 'Ejection Drawn',
    abbreviation: 'ED',
    scoreValue: 0,
};

export const WaterPoloPenaltyDrawn = {
    databaseValue: 'PENALTY_DRAWN',
    displayName: 'Penalty Drawn',
    abbreviation: 'PD',
    scoreValue: 0,
};

export const WaterPoloSteal = {
    databaseValue: 'STEAL',
    displayName: 'Steal',
    abbreviation: 'ST',
    scoreValue: 0,
};

export const WaterPoloTimeout = {
    databaseValue: 'TIMEOUT',
    displayName: 'Timeout',
    abbreviation: 'TO',
    scoreValue: 0,
};

export const WaterPoloMajorFoul = {
    databaseValue: 'MAJOR_FOUL',
    displayName: 'Major Foul',
    abbreviation: 'MF',
    scoreValue: 0,
};

export const WaterPoloAssist = {
    databaseValue: 'ASSIST',
    displayName: 'Assist',
    abbreviation: 'A',
    scoreValue: 0,
};

export const WaterPoloSprintWon = {
    databaseValue: 'SPRINT_WON',
    displayName: 'Sprint Won',
    abbreviation: 'SW',
    scoreValue: 0,
};

export const WaterPoloTeamMisconduct = {
    databaseValue: 'teamMisconduct',
    displayName: 'Team Misconduct',
    abbreviation: 'TM',
    scoreValue: 0,
};

export const WaterPoloPlayerMisconduct = {
    databaseValue: 'playerMisconduct',
    displayName: 'Player Misconduct',
    abbreviation: 'PM',
    scoreValue: 0,
};

export const WaterPoloGameRemark = {
    databaseValue: 'gameRemark',
    displayName: 'Remark',
    abbreviation: 'R',
    scoreValue: 0,
};

export const WaterPoloFlagrant = {
    databaseValue: 'flagrant',
    displayName: 'Flagrant Misconduct',
    abbreviation: 'FM',
    scoreValue: 0,
};

export const WaterPoloOffensiveFoul = {
    databaseValue: 'OFFENSIVE_FOUL',
    displayName: 'Offensive Foul',
    abbreviation: 'OF',
    scoreValue: 0,
};

export const WaterPoloStats: StatType[] = [
    {
        databaseValue: 'SUB_IN',
        displayName: 'Sub. In',
        abbreviation: 'SI',
        scoreValue: 0,
    },
    {
        databaseValue: 'SUB_OUT',
        displayName: 'Sub. Out',
        abbreviation: 'SO',
        scoreValue: 0,
    },
    WaterPoloGoal,
    WaterPoloAssist,
    WaterPoloSteal,
    WaterPoloSave,
    WaterPoloBlock,
    WaterPoloShot,
    WaterPoloMajorFoul,
    WaterPoloEjectionDrawn,
    WaterPoloPenaltyDrawn,
    WaterPoloOffensiveFoul,
    WaterPoloSteal,
    {
        databaseValue: 'TURNOVER',
        displayName: 'Turnover',
        abbreviation: 'T',
        scoreValue: 0,
    },
    {
        databaseValue: 'possessionWon',
        displayName: 'Possession Won',
        abbreviation: 'PW',
        scoreValue: 0,
    },
    {
        databaseValue: 'possessionLost',
        displayName: 'Possession Lost',
        abbreviation: 'PL',
        scoreValue: 0,
    },
    WaterPoloTimeout,
    {
        databaseValue: 'SHOT_CLOCK',
        displayName: 'Shot Clock',
        abbreviation: 'SC',
        scoreValue: 0,
    },
    WaterPoloPlayerMisconduct,
    WaterPoloTeamMisconduct,
    WaterPoloGameRemark,
    WaterPoloSprintWon,
    WaterPoloFlagrant,
];

export const WaterPoloExclusionSubtype = {
    databaseValue: 'EXCLUSION',
    displayName: 'Exclusion',
    abbreviation: 'E',
    scoreValue: 0,
};

export const WaterPoloPenaltySubtype = {
    databaseValue: 'PENALTY',
    displayName: 'Penalty',
    abbreviation: 'P',
    scoreValue: 0,
};

export const WaterPoloFullTimeoutSubtype = {
    databaseValue: 'FULL',
    displayName: 'Full Timeout',
    abbreviation: 'TO',
    scoreValue: 0,
};

export const WaterPolo30SecTimeoutSubtype = {
    databaseValue: '30_SEC',
    displayName: 'TO 30 sec.',
    abbreviation: '30',
    scoreValue: 0,
};

export const WaterPoloMinorMisconductSubtype = {
    databaseValue: 'minorMisconduct',
    displayName: 'Minor Misconduct',
    abbreviation: 'MiM',
    scoreValue: 0,
};

export const WaterPoloMajorMisconductSubtype = {
    databaseValue: 'majorMisconduct',
    displayName: 'Major Misconduct',
    abbreviation: 'MajM',
    scoreValue: 0,
};

export const WaterPoloBrutalitySubtype = {
    databaseValue: 'brutality',
    displayName: 'Brutality',
    abbreviation: 'Br',
    scoreValue: 0,
};

export const WaterPoloYellowCard = {
    databaseValue: 'yellowCard',
    displayName: 'Yellow Card',
    abbreviation: 'YC',
    scoreValue: 0,
};

export const WaterPoloRedCard = {
    databaseValue: 'redCard',
    displayName: 'Red Card',
    abbreviation: 'R',
    scoreValue: 0,
};

export const WaterPoloYellowRedCard = {
    databaseValue: 'yellowRedCard',
    displayName: 'Yellow/Red Card',
    abbreviation: 'YR',
    scoreValue: 0,
};

export const WaterPoloSubTypes: StatType[] = [
    WaterPoloPenaltySubtype,
    WaterPoloExclusionSubtype,
    WaterPoloMinorMisconductSubtype,
    WaterPoloMajorMisconductSubtype,
    WaterPoloYellowCard,
    WaterPoloRedCard,
    WaterPoloYellowRedCard,
    WaterPoloBrutalitySubtype,
    {
        databaseValue: 'yellowCardBench',
        displayName: 'Yellow Card Bench',
        abbreviation: 'YB',
        scoreValue: 0,
    },
    {
        databaseValue: 'yellowCardCoach',
        displayName: 'Yellow Card Coach',
        abbreviation: 'YC',
        scoreValue: 0,
    },
    {
        databaseValue: 'redCardCoach',
        displayName: 'Red Card Coach',
        abbreviation: 'RC',
        scoreValue: 0,
    },
    WaterPoloFullTimeoutSubtype,
    WaterPolo30SecTimeoutSubtype,
];
