import { Col, ListGroup, Row } from "react-bootstrap";
import { CompetitionPickerListView } from "./CompetitionPickerViews";
import { Competition } from "../../../models/interfaces/Competition";
import ListView from "../../../components/containers/ListView";
import StyledListGroupItem from "../../../components/containers/StyledListGroupItem";
import { useHistory } from "react-router-dom";
import { parseTeamColor } from "../../../helpers/parseTeamColor";
import TeamRecordView from "../../competitions/modules/TeamRecordView";

interface Props {
    competitions: Competition[];
    selectedCompetition: Competition;
    onSelectCompetition: (Competition) => void;
}

export function EventStandingsView({ competitions, selectedCompetition, onSelectCompetition }: Props) {
    const history = useHistory();
    return (
        <div style={{width: '100%', paddingTop: '10px'}}>
            <Row noGutters className="matchContainer" style={{gap: '10px', padding: '20px'}}>
            <Col xs={4}>
                <CompetitionPickerListView
                    competitions={competitions}
                    selectedCompetition={selectedCompetition}
                    onCompetitionSelect={onSelectCompetition}
                />
            </Col>
            <Col>
                <ListView
                    key={selectedCompetition.id}
                    listProvider={selectedCompetition.standingsProvider}
                    isFlush={true}
                    listItemTranslator={(teamRecord) => {
                        return (
                            <StyledListGroupItem
                                key={teamRecord.team.id}
                                action
                                onClick={() => {
                                    history.push(
                                        `/competitions/${selectedCompetition.id}/entries/${teamRecord.team.id}`
                                    );
                                }}
                                color={parseTeamColor(teamRecord.team.color)}
                            >
                                <TeamRecordView teamRecord={teamRecord} />
                            </StyledListGroupItem>
                        );
                    }}
                />
            </Col>
            </Row>
        </div>
    );
}