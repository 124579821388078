import { TeamAttributes, TeamColor } from './TeamAttributes';
import { User, Name, PublicUser } from './User';
import { CapNumber, Position } from './RosterEntry';
import { ListProvider } from './ListProvider';
import { Resolver } from './Resolver';
import { CompetitionEntry } from './Competitions/CompetitionEntry';
import { AccessGroup } from './AccessGroup';
import { Player } from './Player';
import { Match } from './Match';
import { CompetitionEntryRequest } from './Competitions/CompetitionEntryRequest';
import {
    AttemptColumnFormat,
    MatchTableColumnDescription,
    PlayerTableColumnDescription,
    SimpleColumnFormat,
} from './ViewDescriptions/TableColumnDescription';
import { Competition } from './Competition';

export function isTeam(candidate): candidate is Team {
    return (candidate as Team).accessGroup !== undefined;
}

export interface Team {
    id: string;
    name: string;
    abbreviation: string;
    attributes: TeamAttributes;
    color: TeamColor;

    accessGroup: AccessGroup;

    playerProvider: ListProvider<Resolver<Player>>;
    competitionEntryProvider: ListProvider<Resolver<CompetitionEntry>>;

    matchStatTableColumnDescriptions: MatchTableColumnDescription[];
    resolvedMatchStatColumns: (
        columns: MatchTableColumnDescription[],
        matches: Match[]
    ) => Promise<{
        totalRow: string[];
        matchRows: (SimpleColumnFormat | AttemptColumnFormat)[][];
    }>;

    playerStatTableColumnDescriptions: PlayerTableColumnDescription[];
    resolvedPlayerStatColumns: (
        columns: PlayerTableColumnDescription[],
        players: Player[],
        matches: Match[]
    ) => Promise<(SimpleColumnFormat | AttemptColumnFormat)[][][]>; // Player, Matches, Columns

    adminForUser(user: User): Promise<TeamAdmin>;
}

export interface UpdatePlayerResponse {
    players: Player[];
    updatedCompetitionEntries: Promise<CompetitionEntry>[];
    skippedCompetitionEntries: Promise<CompetitionEntry>[];
}

export interface ClaimPlayerRequest {
    player: Player;
    backingUser: PublicUser;
    email: string;
}

export interface ClaimPlayerInvitation {
    id: string;
    player: Player;
    email: string;

    linkURL: string;
}

export function isClaimPlayerRequest(candidate): candidate is ClaimPlayerRequest {
    return (
        (candidate as ClaimPlayerRequest).player !== undefined &&
        (candidate as ClaimPlayerRequest).email !== undefined
    );
}

export function isClaimPlayerInvitation(candidate): candidate is ClaimPlayerInvitation {
    return (
        (candidate as ClaimPlayerInvitation).player !== undefined &&
        ((candidate as ClaimPlayerInvitation).email !== undefined &&
            (candidate as ClaimPlayerInvitation).linkURL) !== undefined
    );
}

export interface TeamAdmin {
    team: Team;
    user: User;

    pendingRequestProvider: ListProvider<Resolver<CompetitionEntryRequest>>;
    claimPlayerRequestProvider: ListProvider<Resolver<ClaimPlayerRequest>>;
    claimPlayerInvitationProvider: ListProvider<Resolver<ClaimPlayerInvitation>>;

    deactivatedPlayerProvider: ListProvider<Resolver<Player>>;

    acceptClaimRequest: (request: ClaimPlayerRequest) => Promise<Player>;
    sendClaimInvitation: (player: Player, email: string) => Promise<ClaimPlayerInvitation>;

    addPlayer: (
        name: Name,
        capNumber: CapNumber,
        position: Position,
        backingUser?: PublicUser
    ) => Promise<UpdatePlayerResponse>;

    addPlayers: (
        players: {
            name: Name;
            capNumber: CapNumber;
            position: Position;
            backingUser?: PublicUser;
        }[]
    ) => Promise<UpdatePlayerResponse>;

    reactivatePlayers: (players: Player[]) => Promise<void>;
    updatePlayer: (
        player: Player,
        name: Name,
        capNumber: CapNumber,
        position: Position,
        backingUser?: PublicUser
    ) => Promise<UpdatePlayerResponse>;

    removePlayer: (player: Player) => Promise<void>;
    removePlayers: (players: Player[]) => Promise<void>;
}
