import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ListGroup, Row, Col } from 'react-bootstrap';
import { isPlayer } from '../../models/interfaces/Player';
import PlayerView from './PlayerView';
import PlayerListButtons from './modules/PlayerListButtons';
import { isRosterEntry, PlayerMetadata } from '../../models/interfaces/RosterEntry';

interface Props<EntryType> {
    player: EntryType;
    handleSelectPlayer?: (player: EntryType) => void;
    handleDeletePlayer?: (player: EntryType, toggleState: () => void) => void;
    admin?: boolean;
}

function PlayerList<EntryType extends PlayerMetadata>({
    player,
    handleSelectPlayer,
    handleDeletePlayer,
    admin,
}: Props<EntryType>) {
    const history = useHistory();

    const handleClick = useCallback(() => {
        if (!handleSelectPlayer) {
            // if player go to players page displaying stats from all competitions
            if (isPlayer(player)) {
                history.push(`/players/${player.id}`);
            } else if (isRosterEntry(player) && player.backingPlayer) {
                history.push(`/players/${player.backingPlayer.id}`);
            }
        }
    }, [history, handleSelectPlayer, player]);

    return (
        <ListGroup.Item className="playerView" as={!handleSelectPlayer ? 'button' : 'div'} action onClick={handleClick}>
            <Row className="justify-content-between">
                <Col xs="auto">
                    <PlayerView player={player} />
                </Col>

                { player.isArchived ? 
                    <Col className="text-right">
                        <i><small>Archived</small></i>
                    </Col> 
                    : (!!handleSelectPlayer && !player.isArchived && (
                        <Col xs="auto">
                            <PlayerListButtons
                                player={player}
                                handleSelectPlayer={handleSelectPlayer}
                                admin={admin}
                                handleDeletePlayer={handleDeletePlayer}
                            />
                        </Col>)
                )}
            </Row>
        </ListGroup.Item>
    );
}

export default memo(PlayerList);
