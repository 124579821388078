import axios from "axios";
import { EventAdmin } from "../interfaces/Event";
import { RevSportDivision, RevSportEvent, RevSportIntegration, RevSportMatch, RevSportMatchRosters, RevSportPlayerData, RevSportRound } from "../interfaces/RevSportIntegration";
import { CapNumber, Name, RosterEntry } from "../interfaces/RosterEntry";
import { SportProvider } from "../interfaces/SportProvider";
import { RevSportAccount, User, WaterPoloAustraliaAdmin } from "../interfaces/User";
import { Venue } from "../interfaces/Venue";
import { CompetitionEntryAdmin, isCompetitionEntry } from "../interfaces/Competitions/CompetitionEntry";


export class WaterPoloAustraliaAdminSupport implements WaterPoloAustraliaAdmin {
    user: User
    sportProvider: SportProvider
    constructor(user: User, sportProvider: SportProvider) {
        this.user = user
        this.sportProvider = sportProvider
    }

    fetchRevSportAccounts(): Promise<RevSportAccount[]> {
        return this.user.fetchVerificationToken().then(userToken => {
            const body = {
                userToken
            };
            var apiPath = '/api/wpa/revsport/fetchAccounts';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const responseData = response.data
                const arrayData: any[] = responseData
                if (Array.isArray(arrayData)) {
                    return arrayData.reduce((currentArray, currentValue) => {
                        const id = currentValue['id']
                        const name = currentValue['name']
                        if (typeof id == 'string' && typeof name == 'string') {
                            const revSportAccount: RevSportAccount = {
                                id,
                                name
                            }
                            const updatedArray = currentArray
                            updatedArray.push(revSportAccount)
                            return updatedArray
                        } else {
                            return currentArray
                        }
                    }, new Array<RevSportAccount>())
                } else {
                    return []
                }
            })
        })
    }

    // addRevSportAccount(name: string, apiKey: string, secretKey: string): Promise<RevSportAccount> {
    //     return this.user.fetchVerificationToken().then(userToken => {
    //         const body = {
    //             userToken,
    //             accountName: name,
    //             apiKey,
    //             secretKey
    //         };
    //         var apiPath = '/api/wpa/revsport/addAccount';
    //         if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
    //             apiPath = 'http://localhost:3000' + apiPath;
    //         }       
    //         return axios.post(apiPath, body).then((response) => {
    //             const responseData = response.data
    //             return {
    //                 id: responseData['id'],
    //                 name: responseData['name']
    //             }
    //         })
    //     })
    // }

    fetchRevSportEvents(account: RevSportAccount): Promise<RevSportEvent[]> {
        return this.user.fetchVerificationToken().then(userToken => {
            const body = {
                userToken,
                revSportAccountID: account.id
            };
            var apiPath = '/api/wpa/revsport/fetchEvents';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const responseData = response.data
                const revSportEventKeys = Object.keys(responseData)
                const revSportEvents = revSportEventKeys.map(revSportID => {
                    const name = (responseData[revSportID] ?? {})["Name"];
                    const accountSource: UserAccountSource = {
                        sourceName: "USER",
                        user: this.user,
                        revSportAccount: account
                    }
                    return new RevSportEventImplementation(revSportID, name, accountSource, this.sportProvider)
                })
                return Promise.resolve(revSportEvents)
            })
        })
    }
}

export class RevSportIntegrationSupport implements RevSportIntegration {
    constructor(eventAdmin: EventAdmin, sportProvider: SportProvider) {
        this.eventAdmin = eventAdmin
        this.sportProvider = sportProvider
    }

    eventAdmin: EventAdmin
    sportProvider: SportProvider

    getLinkedEvent: () => Promise<RevSportEventImplementation | undefined> = () => {
        const body = {
            cbWaterPoloEventID: this.eventAdmin.event.id
        };
        var apiPath = '/api/wpa/revsport/getLinkedRevSportEvent';
        if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
            apiPath = 'http://localhost:3000' + apiPath;
        }
        return axios.post(apiPath, body).then((response) => {
            const linkedEventData = response.data
            var revSportID = linkedEventData['revSportID']
            if (typeof revSportID === 'number') {
                revSportID = revSportID.toString()
            }

            if (typeof revSportID !== 'string') {
                return Promise.reject('Failed to get revSportID')
            }

            const revSportAccount = linkedEventData['revSportAccount'] || {}
            const revSportAccountID = revSportAccount['id']
            const revSportAccountName = revSportAccount['name']

            if (typeof revSportAccountID !== 'string') {
                return Promise.reject('Failed to get RevSport account id')
            }

            if (typeof revSportAccountName !== 'string') {
                return Promise.reject('Failed to get RevSport account name')
            }

            const accountSource: EventAccountSource = {
                sourceName: 'EVENT',
                eventAdmin: this.eventAdmin,
                revSportAccount: {
                    id: revSportAccountID,
                    name: revSportAccountName
                }

            }

            return Promise.resolve(
                new RevSportEventImplementation(
                    revSportID,
                    this.eventAdmin.event.attributes.name,
                    accountSource,
                    this.sportProvider)
            )
        }).catch(error => {
            console.log(error)
            if (error.response.status == 404) {
                return Promise.resolve(undefined)
            } else {
                return Promise.reject(error)
            }
        });
    }

    updateLinkedEvent: (linkedEvent: RevSportEvent | undefined) => Promise<void> = (linkedEvent) => {
        return this.eventAdmin.user.fetchVerificationToken()
            .then((userToken) => {
                var apiPath = '/api/wpa/revsport/linkEvent';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                const body = {
                    userToken,
                    cbWaterPoloEventID: this.eventAdmin.event.id,
                    revSportID: linkedEvent ? linkedEvent.revSportID : null,
                    revSportAccountID: linkedEvent ? linkedEvent.account.id : null,
                }

                return axios.post(apiPath, body).then((response) => {
                    if (response.status === 200) {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(`Link Event: Unexpected Status: ${response.status}`)
                    }
                })
            })
    }

    uploadMatchResult: (cbWaterPoloMatchKey: string) => Promise<void> = (cbWaterPoloMatchKey) => {
        return this.eventAdmin.user.fetchVerificationToken()
            .then((userToken) => {
                var apiPath = '/api/wpa/revsport/uploadMatch';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                const body = {
                    userToken,
                    cbWaterPoloMatchKey
                }

                return axios.post(apiPath, body).then((response) => {
                    if (response.status === 200) {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(`Upload Match Result: Unexpected Status: ${response.status}`)
                    }
                })
            })
    }

    syncMatchRosters(cbWaterPoloMatchKey: string) {
        return this.getLinkedEvent().then(linkedEvent => {
            if (linkedEvent) {
                return linkedEvent.syncMatchRosters(cbWaterPoloMatchKey)
            } else {
                Promise.reject('Missing linked event')
            }
        })
    }
}

interface UserAccountSource {
    sourceName: 'USER'
    user: User,
    revSportAccount: RevSportAccount
}

interface EventAccountSource {
    sourceName: 'EVENT'
    eventAdmin: EventAdmin,
    revSportAccount: RevSportAccount
}

type AccountSource = UserAccountSource | EventAccountSource

class RevSportEventImplementation implements RevSportEvent {
    revSportID: string;
    name: string;
    account: RevSportAccount;
    accountSource: AccountSource;
    sportProvider: SportProvider


    constructor(revSportID: string, name: string, accountSource: AccountSource, sportProvider: SportProvider) {
        this.revSportID = revSportID
        this.name = name
        this.accountSource = accountSource
        this.sportProvider = sportProvider
        this.account = accountSource.revSportAccount
    }

    getAccountParameters(): Promise<any> {
        switch (this.accountSource.sourceName) {
            case "USER":
                return this.accountSource.user.fetchVerificationToken().then(userToken => {
                    return {
                        source: this.accountSource.sourceName,
                        accountID: this.accountSource.revSportAccount.id,
                        userToken,
                    }
                })
            case "EVENT":
                const eventAdmin = this.accountSource.eventAdmin
                return eventAdmin.user.fetchVerificationToken().then(userToken => {
                    return {
                        source: this.accountSource.sourceName,
                        accountID: this.accountSource.revSportAccount.id,
                        userToken,
                        eventID: eventAdmin.event.id,
                    }
                })
        }
    }

    getDivisions(): Promise<RevSportDivision[]> {
        var apiPath = '/api/wpa/revsport/fetchDivisions';
        if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
            apiPath = 'http://localhost:3000' + apiPath;
        }
        const eventKey = this.revSportID

        return this.getAccountParameters().then(revSportAccountSource => {
            const body = {
                revSportAccountSource,
                eventKey
            }
            return axios.post(apiPath, body).then((response) => {
                const responseData = response.data
                const revSportDivisionIDs = Object.keys(responseData)
                const revSportDivisions = revSportDivisionIDs.map(revSportID => {
                    const name = (responseData[revSportID] ?? {})["Name"];
                    const revSportDivision: RevSportDivision = {
                        revSportID,
                        name,
                        getRounds: () => { return this.getRevSportRounds(eventKey, revSportID) }
                    }
                    return revSportDivision
                })
                return Promise.resolve(revSportDivisions)
            })
        })
    }

    getRevSportRounds: (eventKey: string, divisionKey: string) => Promise<RevSportRound[]> = (eventKey: string, divisionKey: string) => {
        return this.getAccountParameters().then(revSportAccountSource => {
            var apiPath = '/api/wpa/revsport/fetchRounds';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            const body = {
                eventKey,
                divisionKey,
                revSportAccountSource,
            }
            return axios.post(apiPath, body).then((response) => {
                const responseData = response.data
                const revSportRoundIDs = Object.keys(responseData)
                const revSportRounds = revSportRoundIDs.map(revSportID => {
                    const dateString = (responseData[revSportID] ?? {})["Round date"];
                    const date = new Date(dateString)
                    const round: RevSportRound = {
                        revSportID,
                        date,
                        getMatches: () => { return this.getRevSportMatches(eventKey, divisionKey, revSportID) }
                    }
                    return round
                })
                return Promise.resolve(revSportRounds)
            })
        })
    }


    getRevSportMatches: (eventKey: string, divisionKey: string, roundKey: string) => Promise<RevSportMatch[]> = (eventKey: string, divisionKey: string, roundKey: string) => {
        return this.getAccountParameters().then(revSportAccountSource => {
            var apiPath = '/api/wpa/revsport/fetchGames';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            const body = {
                eventKey,
                divisionKey,
                roundKey,
                revSportAccountSource
            }
            return axios.post(apiPath, body).then((response) => {
                const responseData = response.data
                const revSportGameIDs = Object.keys(responseData)
                const revSportGames: RevSportMatch[] = revSportGameIDs.map(revSportID => {
                    const gameData = responseData[revSportID] ?? {}
                    const team1Data = gameData["Team 1"] ?? {}
                    const team1ID = team1Data['ID'] ?? '-1'
                    const team1Name = team1Data['Name'] ?? "TBD"
                    const team1ScoreValue = team1Data['Score']
                    const team2Data = gameData["Team 2"] ?? {}
                    const team2ID = team2Data['ID'] ?? '-1'
                    const team2Name = team2Data['Name'] ?? "TBD"
                    const team2ScoreValue = team2Data['Score']

                    const dateString = gameData["Game date"];
                    const time = gameData["Time"];

                    var [hours, minutes] = time.split(":").map(Number);
                    if (hours == undefined) {
                        hours = 0
                    }
                    if (minutes == undefined) {
                        minutes = 0
                    }

                    var venue: Venue | undefined = undefined
                    const venueName = gameData['Venue']
                    if (typeof venueName === 'string') {
                        venue = {
                            name: venueName
                        }
                        const subvenueName = gameData['Subvenue']
                        if (typeof subvenueName === 'string' && subvenueName) {
                            venue.subvenueName = subvenueName
                        }
                    }
                    
                    const date = new Date(`${dateString}T${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00+10:00`);

                    // convert team1ScoreValue and team2ScoreValue to numbers from strings or numbers
                    const team1Score = typeof team1ScoreValue === 'number' ? team1ScoreValue : (team1ScoreValue !== undefined ? parseInt(team1ScoreValue) : undefined);
                    const team2Score = typeof team2ScoreValue === 'number' ? team2ScoreValue : (team2ScoreValue !== undefined ? parseInt(team2ScoreValue) : undefined);
                    const result = typeof team1Score === 'number' && !isNaN(team1Score) && typeof team2Score === 'number' && !isNaN(team1Score) ? { team1Score, team2Score } : undefined

                    const game: RevSportMatch = {
                        revSportID,
                        team1: {
                            revSportID: team1ID,
                            name: team1Name,
                        },
                        team2: {
                            revSportID: team2ID,
                            name: team2Name
                        },
                        date,
                        venue,
                        result,
                        getRosters: () => { return this.getRevSportRosters(revSportID) }
                    }
                    return game
                })
                const sortedGames = revSportGames.sort((a, b) => a.date.getTime() - b.date.getTime());
                return Promise.resolve(sortedGames)
            })
        })
    }

    getRevSportRosters: (revSportGameID: string) => Promise<RevSportMatchRosters> = (revSportGameID: string) => {
        return this.getAccountParameters().then(revSportAccountSource => {
            var apiPath = '/api/wpa/revsport/fetchGame';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            const body = {
                gameKey: revSportGameID,
                revSportAccountSource
            }

            const sportProvider = this.sportProvider

            return axios.post(apiPath, body).then((response) => {
                function parseName(nameString: any): Name {
                    if (typeof nameString !== 'string') {
                        return {
                            firstName: "",
                            lastName: "(No Name Provided)"
                        }
                    }
                    const words = nameString.split(" ");
                    const firstName = words[0];
                    const lastName = words.slice(1).join(" ");
                    return { firstName, lastName };
                }

                function convertRosterData(rosterData) {
                    const revSportPlayerIDs = Object.keys(rosterData)
                    return revSportPlayerIDs.reduce((currentPlayers, playerID) => {
                        const playerData = rosterData[playerID] ?? {}
                        const playerName = playerData["Name"]
                        const name = parseName(playerName)

                        const numberValue = playerData["Number"]
                        const capNumber = sportProvider.playerCapNumbers.find(capNumber => {
                            return capNumber.displayName === numberValue
                        }) ?? sportProvider.playerCapNumbers[0]

                        const playerRosterData: RevSportPlayerData = {
                            revSportID: playerID,
                            name,
                            capNumber
                        }

                        var updatedPlayers = currentPlayers
                        updatedPlayers.push(playerRosterData)
                        return updatedPlayers
                    }, new Array<RevSportPlayerData>())
                }

                const responseData = response.data
                const team1Data = responseData["Team 1"] ?? {}
                const team1ID = team1Data['ID'] ?? '-1'
                const team1Name = team1Data['Name'] ?? "TBD"

                const team2Data = responseData["Team 2"] ?? {}
                const team2ID = team2Data['ID'] ?? '-1'
                const team2Name = team2Data['Name'] ?? "TBD"

                const team1RosterData = responseData["Team 1 list"] || {} 
                const team2RosterData = responseData["Team 2 list"] || {}

                const team1Players = convertRosterData(team1RosterData)
                const team2Players = convertRosterData(team2RosterData)
                return {
                    team1: {
                        revSportID: team1ID,
                        name: team1Name,
                    },
                    team2: {
                        revSportID: team2ID,
                        name: team2Name
                    },
                    team1Players,
                    team2Players
                }
            })
        })
    }

    syncMatchRosters(cbWaterPoloMatchKey: string) {
        return this.sportProvider.matchResolver(cbWaterPoloMatchKey).asAPromise().then(cbWaterPoloMatch => {
            const revSportMatchID = cbWaterPoloMatch.externalID
            if (!revSportMatchID) {
                return Promise.reject('Missing RevSportID')
            }
            return Promise.resolve().then(() => {
                switch (this.accountSource.sourceName) {
                    case "USER":
                        return cbWaterPoloMatch.adminForUser(this.accountSource.user)
                    case "EVENT":
                        return cbWaterPoloMatch.adminForUser(this.accountSource.eventAdmin.user)
                }
            }).then(matchAdmin => {
                return {
                    matchAdmin,
                    revSportMatchID
                }
            })
        })
        .then(({ matchAdmin, revSportMatchID }) => {
            return this.getRevSportRosters(revSportMatchID).then(revSportRosters => {
                return {
                    matchAdmin, revSportRosters
                }
            })
        }).then(({ matchAdmin, revSportRosters }) => {
            const team1ID = revSportRosters.team1.revSportID
            const team2ID = revSportRosters.team2.revSportID

            const lightCapTeam = matchAdmin.match.lightCapTeam
            const darkCapTeam = matchAdmin.match.darkCapTeam

            const cbWaterPoloTeam1 = lightCapTeam.externalID == team1ID ? lightCapTeam : (darkCapTeam.externalID == team1ID ? darkCapTeam : undefined)
            const cbWaterPoloTeam2  = lightCapTeam.externalID == team2ID ? lightCapTeam : (darkCapTeam.externalID == team2ID ? darkCapTeam : undefined)

            if (!isCompetitionEntry(cbWaterPoloTeam1) || !isCompetitionEntry(cbWaterPoloTeam2)) {
                return Promise.reject('missing revsport teams')
            }

            return cbWaterPoloTeam1.adminForUser(matchAdmin.user).then(team1Admin => {
                return cbWaterPoloTeam2.adminForUser(matchAdmin.user).then(team2Admin  => {
                    return {
                        revSportRosters,
                        team1Admin,
                        team2Admin
                    }
                })
            })
        }).then(({ revSportRosters, team1Admin, team2Admin }) => {
            function getPromisesForTeam(teamAdmin: CompetitionEntryAdmin, revSportPlayers, sportProvider): Promise<void> {
                return teamAdmin.competitionEntry.rosterProvider.once().then(teamCBWaterPoloRoster => {
                    console.log(`--Starting sync for ${teamAdmin.competitionEntry.name}`)
                    return Promise.all(teamCBWaterPoloRoster.map(resolver => {
                        return resolver.asAPromise()
                    }))
                }).then(teamCBWaterPoloRoster => {
                    var playerMap = new Map<string, RosterEntry>()
                    teamCBWaterPoloRoster.forEach(rosterEntry => {
                        playerMap.set(rosterEntry.id, rosterEntry)
                    })
            
                    const claimedNumbers = new Set()
            
                    const updatePromises = revSportPlayers.map(revSportPlayer => {
                        claimedNumbers.add(revSportPlayer.capNumber.databaseValue)
                        const existingPlayer = teamCBWaterPoloRoster.find(cbWaterPoloPlayer => {
                            return cbWaterPoloPlayer.name.firstName == revSportPlayer.name.firstName 
                                && cbWaterPoloPlayer.name.lastName == revSportPlayer.name.lastName
                        })
                        if (existingPlayer) {
                            playerMap.delete(existingPlayer.id)
                            if (existingPlayer.externalID != revSportPlayer.revSportID 
                                || existingPlayer.capNumber.databaseValue != revSportPlayer.capNumber.databaseValue) {
                                console.log(`Update for player: ${existingPlayer.name.firstName} ${existingPlayer.name.lastName}`)
                                console.log(`Incoming cap number: ${revSportPlayer.capNumber.databaseValue}`)
                                console.log(`Existing cap number: ${existingPlayer.capNumber.databaseValue}`)
                                console.log(`Incoming RevSportID: ${revSportPlayer.revSportID}`)
                                console.log(`Existing RevSportID: ${existingPlayer.externalID}`)
                                return teamAdmin.updateRosterEntry(
                                    existingPlayer, 
                                    existingPlayer.name, 
                                    revSportPlayer.capNumber, 
                                    existingPlayer.position,
                                    revSportPlayer.revSportID)
                            } else {
                                // no update needed
                                console.log(`Skipping update for player: ${existingPlayer.name.firstName} ${existingPlayer.name.lastName}`)
                                return Promise.resolve()
                            }
                        } else {
                            console.log(`Adding player: ${revSportPlayer.firstName} ${revSportPlayer.lastName}`)
                            return teamAdmin.addRosterEntry(revSportPlayer.name, revSportPlayer.capNumber, sportProvider.playerPositions[0], revSportPlayer.revSportID)
                        }
                    })
            
                    return Promise.all(updatePromises).then(() => {
                        console.log('--handling untouched entries')
                        var currentCapNumberIndex = 25
                        const possibleCapNumbers = sportProvider.playerCapNumbers
                        return Promise.all(
                            Array.from(playerMap.values()).map(untouchedRosterEntry => {
                                console.log(`Handling non revsport player: ${untouchedRosterEntry.capNumber.displayName} ${untouchedRosterEntry.name.firstName} ${untouchedRosterEntry.name.lastName}`)
                                if (typeof untouchedRosterEntry.externalID === 'undefined') {
                                    console.log('Skipping clearing RevsportID')
                                    return Promise.resolve()
                                }
                                var capNumber: CapNumber | undefined = undefined 
                                while (currentCapNumberIndex < possibleCapNumbers.length) {
                                    const proposedCapNumber = possibleCapNumbers[currentCapNumberIndex]
                                    if (!claimedNumbers.has(proposedCapNumber.databaseValue)) {
                                        claimedNumbers.add(proposedCapNumber.databaseValue)
                                        capNumber = proposedCapNumber
                                        break
                                    } else {
                                        currentCapNumberIndex += 1
                                    }
                                }
                
                                if (!capNumber) {
                                    capNumber = untouchedRosterEntry.capNumber
                                }
                                console.log(`Clearing RevSportID`)
                                return teamAdmin.updateRosterEntry(
                                    untouchedRosterEntry, 
                                    untouchedRosterEntry.name, 
                                    capNumber,
                                    untouchedRosterEntry.position,
                                    undefined
                                )
                            })
                        ).then(() => {
                            return Promise.resolve()
                        })
                    })
                })
            }

            const team1Promises = getPromisesForTeam(team1Admin, revSportRosters.team1Players, this.sportProvider)
            const team2Promises = getPromisesForTeam(team2Admin, revSportRosters.team2Players, this.sportProvider)
    
            return team1Promises.then(() => {
                return team2Promises
            })
        })
    }
}