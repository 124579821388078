import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Team } from '../../models/interfaces/Team';

interface Props {
    name: string;
    subtitle ?: string;
    accessoryView?: ReactElement;
}

export default function TeamView({ name, subtitle, accessoryView }: Props) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h4>{name}</h4>
                { subtitle && <h5>{subtitle}</h5>}
            </div>
            { accessoryView }
        </div>
    );
}
