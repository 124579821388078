import React, { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { PlayerMetadata } from '../../../models/interfaces/RosterEntry';

interface Props<EntryType> {
    player: EntryType;
    handleSelectPlayer: (player: EntryType) => void;
    handleDeletePlayer?: (player: EntryType, toggleState: () => void) => void;
    admin?: boolean;
}
const PlayerListButtons = <EntryType extends PlayerMetadata>({
    player,
    handleSelectPlayer,
    handleDeletePlayer,
    admin,
}: Props<EntryType>) => {
    const [deleting, setDeleting] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const selectPlayer = () => {
        handleSelectPlayer(player);
    };

    const toggleDelete = () => {
        setDeleting((state) => !state);
    };

    const handleDelete = () => {
        handleDeletePlayer && handleDeletePlayer(player, toggleDelete);
    };

    const promptDeleteConfirmation = () => {
        setConfirmDelete(true);
    };

    const cancelPromptDeleteConfirmation = () => {
        setConfirmDelete(false);
    };

    if (confirmDelete)
        return (
            <ButtonGroup size="sm">
                <Button
                    onClick={cancelPromptDeleteConfirmation}
                    variant="primary"
                    style={{ boxShadow: 'none' }}
                >
                    Cancel
                </Button>
                <Button onClick={handleDelete} variant="danger" style={{ boxShadow: 'none' }}>
                    {deleting ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        'Confirm Remove'
                    )}
                </Button>
            </ButtonGroup>
        );

    return (
        <ButtonGroup size="sm">
            <Button onClick={selectPlayer} variant="outline-primary">
                {admin ? 'Edit' : 'Select'}
            </Button>
            {admin && (
                <Button onClick={promptDeleteConfirmation} variant="outline-danger">
                    Remove
                </Button>
            )}
        </ButtonGroup>
    );
};

export default PlayerListButtons;
