import { useState, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Player } from '../../../models/interfaces/Player';
import TeamRosterView from '../../teams/TeamRosterView';
import { RegistrationData } from '../../interfaces/EventUI';
import PlayerFormView from '../../teams/PlayerForm';
import { SportProvider } from '../../../models/interfaces/SportProvider';
import { CapNumber, Name, Position, RosterEntry } from '../../../models/interfaces/RosterEntry';

interface Props {
    eventRoster?: Player[];
    handleSelect: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
    sportProvider: SportProvider;
}

const EventRoster = ({ eventRoster, handleSelect, sportProvider }: Props) => {
    const [selectedPlayer, setSelectedPlayer] = useState<Player | RosterEntry | undefined>();
    const [addingPlayer, setAddingPlayer] = useState<boolean>(false);

    const handleRemoveAll = useCallback(() => {
        handleSelect('roster', undefined);
    }, [handleSelect]);

    const handleRemovePlayer = useCallback(
        (player: Player | RosterEntry, toggleState: () => void) => {
            toggleState();
            const rosterCopy = eventRoster?.filter((eventPlayer) => eventPlayer.id !== player.id);
            handleSelect('roster', rosterCopy);
            toggleState();
        },
        [eventRoster, handleSelect]
    );

    const removeAllButton = useMemo(() => {
        return eventRoster ? (
            <Button onClick={handleRemoveAll}>Remove All Players</Button>
        ) : undefined;
    }, [eventRoster, handleRemoveAll]);

    const removeAllButtonObject = useMemo(() => {
        return removeAllButton
            ? { buttonElement: removeAllButton, position: 'top' as 'top' }
            : undefined;
    }, [removeAllButton]);

    const sortedRoster = useMemo(() => {
        if (!eventRoster) return [];
        return [...new Set(eventRoster)].sort((a, b) => {
            if (a.capNumber.sortValue < b.capNumber.sortValue) return -1;
            if (a.capNumber.sortValue > b.capNumber.sortValue) return 1;
            return 1;
        });
    }, [eventRoster]);

    const handleSelectPlayer = useCallback((player: Player | RosterEntry) => {
        setSelectedPlayer(player);
    }, []);

    const handleSubmit = useCallback(
        (
            name: Name,
            capNumber: CapNumber,
            position: Position,
            resetValues: () => void,
            handleCancel: () => void
        ) => {
            setAddingPlayer(true);
            const updatedRoster = eventRoster?.map((player) => {
                if (player.id !== selectedPlayer?.id) return player;
                const copyPlayer = Object.assign({}, player);
                copyPlayer.capNumber = capNumber;
                copyPlayer.position = position;
                return copyPlayer;
            });
            handleSelect('roster', updatedRoster);
            resetValues();
            setAddingPlayer(false);
            handleCancel();
        },
        [eventRoster, handleSelect, selectedPlayer?.id]
    );

    const resetEditPlayer = useCallback(() => {
        setSelectedPlayer(undefined);
    }, []);

    if (selectedPlayer)
        return (
            <PlayerFormView
                sportProvider={sportProvider}
                editPlayer={selectedPlayer}
                handleSubmit={handleSubmit}
                resetEditPlayer={resetEditPlayer}
                lockNamePolicy={{
                    locked: true,
                    message:
                        'Updating the name is not supported from this page. Please update your roster from your team page and try again.',
                }}
                addingPlayer={addingPlayer}
            />
        );

    return (
        <TeamRosterView
            teamPlayers={sortedRoster}
            cardHeader="Event Roster"
            listButton={removeAllButtonObject}
            handleSelectPlayer={handleSelectPlayer}
            admin={true}
            handleDeletePlayer={handleRemovePlayer}
        />
    );
};

export default EventRoster;
