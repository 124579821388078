import { SportProvider } from '../../interfaces/SportProvider';
import { AccessGroup } from '../../interfaces/AccessGroup';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import firebase from 'firebase';

export class FirebaseAccessGroupTranslator implements FirebaseItemTranslator<AccessGroup> {
    id: string;
    database: firebase.database.Database;
    private sportProvider: SportProvider;

    constructor(id: string, sportProvider: SportProvider, database: firebase.database.Database) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
    }
    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (AccessGroup) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val();
        const organizationID = val['organizationID'];
        const team = val['team'];
        if (typeof team === 'string' && typeof organizationID === 'string') {
            const accessGroup: AccessGroup = {
                id: this.id,
                teamID: team,
                organizationID,
            };
            onSuccess(accessGroup);
        } else {
            onFailure('missing team or organization id when resolving accessGroup');
        }
    }
}
