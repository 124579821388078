import React, { useState, useEffect, ReactElement } from 'react';
import { Spinner, Container } from 'react-bootstrap';
import { Resolver } from '../../models/interfaces/Resolver';

interface Props<T> {
    resolver: Resolver<T>;
    itemTranslator: (T) => ReactElement;
}

export default function ResolverView<T>({ resolver, itemTranslator }: Props<T>) {
    const [hasResolvedItem, setHasResolvedItem] = useState(resolver.hasResolved);
    const [resolvedItem, setResolvedItem] = useState(resolver.resolvedObject);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    useEffect(() => {
        const token = resolver.resolve(
            (updatedItem) => {
                setHasResolvedItem(true);
                setResolvedItem(updatedItem);
            },
            (resolverError) => {
                setHasResolvedItem(true);
                setErrorMessage(resolverError);
            }
        );
        return () => {
            resolver.cancel(token);
        };
    }, [resolver]);
    if (resolvedItem) {
        return itemTranslator(resolvedItem);
    } else if (errorMessage) {
        return <Container fluid>{errorMessage}</Container>;
    } else if (!hasResolvedItem) {
        return <Spinner key={resolver.id} animation="border" />;
    } else {
        return (
            <Container fluid key={resolver.id}>
                An unexpected resolver error occurred
            </Container>
        );
    }
}
