import { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Organization } from '../../../models/interfaces/Organization';
import { isTeam, Team } from '../../../models/interfaces/Team';
import { Player } from '../../../models/interfaces/Player';
import { RegistrationData } from '../../interfaces/EventUI';
import SelectedTeamRoster from './SelectedTeamRoster';
import EventRoster from './EventRoster';
import useListProviderToArray from '../../../helpers/useListProviderToArray';
import SelectTeamForRoster from './SelectTeamForRoster';
import { sortTeamBasedOnSelectedTeamAttributes } from '../../../helpers/teamSortFunction';
import { SportProvider } from '../../../models/interfaces/SportProvider';

interface Props {
    organization: Organization;
    handleSelect: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
    backingTeam: Team;
    eventRoster?: Player[];
    sportProvider: SportProvider;
}

const CompetitionEntryRosterDetails = ({
    organization,
    handleSelect,
    backingTeam,
    eventRoster,
    sportProvider,
}: Props) => {
    const [selectedOrganizationTeam, setSelectedOrganizationTeam] = useState<Team>(backingTeam);

    const memorizedTeamSort = useCallback(
        (a: Team, b: Team) => {
            return sortTeamBasedOnSelectedTeamAttributes(a, b, backingTeam);
        },
        [backingTeam]
    );

    const organizationTeams = useListProviderToArray({
        provider: organization.teamProvider,
        isType: isTeam,
        sortFn: memorizedTeamSort,
    });

    const handleTeamSelect = useCallback((team: Team) => {
        setSelectedOrganizationTeam(team);
    }, []);

    return (
        <Row className="mt-2">
            <Col md={5}>
                <EventRoster
                    eventRoster={eventRoster}
                    handleSelect={handleSelect}
                    sportProvider={sportProvider}
                />
            </Col>
            <Col>
                <SelectTeamForRoster
                    organization={organization}
                    organizationTeams={organizationTeams}
                    selectedTeam={selectedOrganizationTeam}
                    handleClick={handleTeamSelect}
                    sportProvider={sportProvider}
                />
            </Col>
            <Col md={4}>
                <SelectedTeamRoster
                    team={selectedOrganizationTeam}
                    handleSelect={handleSelect}
                    eventRoster={eventRoster}
                    sportProvider={sportProvider}
                />
            </Col>
        </Row>
    );
};

export default CompetitionEntryRosterDetails;
