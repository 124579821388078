import { useState } from 'react';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { TeamCategory, TeamDivision, TeamGender } from '../../models/interfaces/TeamAttributes';
import styled from 'styled-components';
import { Event } from '../../models/interfaces/Event';
import { CompetitionAttributes } from '../../models/interfaces/Competition';
import { SportProvider } from '../../models/interfaces/SportProvider';
import { Formik } from 'formik';
import * as yup from 'yup';

type Props = {
    parentEvent: Event;
    initialAttibutes?: CompetitionAttributes;
    sportProvider: SportProvider;
    onSubmitCompetitionAttributes: (CompetitionAttributes) => Promise<void>;
    onHide: () => void;
};

const ButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
`;

const schema = yup.object({
    competitionName: yup.string().required('Competition name is required'),
    gender: yup.object({}).required('Team Gender is required'),
    division: yup.object({}).required('Team Division is required'),
    category: yup.object({}).required('Team Category is required'),
});

interface CompetitionFormValues {
    competitionName: string | undefined;
    gender: TeamGender;
    category: TeamCategory;
    division: TeamDivision;
    isIndexed: boolean;
}

export default function CreateCompetition({
    parentEvent,
    initialAttibutes,
    sportProvider,
    onSubmitCompetitionAttributes,
    onHide,
}: Props) {
    const teamCategories = sportProvider.teamCategories;
    const teamGenders = sportProvider.teamGenders;
    const initialCategory =
        initialAttibutes?.category ?? parentEvent.attributes.category ?? teamCategories[0];

    const initialValues: CompetitionFormValues = {
        competitionName: initialAttibutes?.name,
        gender: initialAttibutes?.gender ?? teamGenders[0],
        category: initialCategory,
        division: initialAttibutes?.division ?? initialCategory.divisions[0],
        isIndexed: initialAttibutes ? initialAttibutes.isIndexed : false,
    };

    const [hasPendingAction, setHasPendingAction] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    return (
        <Row className="justify-content-center my-2">
            <Col sm={6} md={6} lg={6}>
                <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={({ competitionName, gender, category, division, isIndexed }) => {
                        if (competitionName && gender && category && division) {
                            const attributes: CompetitionAttributes = {
                                name: competitionName,
                                category: category,
                                division: division,
                                gender: gender,
                                isIndexed: isIndexed,
                                updateTeamRosterAutomatically: category.allowsRosterPropagation,
                            };
                            setHasPendingAction(true);
                            onSubmitCompetitionAttributes(attributes)
                                .catch((error) => {
                                    setErrorMessage('Competition update failed: ' + error);
                                })
                                .finally(() => {
                                    setHasPendingAction(false);
                                });
                        }
                    }}
                >
                    {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                        <Form
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="competitionName"
                                value={values.competitionName}
                                isValid={touched?.competitionName && !errors?.competitionName}
                                isInvalid={touched?.competitionName && !!errors?.competitionName}
                                onChange={handleChange}
                            />
                            {errors?.competitionName && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errors?.competitionName}
                                </div>
                            )}
                            <hr />
                            <Form.Check
                                name="isIndexed"
                                checked={values.isIndexed}
                                onChange={handleChange}
                                label="Visible on homepage"
                            />
                            <hr />
                            <Form.Group
                                className="text-center"
                                onChange={(event: any) => {
                                    const key = event.target.id;
                                    const updatedSelectedGender = teamGenders.find((teamGender) => {
                                        return teamGender.databaseValue === key;
                                    });
                                    if (updatedSelectedGender) {
                                        setFieldValue('gender', updatedSelectedGender);
                                    }
                                }}
                            >
                                {teamGenders.map((teamGender) => {
                                    return (
                                        <Form.Check
                                            required
                                            inline
                                            type="radio"
                                            id={teamGender.databaseValue}
                                            key={teamGender.databaseValue}
                                            name="gender"
                                            label={teamGender.name}
                                            checked={
                                                values.gender.databaseValue ===
                                                teamGender.databaseValue
                                            }
                                        />
                                    );
                                })}
                                {errors?.gender?.name && (
                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                        {errors?.gender?.name}
                                    </div>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Division</Form.Label>
                                <Form.Control
                                    custom
                                    as="select"
                                    isValid={touched?.division && !errors?.division}
                                    isInvalid={touched?.division && !!errors?.division}
                                    onChange={(event) => {
                                        const eventKey = event.target.value;
                                        const teamDivision = values.category.divisions.find(
                                            (teamDivision) => {
                                                return teamDivision.databaseValue === eventKey;
                                            }
                                        );
                                        if (teamDivision) {
                                            if (teamDivision !== values.division) {
                                                setFieldValue('division', teamDivision);
                                            }
                                        }
                                    }}
                                    data-cy="team-division-select"
                                    value={values.division.databaseValue}
                                >
                                    <option value="" disabled hidden>
                                        Select Team Division
                                    </option>
                                    {values.category.divisions.map((teamDivision) => {
                                        return (
                                            <option
                                                key={teamDivision.databaseValue}
                                                value={teamDivision.databaseValue}
                                            >
                                                {teamDivision.name}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                                {errors?.division?.name && (
                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                        {errors?.division?.name}
                                    </div>
                                )}
                            </Form.Group>
                            <ButtonBox>
                                {!hasPendingAction && (
                                    <Button variant="secondary" onClick={onHide}>
                                        Cancel
                                    </Button>
                                )}
                                {!hasPendingAction && (
                                    <Button type="submit" variant="primary">
                                        {initialAttibutes ? 'Update' : 'Create'}
                                    </Button>
                                )}
                                {hasPendingAction && (
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                )}
                            </ButtonBox>
                            {errorMessage && <span>{errorMessage}</span>}
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
}
