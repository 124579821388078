import { ListProvider } from './ListProvider';
import { Resolver } from './Resolver';
import { RosterEntry, CapNumber, Position } from './RosterEntry';
import { CompetitionEntryMetadata } from './Competitions/CompetitionEntry';
import { User, Name } from './User';

export function isMatchEntry(candidate: MatchEntry | any): candidate is MatchEntry {
    return (candidate as MatchEntry).abbreviation !== undefined;
}

export interface MatchEntryAdmin {
    addRosterEntry: (name: Name, capNumber: CapNumber, position: Position) => Promise<RosterEntry>;
}

export interface MatchEntry extends CompetitionEntryMetadata {
    id: string;
    rosterProvider: ListProvider<Resolver<RosterEntry>>;

    adminForUser: (user: User) => Promise<MatchEntryAdmin>;
}
