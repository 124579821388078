import { useState, useEffect } from 'react';
import { ListProvider } from '../models/interfaces/ListProvider';
import { Resolver } from '../models/interfaces/Resolver';

interface Props<T> {
    provider: ListProvider<Resolver<T>>;
    isType: (candidate: any) => candidate is T;
    sortFn?: (a: T, b: T) => number;
}

export function useUpdatableProviderToArray<T>({ provider, isType, sortFn }: Props<T>) {
    const [state, setState] = useState<T[] | undefined>();
    const [shouldUpdate, setShouldUpdate] = useState(false);

    function triggerUpdate() {
        setShouldUpdate(true);
    }

    useEffect(() => {
        if (shouldUpdate) {
            setShouldUpdate(false);
        }

        const listenerToken = provider.addListener((updatedResolvers) => {
            const listPromise = updatedResolvers.map((resolver) => {
                return new Promise<T | string>((resolve) => {
                    resolver.resolve(
                        (resolvedObject) => {
                            resolve(resolvedObject);
                        },
                        (errorMessage) => {
                            resolve(errorMessage);
                        }
                    );
                });
            });
            Promise.all(listPromise).then((resolvedPromises) => {
                const validArray = resolvedPromises.reduce((currentArray, resolvedObject) => {
                    if (isType(resolvedObject)) {
                        return [...currentArray, resolvedObject];
                    } else {
                        return currentArray;
                    }
                }, new Array<T>());
                if (sortFn) {
                    const sortedArray = validArray.sort(sortFn);
                    setState(sortedArray);
                } else {
                    setState(validArray);
                }
            });
        });
        return () => {
            provider.removeListener(listenerToken);
        };
    }, [provider, isType, sortFn, shouldUpdate]);

    return { state, triggerUpdate };
}

/**
 * @param provider - List Provider
 * @param isType - function to determine if value returned from list provider is the expected type
 * @param sortFn - optional function to sort the array. Please ensure the sort function is memorized & not an inline function to remove constant re-rendering of any sub-components
 *
 * @return array of requested type
 */
export default function useListProviderToArray<T>({ provider, isType, sortFn }: Props<T>) {
    return useUpdatableProviderToArray({ provider, isType, sortFn }).state;
}

interface PropsNoState<T> extends Props<T> {
    callback: (array: T[]) => void;
}

/**Please default to use useListProviderToArray. This function is provided to avoid using a reactHook outside the body of a react functional component.
 *
 * For Example use with an forEach method as seen in useGetTeamMatches
 *
 * It is expected that the returned value from this function is stored in state
 *
 * @param provider - List Provider
 * @param isType - function to determine if value returned from list provider is the expected type
 * @param sortFn - optional function to sort the array. Please ensure the sort function is memorized & not an inline function to remove constant re-rendering of any sub-components
 * @param callback - function to be executed once all promises have been finalized, expects a function similar to set state
 *
 * @return listenerToken
 */
export function listProviderToArrayNoState<T>({
    provider,
    isType,
    sortFn,
    callback,
}: PropsNoState<T>) {
    const listenerToken = provider.addListener((updatedResolvers) => {
        const listPromise = updatedResolvers.map((resolver) => {
            return new Promise<T | string>((resolve) => {
                resolver.resolve(
                    (resolvedObject) => {
                        resolve(resolvedObject);
                    },
                    (errorMessage) => {
                        resolve(errorMessage);
                    }
                );
            });
        });
        Promise.all(listPromise).then((resolvedPromises) => {
            let resolvedArray = resolvedPromises.reduce((currentArray, resolvedObject) => {
                if (isType(resolvedObject)) {
                    return [...currentArray, resolvedObject];
                } else {
                    return currentArray;
                }
            }, new Array<T>());
            if (sortFn) {
                resolvedArray.sort(sortFn);
            }
            callback(resolvedArray);
        });
    });
    return listenerToken;
}
