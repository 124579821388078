import { useState, useCallback } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { User } from '../../../models/interfaces/User';
import {
    CompetitionRegistrationRequest,
    EventRegistration,
} from '../../../models/interfaces/EventRegistration';
import { SportProvider } from '../../../models/interfaces/SportProvider';

import { RegistrationData } from '../../interfaces/EventUI';
import RegistrationModalBodyDisplay from './RegistrationModalBodyDisplay';
import { useToast } from '../../../context/useToastsContext';
import { v4 as uuid } from 'uuid';
import { CompetitionEntryRequest } from '../../../models/interfaces/Competitions/CompetitionEntryRequest';

interface Props {
    user: User;
    sportProvider: SportProvider;
    registration: EventRegistration;
    onCompleteRegistrationRequest: (entryRequest: CompetitionEntryRequest) => void;
    showModal: boolean;
    hideModal: () => void;
}

export default function EventRegistrationModal({
    user,
    sportProvider,
    registration,
    onCompleteRegistrationRequest,
    showModal,
    hideModal,
}: Props) {
    const event = registration.event;
    const [hasPendingRegistration, setHasPendingRegistration] = useState(false);

    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        user,
        competition: undefined,
        backingTeam: undefined,
        metadata: undefined,
        roster: undefined,
    });

    const { createToast } = useToast();

    const updateRegistrationData: <K extends keyof RegistrationData, T>(key: K, value: T) => void =
        useCallback((key, value) => {
            setRegistrationData((data) => ({ ...data, [key]: value }));
        }, []);

    const handleJoin = useCallback(async () => {
        if (
            user &&
            registrationData?.competition &&
            registrationData.backingTeam &&
            registrationData.roster
        ) {
            setHasPendingRegistration(true);
            const competitionEntryRequest: CompetitionRegistrationRequest = {
                id: uuid(),
                competition: registrationData.competition,
                backingTeam: registrationData.backingTeam,
                metadata: {
                    name: registrationData.backingTeam.name,
                    abbreviation: registrationData.backingTeam.abbreviation,
                    color: registrationData.backingTeam.color,
                },
                roster: registrationData.roster.map((player) => {
                    var registrationData: any = player;
                    registrationData['backingPlayer'] = player;
                    return registrationData;
                }),
            };
            registration
                .requestEntryToCompetition(
                    registrationData.competition,
                    user,
                    registrationData.backingTeam,
                    {
                        name: registrationData.backingTeam.name,
                        abbreviation: registrationData.backingTeam.abbreviation,
                        color: registrationData.backingTeam.color,
                    },
                    registrationData.roster.map((player) => {
                        var registrationData: any = player;
                        registrationData['backingPlayer'] = player;
                        return registrationData;
                    })
                )
                .then((registrationRequest) => {
                    onCompleteRegistrationRequest(registrationRequest);
                    createToast({
                        message: 'Successfully created competition entry',
                        backgroundColor: 'bg-success',
                    });
                })
                .catch((e) => {
                    // look to provide better error feedback
                    createToast({
                        message: 'An unexpected error occurred. Please try again',
                        backgroundColor: 'bg-danger',
                    });
                    console.error(e);
                })
                .finally(() => {
                    setRegistrationData({
                        user,
                        competition: undefined,
                        backingTeam: undefined,
                        metadata: undefined,
                        roster: undefined,
                    });
                    setHasPendingRegistration(false);
                });
        }
    }, [registration, registrationData, user, createToast]);

    const handleClear = () => {
        setRegistrationData({
            user,
            competition: undefined,
            backingTeam: undefined,
            metadata: undefined,
            roster: undefined,
        });
    };

    return (
        <Modal
            show={showModal}
            onHide={hideModal}
            size={registrationData.backingTeam ? 'xl' : undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Register for {registration.event.attributes.name}{' '}
                    {registrationData.competition && (
                        <h6>
                            <span>Competition - </span>
                            {registrationData.competition.attributes.name !==
                            registration.event.attributes.name
                                ? `${registrationData.competition.attributes.name} `
                                : ''}
                            <span className="text-muted">
                                {sportProvider.displayTextForTeamOrCompetitionAttributes(
                                    registrationData.competition.attributes
                                )}
                            </span>
                        </h6>
                    )}
                    {registrationData.organization && (
                        <h6>
                            <span>{`Organization - `}</span>
                            <span className="text-muted">{`${registrationData.organization.name}`}</span>
                        </h6>
                    )}
                    {registrationData.backingTeam && (
                        <h6>
                            <span>{`Backing Team - `}</span>
                            <span className="text-muted">{`${registrationData.backingTeam.name} - `}</span>
                            <span className="text-muted">
                                {sportProvider.displayTextForTeamOrCompetitionAttributes(
                                    registrationData.backingTeam.attributes
                                )}
                            </span>
                        </h6>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RegistrationModalBodyDisplay
                    user={user}
                    event={event}
                    registrationData={registrationData}
                    updateRegistrationData={updateRegistrationData}
                    sportProvider={sportProvider}
                />
            </Modal.Body>
            <Modal.Footer>
                {registrationData.competition && (
                    <Button variant="secondary" onClick={handleClear}>
                        Clear All Selections
                    </Button>
                )}
                <Button
                    variant="primary"
                    disabled={
                        !(
                            registrationData.competition &&
                            registrationData.backingTeam &&
                            registrationData.roster
                        )
                    }
                    onClick={handleJoin}
                >
                    {hasPendingRegistration ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        'Join Competition'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
