import { ListProvider } from './ListProvider';
import { Resolver } from './Resolver';
import { Team } from './Team';
import { User, PublicUser } from './User';
import { TeamAttributes, TeamColor } from './TeamAttributes';

export interface Organization {
    id: string;
    name: string;
    teamProvider: ListProvider<Resolver<Team>>;

    redeemInvitation(token: string, user: User): Promise<void>;

    adminForUser(user: User): Promise<OrganizationAdmin>;
}

export interface OrganizationAdmin {
    organization: Organization;

    updateName(name: string): Promise<Organization>;
    addTeam(
        name: string,
        abbrevitation: string,
        color: TeamColor,
        attributes: TeamAttributes
    ): Promise<Team>;

    updateTeam(
        team: Team,
        name: string,
        abbreviation: string,
        color: TeamColor,
        attributes: TeamAttributes
    ): Promise<Team>;

    removeTeam(team: Team): Promise<void>;

    adminProvider: ListProvider<Resolver<PublicUser>>;
    leaveOrganization(): Promise<void>;

    invitationProvider: ListProvider<Resolver<OrganizationInvitation>>;
    sendInvitation(email: string): Promise<OrganizationInvitation>;
    removeInvitation(invitation: OrganizationInvitation): Promise<void>;
}

export interface OrganizationInvitation {
    id: string;
    email: string;

    organization: Organization;
}

export function isOrganization(candidate): candidate is Organization {
    return (candidate as Organization).id !== undefined;
}

export function sortOrganizations(left: Organization, right: Organization) {
    return left.name < right.name ? -1 : 1;
}
