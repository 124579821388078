import {
    SportProvider,
    UpdatingResolverProvider,
    ResolverProvider,
    Availability,
} from '../../interfaces/SportProvider';
import { Match } from '../../interfaces/Match';
import { RosterEntry } from '../../interfaces/RosterEntry';
import { FirebaseMatchTranslator } from '../translators/FirebaseMatchTranslator';
import { PublicUser } from '../../interfaces/User';
import { FirebaseItemResolver } from '../translators/FirebaseItemResolver';
import { FirebasePublicUserTranslator } from '../translators/FirebasePublicUserTranslator';
import { FirebaseUpdatingItemResolver } from '../translators/FirebaseUpdatingItemResolver';
import { FirebaseRosterEntryTranslator } from '../translators/FirebaseRosterEntryTranslator';
import { Organization, OrganizationInvitation } from '../../interfaces/Organization';
import { FirebaseOrganizationInvitationTranslator } from '../translators/FirebaseOrganizationInvitationTranslator';
import { FirebaseBackedOrganization } from '../FirebaseBackedOrganization';
import { EventAdminInvitation } from '../../interfaces/Events/EventAdminInvitation';
import firebase from 'firebase';
import BuildUrl from 'build-url';
import { EventAdminPermission } from '../../interfaces/Event';
import { Resolver } from '../../interfaces/Resolver';

function eventPermissionFor(permissionValue: string): EventAdminPermission | undefined {
    if (EventAdminPermission.canEditEvent === permissionValue) {
        return EventAdminPermission.canEditEvent;
    } else if (EventAdminPermission.canEditCompetitions === permissionValue) {
        return EventAdminPermission.canEditCompetitions;
    } else if (EventAdminPermission.canEditTeams === permissionValue) {
        return EventAdminPermission.canEditTeams;
    } else if (EventAdminPermission.canEditMatches === permissionValue) {
        return EventAdminPermission.canEditMatches;
    } else if (EventAdminPermission.canEditStats === permissionValue) {
        return EventAdminPermission.canEditStats;
    }
    return undefined;
}

export class FirebaseResolverProvider implements ResolverProvider {
    sportProvider: SportProvider;
    database: firebase.database.Database;

    constructor(sportProvider: SportProvider, database: firebase.database.Database) {
        this.sportProvider = sportProvider;
        this.database = database;
    }

    publicUserResolver(userID: string) {
        const publicUserPath = `users/${userID}/public`;
        return new FirebaseItemResolver<PublicUser>(
            userID,
            this.database.ref(publicUserPath),
            new FirebasePublicUserTranslator(userID, this.database, this.sportProvider)
        );
    }

    organizationResolver(organizationID: string) {
        const organizationPath = `organizations/${organizationID}`;
        const organizationRef = this.database.ref(organizationPath);
        return new FirebaseItemResolver<Organization>(organizationID, organizationRef, {
            translate: (snapshot, onSuccess, onFailure) => {
                const val = snapshot.val() || {};
                const name = val['name'];
                if (name) {
                    const organization = new FirebaseBackedOrganization(
                        organizationID,
                        this.database,
                        this.sportProvider,
                        name
                    );
                    onSuccess(organization);
                } else {
                    const error = "Could not load organization's name";
                    onFailure(error);
                }
            },
        });
    }

    organizationInvitationResolver(invitationID: string) {
        const invitationPath = `invitations/${invitationID}`;
        return new FirebaseItemResolver<OrganizationInvitation>(
            invitationID,
            this.database.ref(invitationPath),
            new FirebaseOrganizationInvitationTranslator(
                invitationID,
                this.database,
                this.sportProvider
            )
        );
    }

    eventAdminPermissionResolver(
        eventID: string,
        adminID: string
    ): Resolver<{ user: PublicUser; permission: EventAdminPermission }> {
        const eventAdminPermissionsPath = `/events/${eventID}/admins/${adminID}`;
        return new FirebaseItemResolver<{
            user: PublicUser;
            permission: EventAdminPermission;
        }>(adminID, this.database.ref(eventAdminPermissionsPath), {
            translate: (snapshot, onSuccess, onFailure) => {
                const permissionValue = snapshot.val();
                const resolvedPermission = eventPermissionFor(permissionValue);
                if (typeof resolvedPermission !== 'undefined') {
                    const permission: EventAdminPermission = resolvedPermission;
                    this.publicUserResolver(adminID)
                        .asAPromise()
                        .then((user) => {
                            onSuccess({
                                user,
                                permission,
                            });
                        })
                        .catch((error) => {
                            onFailure(`Could not resolve user: ${error}`);
                        });
                } else {
                    onFailure('Could not resolve permissions');
                }
            },
        });
    }

    eventAdminInvitationResolver(id: string) {
        const eventAdminInvitationPath = `eventAdminInvitations/${id}`;
        return new FirebaseItemResolver<EventAdminInvitation>(
            id,
            this.database.ref(eventAdminInvitationPath),
            {
                translate: (snapshot, onSuccess, onFailure) => {
                    const { event, email, permission: permissionValue } = snapshot.val();
                    const resolvedPermission = eventPermissionFor(permissionValue);
                    if (!resolvedPermission) {
                        onFailure('Missing target permission for invitation');
                        return;
                    }
                    this.sportProvider
                        .eventResolver(event)
                        .asAPromise()
                        .then((event) => {
                            const url =
                                process.env.REACT_APP_PUBLIC_URL || `https://table.cbwaterpolo.com`;
                            const link = BuildUrl(url, {
                                path: 'redeemEventAdminInvitation',
                                queryParams: {
                                    token: id,
                                    event: event.id,
                                },
                            });

                            const invitation: EventAdminInvitation = {
                                id: id,
                                email,
                                event,
                                link,
                                permission: resolvedPermission,
                            };
                            onSuccess(invitation);
                        })
                        .catch((error) => {
                            onFailure(error);
                        });
                },
            }
        );
    }
}

export class FirebaseUpdatingResolverProvider implements UpdatingResolverProvider {
    sportProvider: SportProvider;
    database: firebase.database.Database;

    constructor(sportProvider: SportProvider, database: firebase.database.Database) {
        this.sportProvider = sportProvider;
        this.database = database;
    }

    updatingMatchResolver(id: string) {
        const ref = this.database.ref(`matches/${id}`);
        return new FirebaseUpdatingItemResolver<Match>(
            id,
            ref,
            new FirebaseMatchTranslator(id, this.database, this.sportProvider)
        );
    }

    updatingRosterEntryResolver(
        competitionID: string,
        competitionEntryID: string,
        playerID: string
    ) {
        const ref = this.database.ref(
            `competitions/${competitionID}/entries/${competitionEntryID}/roster/${playerID}`
        );
        if (!competitionID || !competitionEntryID) {
            console.log('missing competition ids for updating resolver');
        }
        return new FirebaseUpdatingItemResolver<RosterEntry>(
            playerID,
            ref,
            new FirebaseRosterEntryTranslator(
                playerID,
                this.database,
                this.sportProvider,
                competitionID,
                competitionEntryID
            )
        );
    }

    updatingAvailabiltiyResolver() {
        const ref = this.database.ref('availabilityStatus');
        return new FirebaseUpdatingItemResolver<Availability>('availabilityStatus', ref, {
            translate: (snapshot, onSuccess, onFailure) => {
                const isHidden = !!(snapshot.val() || {})['isHidden'];
                const availability: Availability = { isHidden: isHidden };
                onSuccess(availability);
            },
        });
    }
}
