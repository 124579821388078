import { Competition } from '../../../../interfaces/Competition';
import { Player } from '../../../../interfaces/Player';
import { CapNumber, Name, Position, RosterEntry } from '../../../../interfaces/RosterEntry';
import { MatchEntry } from '../../../../interfaces/MatchEntry';
import { TeamCompetitionEntry } from './TeamCompetitionEntry';
export class PlayerRosterEntry implements RosterEntry {
    id: string;
    name: Name;
    capNumber: CapNumber;
    position: Position;
    team: MatchEntry;
    isArchived: boolean;

    constructor(player: Player, competition: Competition) {
        this.id = player.id;
        this.name = player.name;
        this.capNumber = player.capNumber;
        this.position = player.position;
        this.isArchived = false;

        this.team = new TeamCompetitionEntry(player.team, competition);
    }
}
