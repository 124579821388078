import { Team } from '../../interfaces/Team';
import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { SportProvider } from '../../interfaces/SportProvider';
import { FirebaseBackedTeam } from '../FirebaseBackedTeam';
import firebase from 'firebase';

export class FirebaseTeamTranslator implements FirebaseItemTranslator<Team> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    constructor(id: string, sportProvider: SportProvider, database: firebase.database.Database) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
    }

    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (Team) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val() || {};
        const accessGroupID = val.accessGroup;
        if (!accessGroupID) {
            const accessGroupError = 'Failed to resolve access group';
            onFailure(accessGroupError);
            return;
        }

        this.sportProvider
            .accessGroupResolver(accessGroupID)
            .asAPromise()
            .then((accessGroup) => {
                const name = val['name'];
                if (!name) {
                    const nameError = `Failed to resolve name: ${this.id}`;
                    onFailure(nameError);
                    return;
                }

                const abbreviation = val['shortName'];
                if (!abbreviation) {
                    onFailure('Failed to fetch abbreviation');
                    return;
                }
                const categoryDatabaseValue = val['teamTypeV2'] || val['teamType'];
                const divisionDatabaseValue = val['teamDivisionV2'] || val['teamDivision'];

                const { category, division } = this.sportProvider.teamValues(
                    categoryDatabaseValue,
                    divisionDatabaseValue
                );
                if (!category) {
                    const categoryError = 'Failed to resolve category';
                    onFailure(categoryError);
                    return;
                }

                if (!division) {
                    const divisionError = 'Failed to resolve division';
                    onFailure(divisionError);
                    return;
                }

                const genderDatabaseValue = val['teamGender'];
                const { gender } = this.sportProvider.teamGender(genderDatabaseValue);
                if (!gender) {
                    const genderError = 'Failed to resolve gender';
                    onFailure(genderError);
                    return;
                }

                const color = val['color'] || {};
                const red = color['red'];
                const blue = color['blue'];
                const green = color['green'];
                if (
                    typeof red !== 'number' ||
                    typeof blue !== 'number' ||
                    typeof green !== 'number'
                ) {
                    onFailure('Failed to resolve a color');
                    return;
                }
                const team = new FirebaseBackedTeam(
                    this.id,
                    this.sportProvider,
                    this.database,
                    accessGroup,
                    name,
                    abbreviation,
                    {
                        category: category,
                        division: division,
                        gender: gender,
                    },
                    {
                        red: red,
                        blue: blue,
                        green: green,
                    },
                    this.sportProvider.teamDetailMatchStatColumnDescriptions,
                    this.sportProvider.teamDetailPlayerStatColumnDescriptions
                );
                onSuccess(team);
            })
            .catch(() => {
                onFailure('failed to resolve Access Group');
            });
    }
}
