import { Nav, Navbar, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Footer() {
    if (window.location !== window.parent.location) {
        return <></>;
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'end',
            padding: '20px',

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
            }}>
                <a href="https://www.goldbarsports.tech/"><small>Made by Gold Bar Sports Tech, LLC</small></a>
                <a href="mailto:support@cbwaterpolo.com"><small>Contact</small></a>
            </div>
        </div>        
    );
}
