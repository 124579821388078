import { CapNumber, RosterEntry, Position } from '../interfaces/RosterEntry';
import { MatchEntry } from '../interfaces/MatchEntry';
import { Name, PublicUser, User } from '../interfaces/User';
import { Player } from '../interfaces/Player';
import { Match, MatchStatus } from '../interfaces/Match';
import { CompetitionEntry } from '../interfaces/Competitions/CompetitionEntry';
import { Stat, StatTypeDescribing } from '../interfaces/Stat';
import { PlayerStats } from '../interfaces/PlayerStatProvider';
import { ClaimPlayerRequest, Team } from '../interfaces/Team';
import axios from 'axios';

export class FirebaseBackedPlayer implements Player {
    id: string;
    name: Name;
    capNumber: CapNumber;
    position: Position;
    team: Team;
    isArchived: boolean;
    backingUser?: PublicUser;

    constructor(
        id: string,
        name: Name,
        capNumber: CapNumber,
        position: Position,
        team: Team,
        backingUser?: PublicUser
    ) {
        this.id = id;
        this.name = name;
        this.capNumber = capNumber;
        this.position = position;
        this.team = team;
        this.isArchived = false;
        this.backingUser = backingUser;
    }

    matchStatProvider(statDescriptions: StatTypeDescribing[]) {
        return this.team.competitionEntryProvider
            .once()
            .then((competitionEntryResolvers) => {
                return Promise.all(
                    competitionEntryResolvers.map((competitionEntryResolver) => {
                        return new Promise<CompetitionEntry | undefined>((resolve) => {
                            competitionEntryResolver
                                .asAPromise()
                                .then((competitionEntry) => {
                                    resolve(competitionEntry);
                                })
                                .catch(() => {
                                    resolve(undefined);
                                });
                        });
                    })
                );
            })
            .then((resolvedPromises) => {
                const competitionEntries = resolvedPromises.reduce(
                    (currentEntries, resolvedObject) => {
                        if (resolvedObject) {
                            return currentEntries.concat([resolvedObject]);
                        } else {
                            return currentEntries;
                        }
                    },
                    new Array<CompetitionEntry>()
                );
                return Promise.all(
                    competitionEntries.map((competitionEntry) => {
                        return competitionEntry.matchProvider.once().then((matchResolvers) => {
                            return matchResolvers;
                        });
                    })
                );
            })
            .then((matchResolversArray) => {
                return Promise.all(
                    matchResolversArray.reduce((currentMatchPromises, matchResolvers) => {
                        const matchPromises = matchResolvers.map((matchResolver) => {
                            return new Promise<Match | undefined>((resolve) => {
                                matchResolver
                                    .asAPromise()
                                    .then((match) => {
                                        resolve(match);
                                    })
                                    .catch(() => {
                                        resolve(undefined);
                                    });
                            });
                        });
                        return currentMatchPromises.concat(matchPromises);
                    }, new Array<Promise<Match | undefined>>())
                );
            })
            .then((resolvedPromises) => {
                const matches = resolvedPromises.reduce((currentMatches, resolvedObject) => {
                    if (resolvedObject && resolvedObject.status === MatchStatus.completed) {
                        var updatedMatches = currentMatches;
                        updatedMatches.push(resolvedObject);
                        return updatedMatches;
                    } else {
                        return currentMatches;
                    }
                }, new Array<Match>());
                const statPromises = matches.map((match) => {
                    return new Promise<PlayerStats | undefined>((resolve) => {
                        match.statProvider
                            .once()
                            .then((matchStats) => {
                                const reducedStats = statDescriptions.map((statDescription) => {
                                    return matchStats.reduce((currentStats, matchSegmentStats) => {
                                        return currentStats.concat(
                                            matchSegmentStats.stats.filter((stat) => {
                                                if (!stat.player || stat.player.id !== this.id) {
                                                    return false;
                                                }
                                                return statDescription.matchesDescription(
                                                    stat.description
                                                );
                                            })
                                        );
                                    }, new Array<Stat>());
                                });
                                const playerStats: PlayerStats = {
                                    match: match,
                                    stats: reducedStats,
                                };
                                resolve(playerStats);
                            })
                            .catch(() => {
                                resolve(undefined);
                            });
                    });
                });
                return Promise.all(statPromises).then((resolveStatPromises) => {
                    return resolveStatPromises.reduce((currentStats, resolvedObject) => {
                        if (resolvedObject) {
                            var updatedStats = currentStats;
                            updatedStats.push(resolvedObject);
                            return updatedStats;
                        } else {
                            return currentStats;
                        }
                    }, new Array<PlayerStats>());
                });
            });
    }

    createClaimRequest(user: User): Promise<ClaimPlayerRequest> {
        return user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                playerID: this.id,
                teamID: this.team.id,
            };
            var apiPath = '/api/players/createClaimRequest';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }

            return axios
                .post(apiPath, body)
                .then((response) => {
                    if (response.status == 201) {
                        return Promise.resolve({
                            player: this,
                            backingUser: user,
                            email: user.email,
                        });
                    } else {
                        return Promise.reject(`Bad response: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(`Failed to create claim request: ${error}`);
                });
        });
    }
}

export class FirebaseBackedRosterEntry implements RosterEntry {
    id: string;
    name: Name;
    capNumber: CapNumber;
    position: Position;
    team: MatchEntry;
    isArchived: boolean;
    backingPlayer?: Player;

    constructor(
        id: string,
        name: Name,
        capNumber: CapNumber,
        position: Position,
        team: MatchEntry,
        isArchived: boolean,
        backingPlayer?: Player
    ) {
        this.id = id;
        this.name = name;
        this.capNumber = capNumber;
        this.position = position;
        this.team = team;
        this.isArchived = isArchived;
        this.backingPlayer = backingPlayer;
    }
}
