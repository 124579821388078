import { ListProvider } from '../../../../interfaces/ListProvider';
import { Resolver } from '../../../../interfaces/Resolver';

export class ResolverMapping<InitialType, MappedType> implements Resolver<MappedType> {
    initialResolver: Resolver<InitialType>;
    mappingFunction: (InitialType) => Promise<MappedType>;

    id: string;
    hasResolved: boolean;
    resolvedObject?: MappedType;

    constructor(
        id: string,
        initialResolver: Resolver<InitialType>,
        mappingFunction: (InitialType) => Promise<MappedType>
    ) {
        this.id = id;
        this.initialResolver = initialResolver;
        this.hasResolved = false;
        this.mappingFunction = mappingFunction;
    }

    resolve(onSuccess, onFailure) {
        return this.initialResolver.resolve(
            (initialItem) => {
                this.hasResolved = true;
                this.mappingFunction(initialItem)
                    .then((mappedObject) => {
                        this.resolvedObject = mappedObject;
                        onSuccess(mappedObject);
                    })
                    .catch((error) => {
                        onFailure(error);
                    });
            },
            (error) => {
                this.hasResolved = true;
                onFailure(error);
            }
        );
    }

    cancel(token) {
        this.initialResolver.cancel(token);
    }

    asAPromise() {
        return this.initialResolver.asAPromise().then((resolvedItem) => {
            return this.mappingFunction(resolvedItem);
        });
    }
}

export class ListProviderMapping<InitialType, MappedType> implements ListProvider<MappedType> {
    initialTypeListProvider: ListProvider<InitialType>;
    mappingFunction: (InitialType) => MappedType;

    constructor(
        initialTypeListProvider: ListProvider<InitialType>,
        mappingFunction: (InitialType) => MappedType
    ) {
        this.initialTypeListProvider = initialTypeListProvider;
        this.mappingFunction = mappingFunction;
    }

    once() {
        return this.initialTypeListProvider.once().then((initialItems) => {
            return initialItems.map((item) => {
                return this.mappingFunction(item);
            });
        });
    }

    addListener(callback) {
        return this.initialTypeListProvider.addListener((items) => {
            callback(
                items.map((item) => {
                    return this.mappingFunction(item);
                })
            );
        });
    }

    removeListener(token) {
        this.initialTypeListProvider.removeListener(token);
    }
}
