import { useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import StyledListGroupItem from '../../../components/containers/StyledListGroupItem';
import TeamView from '../../../components/containers/TeamView';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import useListProviderToArray from '../../../helpers/useListProviderToArray';
import { Organization, OrganizationAdmin } from '../../../models/interfaces/Organization';
import { isTeam } from '../../../models/interfaces/Team';
import { RegistrationData } from '../../interfaces/EventUI';

interface Props {
    organizationAdmin?: OrganizationAdmin;
    organization: Organization;
    handleSelect: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
}

const SelectBackingTeam = ({ organizationAdmin, organization, handleSelect }: Props) => {
    const organizationTeamsArray = useListProviderToArray({
        provider: organization.teamProvider,
        isType: isTeam,
    });

    useEffect(() => {
        // if only one organization team just accept that team and move on
        if (organizationTeamsArray?.length === 1) {
            handleSelect('backingTeam', organizationTeamsArray[0]);
        }
    }, [organizationTeamsArray, handleSelect]);

    if (!organizationTeamsArray || !organizationAdmin)
        return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

    if (organizationTeamsArray.length === 0)
        return (
            <div>
                No team is associated with {organization.name}. Please create a team on the
                Organization Management page
            </div>
        );

    return (
        <>
            <div>Select Competition Entry Backing Team</div>
            <ListGroup className="d-flex flex-grow-1">
                {organizationTeamsArray.map((team) => {
                    return (
                        <StyledListGroupItem
                            key={team.id}
                            action
                            onClick={() => {
                                handleSelect('backingTeam', team);
                            }}
                            className="flex-grow-1"
                            color={parseTeamColor(team.color)}
                        >
                            <TeamView
                                name={team.name}
                                subtitle={`${team.attributes.gender.name} ${team.attributes.division.name}`}
                            />
                        </StyledListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

export default SelectBackingTeam;
