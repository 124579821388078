import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { PerformerTypes, Stat, TableWorker } from '../../interfaces/Stat';
import { isRosterEntry, RosterEntry } from '../../interfaces/RosterEntry';
import { isMatchEntry, MatchEntry } from '../../interfaces/MatchEntry';
import { SportProvider } from '../../interfaces/SportProvider';
import { PlayerRosterEntry } from '../sports/waterpolo/LegacySupport/PlayerRosterEntry';
import { TeamMatchEntry } from '../sports/waterpolo/LegacySupport/TeamMatchEntry';
import moment from 'moment';
import firebase from 'firebase';

export class FirebaseStatTranslator implements FirebaseItemTranslator<Stat> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    translate(snapshot, onSuccess: (Stat) => void, onFailure) {
        const { type, tableWorker, match, matchSegment, timestamp } = snapshot.val();
        if (!match) {
            onFailure('Missing match id');
            return;
        }

        const subtypeDatabaseValue = snapshot.val()['subtype'];
        const statDescription = this.sportProvider.statDescription(type, subtypeDatabaseValue);
        if (!statDescription) {
            onFailure('Failed to resolve a stat description');
            return;
        }

        const matchPromise = this.sportProvider.matchResolver(match).asAPromise();
        matchPromise.then((resolvedMatch) => {
            const matchSegmentObject = this.sportProvider.matchSegment(
                resolvedMatch.parameters.regularTimeMatchSegmentOption,
                matchSegment
            );
            const remarks = snapshot.val()['remarks'];
            const displayName = statDescription.dataSubType
                ? statDescription.dataSubType.displayName
                : statDescription.dataType.displayName;
            const abbreviation = statDescription.dataSubType
                ? statDescription.dataSubType.abbreviation
                : statDescription.dataType.abbreviation;
            const scoreValue = statDescription.dataSubType
                ? statDescription.dataSubType.scoreValue
                : statDescription.dataType.scoreValue;

            const performerPromise = new Promise<MatchEntry | RosterEntry | TableWorker>(
                (resolve, reject) => {
                    const statVal = snapshot.val();
                    const supportedPerfomers = statDescription.supportedPerfomers;

                    if (
                        supportedPerfomers.has(PerformerTypes.player) &&
                        statVal['playerV2'] &&
                        statVal['teamV2']
                    ) {
                        this.sportProvider
                            .rosterEntryResolver(
                                resolvedMatch.competition.id,
                                statVal['teamV2'],
                                statVal['playerV2']
                            )
                            .asAPromise()
                            .then((player) => {
                                resolvedMatch
                                    .capChangeForPlayer(player)
                                    .then((capChange) => {
                                        if (capChange) {
                                            player.capNumber = capChange;
                                            resolve(player);
                                        } else {
                                            resolve(player);
                                        }
                                    })
                                    .catch(() => {
                                        resolve(player);
                                    });
                                resolve(player);
                            })
                            .catch((error) => {
                                console.error('Failed to resolve player: ', error);
                                reject(error);
                            });
                    } else if (supportedPerfomers.has(PerformerTypes.player) && statVal['player']) {
                        this.sportProvider
                            .playerResolver(statVal['player'])
                            .asAPromise()
                            .then((player) => {
                                const rosterEntry = new PlayerRosterEntry(
                                    player,
                                    resolvedMatch.competition
                                );
                                resolve(rosterEntry);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else if (supportedPerfomers.has(PerformerTypes.team) && statVal['teamV2']) {
                        this.sportProvider
                            .competitionEntryResolver(
                                resolvedMatch.competition.id,
                                statVal['teamV2']
                            )
                            .asAPromise()
                            .then((team) => {
                                resolve(team);
                            })
                            .catch((error) => {
                                console.error('Failed to resolve match entry');
                                reject(error);
                            });
                    } else if (supportedPerfomers.has(PerformerTypes.team) && statVal['team']) {
                        this.sportProvider
                            .teamResolver(statVal['team'])
                            .asAPromise()
                            .then((team) => {
                                const matchEntry = new TeamMatchEntry(team);
                                resolve(matchEntry);
                            })
                            .catch((error) => {
                                console.log('Failed to resolve match entry');
                                reject(error);
                            });
                    } else if (supportedPerfomers.has(PerformerTypes.tableWorker) && tableWorker) {
                        this.sportProvider.resolverProvider
                            .publicUserResolver(tableWorker)
                            .asAPromise()
                            .then((publicUser) => {
                                return { user: publicUser };
                            })
                            .then((tableWorker) => {
                                resolve(tableWorker);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        console.error(
                            'Failed to satisfy the perfomer requirement for ',
                            statDescription,
                            ' with teamV2: ',
                            statVal['teamV2'],
                            ' team: ',
                            statVal['team'],
                            ' playerV2: ',
                            statVal['playerV2'],
                            ' player: ',
                            statVal['player'],
                            ' tableworker: ',
                            tableWorker
                        );
                        reject(`Failed to satisfy the perfomer requirement`);
                    }
                }
            );
            performerPromise
                .then((performer) => {
                    const creationTime = snapshot.val()['creationTime']
                        ? moment(snapshot.val()['creationTime'], 'YYYY-MM-DD HH:mm:ss Z').toDate()
                        : new Date();
                    const updateTime = snapshot.val()['lastUpdateTime']
                        ? moment(snapshot.val()['lastUpdateTime'], 'YYYY-MM-DD HH:mm:ss Z').toDate()
                        : undefined;
                    const stat: Stat = {
                        id: this.id,
                        displayName: displayName,
                        abbreviation: abbreviation,
                        description: statDescription,
                        scoreValue: scoreValue,
                        team: isRosterEntry(performer)
                            ? performer.team
                            : isMatchEntry(performer)
                            ? performer
                            : undefined,
                        player: isRosterEntry(performer) ? performer : undefined,
                        match: resolvedMatch,
                        performer: performer,
                        remarks: remarks,
                        matchSegment: matchSegmentObject,
                        timestamp: timestamp,
                        creationTime: creationTime,
                        lastUpdateTime: updateTime,
                    };
                    onSuccess(stat);
                })
                .catch((perfomerError) => {
                    onFailure(`Failed to fetch performer with error: ${perfomerError}`);
                });
        });
    }
}
