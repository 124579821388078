import { useCallback, useState } from 'react';
import { Button, Spinner, Col, Row, Modal } from 'react-bootstrap';
import { useUpdatableProviderToArray } from '../../../helpers/useListProviderToArray';
import { isCompetition, Competition } from '../../../models/interfaces/Competition';
import { EventAdmin, EventCompetitionEditor } from '../../../models/interfaces/Event';
import { SportProvider } from '../../../models/interfaces/SportProvider';
import CreateCompetition from '../../competitions/CreateCompetition';
import styled from 'styled-components';

const ContainerView = styled.div`
    padding-top: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`;

export const DivisionButtonContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
`;

export function EventCompetitionAdminTable({
    sportProvider,
    eventAdmin,
    onSelectCompetition,
}: {
    sportProvider: SportProvider;
    eventAdmin: EventAdmin;
    onSelectCompetition: (competition: Competition | undefined) => void;
}) {
    const memorizedCompetitionSort = useCallback((a: Competition, b: Competition) => {
        if (a.attributes.name < b.attributes.name) return -1;
        return 1;
    }, []);

    const { state: competitions, triggerUpdate } = useUpdatableProviderToArray({
        provider: eventAdmin.event.competitionProvider,
        isType: isCompetition,
        sortFn: memorizedCompetitionSort,
    });

    const [showCreateCompetitionModal, setShowCreateCompetitionModal] = useState(false);

    return (
        <ContainerView>
            {!competitions ? (
                <Col>
                    <Row>
                        <h4>Loading</h4>
                    </Row>
                    <Row>
                        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                    </Row>
                </Col>
            ) : competitions && competitions.length == 0 ? (
                eventAdmin.competitionEditor ? (
                    <NoCompetitionsView
                        competitionEditor={eventAdmin.competitionEditor}
                        onAddButtonClicked={() => {
                            setShowCreateCompetitionModal(true);
                        }}
                    />
                ) : (
                    <Col className="text-center mt-2">
                        <h4>
                            No divisions have been added yet, please contact your administrator.
                        </h4>
                    </Col>
                )
            ) : (
                <>
                    <ButtonContainer>
                        {competitions.map((competition) => {
                            return (
                                <Button
                                    key={competition.id}
                                    onClick={() => {
                                        onSelectCompetition(competition);
                                    }}
                                >
                                    <DivisionButtonContent>
                                        <strong>{competition.attributes.name}</strong>
                                        <small>
                                            {sportProvider.displayTextForTeamOrCompetitionAttributes(
                                                competition.attributes
                                            )}
                                        </small>
                                    </DivisionButtonContent>
                                </Button>
                            );
                        })}
                        {eventAdmin.competitionEditor && (
                            <Button
                                variant="success"
                                onClick={() => setShowCreateCompetitionModal(true)}
                            >
                                Add a division
                            </Button>
                        )}
                    </ButtonContainer>
                </>
            )}
            <Modal
                show={showCreateCompetitionModal}
                onHide={() => setShowCreateCompetitionModal(false)}
            >
                <Modal.Header>Create new division</Modal.Header>
                <Modal.Body>
                    <CreateCompetition
                        sportProvider={sportProvider}
                        parentEvent={eventAdmin.event}
                        onSubmitCompetitionAttributes={(competitionAttributes) => {
                            if (eventAdmin?.competitionEditor) {
                                return eventAdmin.competitionEditor
                                    .addCompetition(competitionAttributes)
                                    .then(() => {
                                        setShowCreateCompetitionModal(false);
                                    });
                            } else {
                                return Promise.reject('Missing permissions to update competition');
                            }
                        }}
                        onHide={() => {
                            setShowCreateCompetitionModal(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
        </ContainerView>
    );
}

function NoCompetitionsView({
    competitionEditor,
    onAddButtonClicked,
}: {
    competitionEditor: EventCompetitionEditor;
    onAddButtonClicked: () => void;
}) {
    return (
        <Col className="text-center mt-2">
            <h4>Add a division to begin adding teams to your event.</h4>
            <Row>
                <Col>
                    <Button variant="success" onClick={onAddButtonClicked}>
                        + Add a division
                    </Button>
                </Col>
            </Row>
        </Col>
    );
}
