import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { RosterEntry } from '../../interfaces/RosterEntry';
import { SportProvider } from '../../interfaces/SportProvider';
import { FirebaseBackedRosterEntry } from '../FirebaseBackedPlayer';
import firebase from 'firebase';
import { Player } from '../../interfaces/Player';

export class FirebaseRosterEntryTranslator implements FirebaseItemTranslator<RosterEntry> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;
    competitionID: string;
    competitionEntryID: string;

    constructor(
        id: string,
        database: firebase.database.Database,
        sportProvider: SportProvider,
        competitionID: string,
        competitionEntryID: string
    ) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
        this.competitionID = competitionID;
        this.competitionEntryID = competitionEntryID;
        if (!competitionID) {
            console.log('Missing competition id');
        }
        if (!competitionEntryID) {
            console.log('Missing competition entry id');
        }
    }

    translate(snapshot, onSuccess: (resolvedItem: RosterEntry) => void, onFailure) {
        const val = snapshot.val();
        if (!val) {
            console.log(
                'missing val for competition: ',
                this.competitionID,
                ' competitionEntry: ',
                this.competitionEntryID,
                ' playerID: ',
                this.id
            );
            onFailure('missing val');
            return;
        }
        const name = val['name'] || {};
        const capNumberDatabaseValue = val['capNumber'];
        const position = val['position'];

        var firstName = name['firstName'];
        var lastName = name['lastName'];
        if (!firstName && !lastName) {
            lastName = '(No Name Provided)';
        }

        const capNumber = this.sportProvider.capNumber(capNumberDatabaseValue);
        const playerPosition = this.sportProvider.playerPosition(position);
        const isArchived = val['isArchived'] || false;

        if (capNumber && playerPosition) {
            const handleBackingPlayer = (player?: Player) => {
                this.sportProvider
                    .competitionEntryResolver(this.competitionID, this.competitionEntryID)
                    .asAPromise()
                    .then((resolvedCompetitionEntry) => {
                        const rosterEntryName = player
                            ? player.name
                            : { firstName: firstName, lastName: lastName };
                        const rosterEntry: RosterEntry = new FirebaseBackedRosterEntry(
                            this.id,
                            rosterEntryName,
                            capNumber,
                            playerPosition,
                            resolvedCompetitionEntry,
                            isArchived,
                            player
                        );
                        onSuccess(rosterEntry);
                    });
            };

            const backingPlayerID = snapshot.val()['backingPlayer'];
            if (backingPlayerID) {
                this.sportProvider
                    .playerResolver(backingPlayerID)
                    .asAPromise()
                    .then((player) => {
                        handleBackingPlayer(player);
                    })
                    .catch(() => {
                        handleBackingPlayer(undefined);
                    });
            } else {
                handleBackingPlayer(undefined);
            }
        } else {
            onFailure('Missing roster entry values');
            return;
        }
    }
}
