import { useCallback } from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import { Team } from '../../../models/interfaces/Team';
import { Competition } from '../../../models/interfaces/Competition';
import { RegistrationData } from '../../interfaces/EventUI';

interface Props {
    selectedCompetition: Competition;
    selectedTeam: Team;
    differentCategory: boolean;
    differentDivision: boolean;
    differentGender: boolean;
    clearWarning: () => void;
    updateRegistrationData: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
}

const TeamAndCompetitionNotMatchingTable = ({
    selectedCompetition,
    selectedTeam,
    differentCategory,
    differentDivision,
    differentGender,
    clearWarning,
    updateRegistrationData,
}: Props) => {
    const handleBack = useCallback(() => {
        updateRegistrationData('backingTeam', undefined);
    }, [updateRegistrationData]);

    return (
        <>
            <div>The selected team and competition types do not match:</div>
            <Table striped bordered className="my-2" size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Category</th>
                        <th>Division</th>
                        <th>Gender</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ fontWeight: 700 }}>{selectedCompetition.attributes.name}</td>
                        <td className={differentCategory ? 'table-warning' : ''}>
                            {selectedCompetition.attributes.category.name}
                        </td>
                        <td className={differentDivision ? 'table-warning' : ''}>
                            {selectedCompetition.attributes.division.name}
                        </td>
                        <td className={differentGender ? 'table-warning' : ''}>
                            {selectedCompetition.attributes.gender.name}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 700 }}>{selectedTeam?.name}</td>
                        <td className={differentCategory ? 'table-warning' : ''}>
                            {selectedTeam?.attributes.category.name}
                        </td>
                        <td className={differentDivision ? 'table-warning' : ''}>
                            {selectedTeam?.attributes.division.name}
                        </td>
                        <td className={differentGender ? 'table-warning' : ''}>
                            {selectedTeam?.attributes.gender.name}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Row className="justify-content-end">
                <Col xs="auto">
                    <Button variant="secondary" onClick={handleBack} className="mr-1">
                        Back
                    </Button>
                    <Button variant="warning" onClick={clearWarning}>
                        Proceed With Selected Backing Team
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default TeamAndCompetitionNotMatchingTable;
