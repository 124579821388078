import { AuthProvider, SignInCallbacks } from '../interfaces/AuthProvider';
import { User, Name, PublicUser } from '../interfaces/User';
import { isNull } from 'lodash';
import firebase from 'firebase';

import { FirebaseBackedUser } from './FirebaseBackedUser';
import { SportProvider } from '../interfaces/SportProvider';
import { FirebaseItemResolver } from './translators/FirebaseItemResolver';
import { FirebasePublicUserTranslator } from './translators/FirebasePublicUserTranslator';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';

export class FirebaseAuthProvider implements AuthProvider {
    currentUser?: User = undefined;

    constructor(
        sportProvider: SportProvider,
        firebaseAuth: firebase.auth.Auth,
        firebaseDatabase: firebase.database.Database
    ) {
        this.sportProvider = sportProvider;
        this.firebaseAuth = firebaseAuth;
        this.firebaseDatabase = firebaseDatabase;
    }

    private sportProvider: SportProvider;
    private firebaseDatabase: firebase.database.Database;
    private firebaseAuth: firebase.auth.Auth;

    onUpdateCurrentUser(callback) {
        return this.firebaseAuth.onAuthStateChanged((authedUser) => {
            if (isNull(authedUser)) {
                callback(undefined);
                this.currentUser = undefined;
            } else {
                this.loadUserFrom(
                    authedUser,
                    (loadedUser: FirebaseBackedUser) => {
                        callback(loadedUser);
                        this.currentUser = loadedUser;
                    },
                    (_) => {
                        callback(undefined);
                        this.currentUser = undefined;
                    }
                );
            }
        });
    }

    signIn(email: string, password: string, callbacks: SignInCallbacks) {
        this.firebaseAuth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential: firebase.auth.UserCredential) => {
                const firebaseUser = userCredential.user;
                if (firebaseUser) {
                    this.loadUserFrom(firebaseUser, callbacks.onSuccess, callbacks.onFailure);
                } else {
                    callbacks.onFailure('Failed to load user data');
                }
            })
            .catch((authError) => {
                let errorMessage = 'An error occurred, please try again';
                if (authError.code === 'auth/invalid-email') {
                    errorMessage = 'Invalid email, please try again';
                } else if (authError.code === 'auth/wrong-password') {
                    errorMessage = 'Invalid password, please try again';
                } else if (authError.code === 'auth/user-not-found') {
                    errorMessage = 'No account found for this email';
                }
                callbacks.onFailure(errorMessage);
            });
    }

    signUp(email: string, password: string, name: Name, callbacks: SignInCallbacks) {
        this.firebaseAuth
            .createUserWithEmailAndPassword(email, password)
            .then((userCredential: firebase.auth.UserCredential) => {
                const firebaseUser = userCredential.user;
                if (firebaseUser) {
                    const newUserData = {
                        name: {
                            firstName: name.firstName,
                            lastName: name.lastName,
                        },
                        isNewUser: true,
                    };
                    const uid = firebaseUser.uid;
                    const userPath = `users/${uid}/public`;
                    const userReference = this.firebaseDatabase.ref(userPath);

                    const userPlayersReference = this.firebaseDatabase.ref(`/users/${uid}/players`);
                    const backedPlayers = new FirebaseBackedListProvider(
                        userPlayersReference,
                        (eventID) => {
                            return this.sportProvider.playerResolver(eventID);
                        }
                    );

                    userReference
                        .set(newUserData)
                        .then(() => {
                            callbacks.onSuccess(
                                new FirebaseBackedUser(
                                    uid,
                                    name,
                                    this.sportProvider,
                                    this.firebaseDatabase,
                                    this.firebaseAuth,
                                    firebaseUser,
                                    backedPlayers,
                                    email
                                )
                            );
                        })
                        .catch((errorMessage) => {
                            console.log('Create User Error: ', errorMessage);
                            callbacks.onFailure(errorMessage);
                        });
                } else {
                    callbacks.onFailure('Failed to generate user, please try again');
                }
            })
            .catch((authError) => {
                let errorMessage = 'An error occurred, please try again';
                if (authError.code === 'auth/invalid-email') {
                    errorMessage = 'Invalid email, please try again';
                } else if (authError.code === 'auth/email-already-in-use') {
                    errorMessage = 'An account already exists for this email, please log in';
                } else if (authError.code === 'auth/weak-password') {
                    errorMessage = 'Please provide a password with more than 6 characters';
                } else if (authError.code === 'auth/wrong-password') {
                    errorMessage = 'Invalid password, please try again';
                } else if (authError.code === 'auth/user-not-found') {
                    errorMessage = 'No account found for this email';
                }
                callbacks.onFailure(errorMessage);
            });
    }

    sendPasswordResetEmail(email) {
        return this.firebaseAuth.sendPasswordResetEmail(email);
    }

    loadUserFrom(
        firebaseUser: firebase.User,
        onSuccess: (FirebaseBackedUser) => void,
        onFailure: (string) => void
    ) {
        const uid = firebaseUser.uid;
        const userPath = `users/${uid}/public`;
        const resolver = new FirebaseItemResolver<PublicUser>(
            uid,
            this.firebaseDatabase.ref(userPath),
            new FirebasePublicUserTranslator(uid, this.firebaseDatabase, this.sportProvider)
        );
        resolver.resolve(
            (publicUser) => {
                onSuccess(
                    new FirebaseBackedUser(
                        uid,
                        publicUser.name,
                        this.sportProvider,
                        this.firebaseDatabase,
                        this.firebaseAuth,
                        firebaseUser,
                        publicUser.backedPlayers,
                        firebaseUser.email ?? ''
                    )
                );
            },
            () => {
                onFailure(`Failed to load user name: ${uid}`);
            }
        );
    }
}
