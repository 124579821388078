import axios from 'axios';
import { Competition } from '../../../interfaces/Competition';
import {
    CompetitionEntryInvitation,
    CompetitionTeamManager,
    RemoveTeamError,
} from '../../../interfaces/Competitions/CompetitionAdmin';
import {
    CompetitionEntry,
    CompetitionEntryMetadata,
} from '../../../interfaces/Competitions/CompetitionEntry';
import { CompetitionEntryRequest } from '../../../interfaces/Competitions/CompetitionEntryRequest';
import { ListProvider } from '../../../interfaces/ListProvider';
import { Resolver } from '../../../interfaces/Resolver';
import { PlayerMetadata } from '../../../interfaces/RosterEntry';
import { Team } from '../../../interfaces/Team';
import { User } from '../../../interfaces/User';
import { FirebaseBackedCompetition } from '../../FirebaseBackedCompetition';

export class FirebaseBackedCompetitionTeamManager implements CompetitionTeamManager {
    user: User;
    competition: FirebaseBackedCompetition;
    entryRequestProvider: ListProvider<Resolver<CompetitionEntryRequest>>;
    entryInvitationProvider: ListProvider<Resolver<CompetitionEntryInvitation>>;

    constructor(
        user: User,
        competition: FirebaseBackedCompetition,
        entryRequestProvider: ListProvider<Resolver<CompetitionEntryRequest>>,
        entryInvitationProvider: ListProvider<Resolver<CompetitionEntryInvitation>>
    ) {
        this.user = user;
        this.competition = competition;
        this.entryRequestProvider = entryRequestProvider;
        this.entryInvitationProvider = entryInvitationProvider;
    }

    acceptEntryRequest(request: CompetitionEntryRequest) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                competitionID: this.competition.id,
                requestID: request.id,
            };
            var apiPath = '/api/competitions/acceptEntryRequest';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const competitionEntryID = response.data.entryID;
                if (response.status === 200 && competitionEntryID) {
                    return this.competition.sportProvider
                        .competitionEntryResolver(this.competition.id, competitionEntryID)
                        .asAPromise();
                } else if (response.status === 403) {
                    return Promise.reject(RemoveTeamError.missingPermission);
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    inviteEntryAdmin(email: string, competitionEntry: CompetitionEntry) {
        return this.user.fetchVerificationToken().then((token) => {
            const competitionName = competitionEntry.competition.attributes.name;
            const attributesText =
                this.competition.sportProvider.displayTextForTeamOrCompetitionAttributes(
                    competitionEntry.competition.attributes
                );
            const overrideCompetitionName = `${competitionName}, ${attributesText}`;
            const body = {
                userToken: token,
                competitionID: this.competition.id,
                competitionEntryID: competitionEntry.id,
                email,
                overrideCompetitionName,
            };
            var apiPath = '/api/competitions/inviteEntry';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    if (response.status === 201) {
                        const invitationID = response.data['invitationID'] ?? '';
                        return this.competition.sportProvider
                            .competitionInvitationResolver(invitationID)
                            .asAPromise();
                    } else {
                        return Promise.reject(`Entry invitation failed: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(`inviteEntryAdmin failed: ${error}`);
                });
        });
    }

    removeEntryAdminInvitation(invitation: CompetitionEntryInvitation) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                competitionID: this.competition.id,
                invitationID: invitation.id,
            };
            var apiPath = '/api/competitions/removeEntryInvitation';
            return axios
                .post(apiPath, body)
                .then((response) => {
                    if (response.status === 200) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject(`Entry invitation failed: ${response.status}`);
                    }
                })
                .catch((error) => {
                    return Promise.reject(`inviteEntryAdmin failed: ${error}`);
                });
        });
    }

    createEntry(metadata: CompetitionEntryMetadata, roster: PlayerMetadata[]) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                competitionID: this.competition.id,
                userToken: token,
                entry: {
                    metadata,
                    roster: roster.reduce((currentRosterData, player) => {
                        currentRosterData[player.id] = {
                            name: player.name,
                            capNumber: player.capNumber.databaseValue,
                            position: player.position.databaseValue,
                            externalID: (typeof player.externalID === 'string' || typeof player.externalID === 'number') ? player.externalID : null,
                        };
                        return currentRosterData;
                    }, {}),
                },
            };

            var apiPath = `/api/competitions/createEntry`;
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 201) {
                    const competitionEntryID = response.data.competitionEntryID || '';
                    return this.competition.sportProvider
                        .competitionEntryResolver(this.competition.id, competitionEntryID)
                        .asAPromise();
                } else if (response.status === 403) {
                    return Promise.reject(RemoveTeamError.missingPermission);
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    addTeamEntry(team: Team): Promise<CompetitionEntry> {
        return this.user.fetchVerificationToken().then((token) => {
            return team.playerProvider
                .once()
                .then((playerResolvers) => {
                    return Promise.all(
                        playerResolvers.map((resolver) => {
                            return resolver.asAPromise();
                        })
                    );
                })
                .then((players) => {
                    const rosterData = players.reduce((currentRosterData, player) => {
                        currentRosterData[player.id] = {
                            name: player.name,
                            capNumber: player.capNumber.databaseValue,
                            position: player.position.databaseValue,
                            backingPlayer: player.id,
                        };
                        return currentRosterData;
                    }, {});

                    const body = {
                        competitionID: this.competition.id,
                        eventID: this.competition.event.id,
                        userToken: token,
                        teamID: team.id,
                        roster: rosterData,
                    };
                    var apiPath = `/api/competitions/addTeamEntry`;
                    if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                        apiPath = 'http://localhost:3000' + apiPath;
                    }
                    return axios.post(apiPath, body).then((response) => {
                        const status = response.status;
                        if (status === 201) {
                            const competitionEntryID = response.data.competitionEntryID || '';
                            return this.competition.sportProvider
                                .competitionEntryResolver(this.competition.id, competitionEntryID)
                                .asAPromise();
                        } else if (response.status === 403) {
                            return Promise.reject(RemoveTeamError.missingPermission);
                        } else {
                            return Promise.reject(response);
                        }
                    });
                });
        });
    }

    removeEntry(competitionEntry: CompetitionEntry, removeMatches: boolean) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                competitionID: this.competition.id,
                entryID: competitionEntry.id,
                removeMatches: removeMatches,
                userToken: token,
            };
            var apiPath = '/api/competitions/removeEntry';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    return Promise.resolve();
                } else if (response.status === 403) {
                    return Promise.reject(RemoveTeamError.missingPermission);
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }
}
