import { TeamRecord, Competition } from '../interfaces/Competition';
import { ListProvider } from '../interfaces/ListProvider';
import { MatchSegmentStats } from '../interfaces/Stat';

import { SportProvider } from '../interfaces/SportProvider';
import firebase from 'firebase';

export function isMatchStats(
    candidate: MatchSegmentStats[] | string
): candidate is MatchSegmentStats[] {
    return (candidate as MatchSegmentStats[]).length !== undefined;
}

export default class FirebaseBackedCompetitionStandingsProvider
    implements ListProvider<TeamRecord>
{
    database: firebase.database.Database;
    sportProvider: SportProvider;
    competition: Competition;

    constructor(
        database: firebase.database.Database,
        competition: Competition,
        sportProvider: SportProvider
    ) {
        this.database = database;
        this.competition = competition;
        this.sportProvider = sportProvider;
    }

    addListener(callback) {
        const promise = this.once();
        promise
            .then((teamRecords) => {
                callback(teamRecords);
            })
            .catch((error) => {
                callback([]);
            });
    }

    removeListener(token) {}

    once() {
        const teamRecordRef = this.database.ref(
            `competitions/${this.competition.id}/cachedData/teamRecords`
        );
        return teamRecordRef.once('value').then((teamRecordsSnapshot) => {
            const teamRecordVal = teamRecordsSnapshot.val();
            if (!teamRecordVal) {
                return Promise.resolve([]);
            } else {
                return this.competition.teamProvider.once().then((competitionEntryResolvers) => {
                    const competitionEntryMap = competitionEntryResolvers.reduce(
                        (currentMap, competitionEntryResolver) => {
                            currentMap[competitionEntryResolver.id] = competitionEntryResolver;
                            return currentMap;
                        },
                        {}
                    );
                    var teamRecordPromises: Promise<TeamRecord>[] = [];
                    for (const teamID in teamRecordVal) {
                        const teamRecord = teamRecordVal[teamID];
                        const entryResolver = competitionEntryMap[teamID];
                        if (teamRecord && entryResolver) {
                            teamRecordPromises.push(
                                entryResolver.asAPromise().then((matchEntry) => {
                                    return {
                                        team: matchEntry,
                                        wins: teamRecord['w'] || 0,
                                        losses: teamRecord['l'] || 0,
                                        draws: teamRecord['d'] || 0,
                                    };
                                })
                            );
                        }
                    }
                    return Promise.allSettled(teamRecordPromises)
                        .then((teamRecordResults) => {
                            var teamRecords: TeamRecord[] = [];
                            teamRecordResults.forEach((result) => {
                                if (result.status === 'fulfilled') {
                                    teamRecords.push(result.value);
                                }
                            });
                            return teamRecords.sort((left, right) => {
                                const winDiff = right.wins - left.wins;
                                if (winDiff !== 0) {
                                    return winDiff;
                                } else {
                                    return left.losses - right.losses;
                                }
                            });
                        })
                        .catch((error) => {
                            return Promise.reject(error);
                        });
                });
            }
        });
    }
}
