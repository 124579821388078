import { ListProvider } from './ListProvider';
import { MatchEntry } from './MatchEntry';
import { RosterEntry } from './RosterEntry';
import { Match, MatchSegment } from './Match';
import { PublicUser } from './User';

export interface TableWorker {
    user: PublicUser;
}

export function isTableWorker(candidate: TableWorker | any): candidate is TableWorker {
    return (candidate as TableWorker).user !== undefined;
}

export interface Stat {
    id: string;

    description: StatTypeDescribing;
    displayName: string;
    abbreviation: string;
    scoreValue: number;

    match: Match;

    team?: MatchEntry;
    player?: RosterEntry;

    performer: MatchEntry | RosterEntry | TableWorker;

    remarks?: string;

    matchSegment: MatchSegment;
    timestamp: number;

    creationTime: Date;
    lastUpdateTime?: Date;
}

export enum PerformerTypes {
    team = 'TEAM',
    player = 'PLAYER',
    tableWorker = 'TABLE_WORKER',
}

export interface StatTypeDescribing {
    dataType: StatType;
    dataSubType?: StatType;
    supportedPerfomers: Set<PerformerTypes>;

    matchesDescription: (description: StatTypeDescribing) => boolean;
}

export interface StatType {
    databaseValue: string;
    displayName: string;
    abbreviation: string;

    scoreValue: number;
}

export interface MatchSegmentStats {
    matchSegment: MatchSegment;
    stats: Stat[];
}

export interface SortedStatProvider extends ListProvider<MatchSegmentStats> {}

export class StatTypeDescription implements StatTypeDescribing {
    dataType: StatType;
    dataSubType?: StatType;
    supportedPerfomers: Set<PerformerTypes>;

    constructor(supportedPerfomers: Set<PerformerTypes>, dataType: StatType, subtype?: StatType) {
        this.dataType = dataType;
        this.dataSubType = subtype;
        this.supportedPerfomers = supportedPerfomers;
    }

    matchesDescription(incomingDescription: StatTypeDescribing) {
        const statType = this.dataType;
        const subtype = this.dataSubType;
        if (subtype) {
            return (
                !!incomingDescription.dataSubType &&
                incomingDescription.dataSubType.databaseValue === subtype.databaseValue &&
                incomingDescription.dataType.databaseValue === statType.databaseValue
            );
        } else {
            return incomingDescription.dataType.databaseValue === statType.databaseValue;
        }
    }
}
