import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type PlacementType = 'auto' | 'top' | 'bottom' | 'left' | 'right';
interface Props {
    placement: PlacementType;
    displayText: string;
    id: string;
    show?: boolean;
    children: ReactElement;
}

//NOTE: do get a console.error from popper due to show being able to be undefined. This doesn't effect the rendering of the trigger & is really a react-bootstrap error when it's being configured with the popper library.
const CustomToolTip = ({ placement, displayText, id, show, children }: Props) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={`tooltip--${id}`}>{displayText}</Tooltip>}
            show={show}
        >
            {children}
        </OverlayTrigger>
    );
};

export default CustomToolTip;
