import { ListProvider } from '../../../../interfaces/ListProvider';

export class CombinedListProvider<T> implements ListProvider<T> {
    primaryListProvider: ListProvider<T>;
    secondaryListProvider: ListProvider<T>;

    secondaryListItems?: T[];

    constructor(primaryListProvider: ListProvider<T>, secondaryListProvider: ListProvider<T>) {
        this.primaryListProvider = primaryListProvider;
        this.secondaryListProvider = secondaryListProvider;
    }

    addListener(callback) {
        return this.primaryListProvider.addListener((primaryItems) => {
            return this.resolveSecondaryItems().then((secondaryItems) => {
                callback(primaryItems.concat(secondaryItems));
            });
        });
    }

    removeListener(token) {
        this.primaryListProvider.removeListener(token);
    }

    once() {
        return this.primaryListProvider.once().then((primaryResolvedItems) => {
            return this.secondaryListProvider.once().then((secondaryResolvedItems) => {
                return primaryResolvedItems.concat(secondaryResolvedItems);
            });
        });
    }

    resolveSecondaryItems() {
        if (this.secondaryListItems) {
            return Promise.resolve(this.secondaryListItems);
        } else {
            return this.secondaryListProvider.once().then((secondaryItems) => {
                this.secondaryListItems = secondaryItems;
                return secondaryItems;
            });
        }
    }
}
