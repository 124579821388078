import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { ListProvider, ListProviderCallback } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';

export class FirebaseBackedListProvider<ItemType> implements ListProvider<Resolver<ItemType>> {
    databaseReference: firebase.database.Reference;
    resolverCache: ResolverCache<ItemType>;

    constructor(
        databaseReference: firebase.database.Reference,
        resolverProvider: (itemID: string) => Resolver<ItemType>
    ) {
        this.databaseReference = databaseReference;
        this.resolverCache = new ResolverCache(resolverProvider);
    }

    addListener(callback) {
        return this.databaseReference.on('value', (snapshot) => {
            const ids = this.childIDsFromSnapshot(snapshot);
            this.resolverCache.handleUpdate(ids, callback);
        });
    }

    removeListener(token: any) {
        this.databaseReference.off('value', token);
    }

    once() {
        return new Promise<Array<Resolver<ItemType>>>((resolve, reject) => {
            this.databaseReference.once(
                'value',
                (snapshot) => {
                    const ids = this.childIDsFromSnapshot(snapshot);
                    this.resolverCache.handleUpdate(ids, (resolvers) => {
                        resolve(resolvers);
                    });
                },
                (error) => {
                    reject('failed to generate resolvers: ' + error);
                }
            );
        });
    }

    childIDsFromSnapshot(snapshot: firebase.database.DataSnapshot) {
        const val = snapshot.val();
        var ids: string[] = [];
        for (const id in val) {
            if (typeof id === 'string' && !!val[id]) {
                ids.push(id);
            }
        }
        return ids;
    }
}

class ResolverCache<ItemType> {
    resolverProvider: (itemID: string) => Resolver<ItemType>;
    childResolvers: Map<string, Resolver<ItemType>>;

    constructor(resolverProvider: (itemID: string) => Resolver<ItemType>) {
        this.resolverProvider = resolverProvider;
        this.childResolvers = new Map();
    }

    handleUpdate(childIDs: Array<string>, callback: ListProviderCallback<Resolver<ItemType>>) {
        var updatedChildResolvers = new Map<string, Resolver<ItemType>>();
        var resolvers: Resolver<ItemType>[] = [];
        childIDs.forEach((childID) => {
            const resolver = this.childResolvers.get(childID) || this.resolverProvider(childID);
            updatedChildResolvers.set(childID, resolver);
            resolvers.push(resolver);
        });
        this.childResolvers = updatedChildResolvers;
        callback(resolvers);
    }
}
