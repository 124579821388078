import { useState, useEffect, useMemo, useCallback } from 'react';
import { User } from '../../../models/interfaces/User';
import { Competition } from '../../../models/interfaces/Competition';
import { Team } from '../../../models/interfaces/Team';
import TeamAndCompetitionNotMatchingTable from './TeamAndCompetitionNotMatchingTable';
import { Event } from '../../../models/interfaces/Event';
import { RegistrationData } from '../../interfaces/EventUI';
import SelectCompetition from './SelectCompetition';
import SelectOrganization from './SelectOrganization';
import SelectBackingTeam from './SelectBackingTeam';
import CompetitionEntryRosterDetails from './CompetitionEntryRosterDetails';
import { OrganizationAdmin } from '../../../models/interfaces/Organization';
import { SportProvider } from '../../../models/interfaces/SportProvider';

interface Props {
    user: User;
    event: Event;
    registrationData: RegistrationData;
    updateRegistrationData: <K extends keyof RegistrationData, T>(key: K, value: T) => void;
    sportProvider: SportProvider;
}

const RegistrationModalBodyDisplay = ({
    user,
    event,
    registrationData,
    updateRegistrationData,
    sportProvider,
}: Props) => {
    const [warnTeamAndCompetitionNotMatching, setWarnTeamAndCompetitionNotMatching] =
        useState(false);
    const [clearedWarning, setClearedWarning] = useState(false);

    const [organizationAdmin, setOrganizationAdmin] = useState<OrganizationAdmin | undefined>();

    useEffect(() => {
        registrationData.organization
            ?.adminForUser(user)
            .then((organizationAdmin) => {
                setOrganizationAdmin(organizationAdmin);
            })
            .catch(() => {
                setOrganizationAdmin(undefined);
            });
    }, [registrationData.organization, user]);

    const differentCategory = useMemo(() => {
        return (
            registrationData.competition?.attributes.category.databaseValue !==
            registrationData.backingTeam?.attributes.category.databaseValue
        );
    }, [
        registrationData.backingTeam?.attributes.category.databaseValue,
        registrationData.competition?.attributes.category.databaseValue,
    ]);

    const differentDivision = useMemo(() => {
        return (
            registrationData.competition?.attributes.division.databaseValue !==
            registrationData.backingTeam?.attributes.division.databaseValue
        );
    }, [
        registrationData.backingTeam?.attributes.division.databaseValue,
        registrationData.competition?.attributes.division.databaseValue,
    ]);

    const differentGender = useMemo(() => {
        return (
            registrationData.competition?.attributes.gender.databaseValue !==
            registrationData.backingTeam?.attributes.gender.databaseValue
        );
    }, [
        registrationData.backingTeam?.attributes.gender.databaseValue,
        registrationData.competition?.attributes.gender.databaseValue,
    ]);

    useEffect(() => {
        if (registrationData.competition && registrationData.backingTeam && !clearedWarning) {
            if (differentCategory || differentDivision || differentGender) {
                setWarnTeamAndCompetitionNotMatching(true);
            } else {
                setWarnTeamAndCompetitionNotMatching(false);
            }
            // if we reset all data also reset the warning state
        } else if (clearedWarning && !registrationData.competition) {
            setClearedWarning(false);
            setWarnTeamAndCompetitionNotMatching(false);
        }
    }, [registrationData, differentCategory, differentDivision, differentGender, clearedWarning]);

    const clearWarning = useCallback(() => {
        setWarnTeamAndCompetitionNotMatching(false);
        setClearedWarning(true);
    }, []);

    if (
        !warnTeamAndCompetitionNotMatching &&
        registrationData.backingTeam &&
        registrationData.organization
    )
        return (
            <CompetitionEntryRosterDetails
                organization={registrationData.organization}
                handleSelect={updateRegistrationData}
                backingTeam={registrationData.backingTeam}
                eventRoster={registrationData.roster}
                sportProvider={sportProvider}
            />
        );

    if (warnTeamAndCompetitionNotMatching && registrationData.backingTeam) {
        return (
            <>
                <TeamAndCompetitionNotMatchingTable
                    selectedCompetition={registrationData.competition as Competition}
                    selectedTeam={registrationData.backingTeam as Team}
                    differentCategory={differentCategory}
                    differentDivision={differentDivision}
                    differentGender={differentGender}
                    clearWarning={clearWarning}
                    updateRegistrationData={updateRegistrationData}
                />
            </>
        );
    }

    if (registrationData.organization) {
        return (
            <SelectBackingTeam
                organizationAdmin={organizationAdmin}
                organization={registrationData.organization}
                handleSelect={updateRegistrationData}
            />
        );
    }
    if (registrationData.competition)
        return <SelectOrganization user={user} handleSelect={updateRegistrationData} />;

    return (
        <SelectCompetition
            event={event}
            sportProvider={sportProvider}
            handleSelectCompetition={(competition) => {
                updateRegistrationData('competition', competition);
            }}
        />
    );
};

export default RegistrationModalBodyDisplay;
