import axios from 'axios';
import { CompetitionAttributes, Competition } from '../../interfaces/Competition';
import { EventCompetitionEditor, ManageCompetitionError } from '../../interfaces/Event';
import { SportProvider } from '../../interfaces/SportProvider';
import { User } from '../../interfaces/User';
import { FirebaseBackedEvent } from '../FirebaseBackedEvent';

export class FirebaseBackedEventCompetitionEditor implements EventCompetitionEditor {
    sportProvider: SportProvider;
    user: User;
    event: FirebaseBackedEvent;

    constructor(sportProvider: SportProvider, user: User, event: FirebaseBackedEvent) {
        this.sportProvider = sportProvider;
        this.user = user;
        this.event = event;
    }

    addCompetition(competitionAttributes: CompetitionAttributes) {
        return this.user
            .fetchVerificationToken()
            .then((userToken) => {
                var competitionArgs = {
                    name: competitionAttributes.name,
                    category: competitionAttributes.category.databaseValue,
                    division: competitionAttributes.division.databaseValue,
                    gender: competitionAttributes.gender.databaseValue,
                    isIndexed: competitionAttributes.isIndexed,
                    updateTeamRosterAutomatically:
                        competitionAttributes.updateTeamRosterAutomatically,
                };
                if (competitionAttributes.externalID) {
                    competitionArgs['externalID'] = competitionAttributes.externalID;
                }

                const body = {
                    eventID: this.event.id,
                    competitionAttributes: competitionArgs,
                    userToken: userToken,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/addCompetition';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 201) {
                    const competitionID = response.data.id || '';
                    return this.sportProvider.competitionResolver(competitionID).asAPromise();
                } else if (status === 400) {
                    return Promise.reject(ManageCompetitionError.missingParameters);
                } else if (status === 401) {
                    return Promise.reject(ManageCompetitionError.unauthenticated);
                } else if (status === 403) {
                    return Promise.reject(ManageCompetitionError.missingPermission);
                } else if (status === 500) {
                    return Promise.reject(ManageCompetitionError.serverFailure);
                } else {
                    return Promise.reject(status);
                }
            });
    }

    updateCompetition(competition: Competition, competitionAttributes: CompetitionAttributes) {
        return this.user
            .fetchVerificationToken()
            .then((userToken) => {
                const body = {
                    eventID: this.event.id,
                    competitionID: competition.id,
                    competitionAttributes: {
                        name: competitionAttributes.name,
                        category: competitionAttributes.category.databaseValue,
                        division: competitionAttributes.division.databaseValue,
                        gender: competitionAttributes.gender.databaseValue,
                        isIndexed: competitionAttributes.isIndexed,
                        updateTeamRosterAutomatically:
                            competitionAttributes.updateTeamRosterAutomatically,
                    },
                    userToken: userToken,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/updateCompetition';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return this.sportProvider.competitionResolver(competition.id).asAPromise();
                } else if (status === 400) {
                    return Promise.reject(ManageCompetitionError.missingParameters);
                } else if (status === 401) {
                    return Promise.reject(ManageCompetitionError.unauthenticated);
                } else if (status === 403) {
                    return Promise.reject(ManageCompetitionError.missingPermission);
                } else if (status === 500) {
                    return Promise.reject(ManageCompetitionError.serverFailure);
                } else {
                    return Promise.reject(status);
                }
            });
    }

    removeCompetition(competition: Competition) {
        return this.user
            .fetchVerificationToken()
            .then((userToken) => {
                const body = {
                    competitionID: competition.id,
                    userToken: userToken,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/removeCompetition';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else if (status === 400) {
                    return Promise.reject(ManageCompetitionError.missingParameters);
                } else if (status === 401) {
                    return Promise.reject(ManageCompetitionError.unauthenticated);
                } else if (status === 403) {
                    return Promise.reject(ManageCompetitionError.missingPermission);
                } else if (status === 404) {
                    return Promise.reject(ManageCompetitionError.notFound);
                } else if (status === 500) {
                    return Promise.reject(ManageCompetitionError.serverFailure);
                } else {
                    return Promise.reject(status);
                }
            });
    }
}
