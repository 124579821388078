import { Link, useHistory, useLocation } from 'react-router-dom';

import {
    User,
    VendorAccountContext,
    vendorAccountContextForString,
} from '../../models/interfaces/User';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { VendorAccount, VendorOnboarding } from '../../models/interfaces/Vendors/VendorAccount';

export enum VendorTask {
    verifyAccount,
    refreshLinks,
    view,
}

interface Props {
    user: User;
    task: VendorTask;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function UserVendorPage({ user, task }: Props) {
    const history = useHistory();
    const [vendorAccount, setVendorAccount] = useState<VendorAccount | undefined>(undefined);
    const [vendorOnboarding, setVendorOnboarding] = useState<VendorOnboarding | undefined>(
        undefined
    );
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const [vendorAccountContext, setVendorAccountContext] = useState<VendorAccountContext>();

    const query = useQuery();
    useEffect(() => {
        if (query) {
            const acconutContextValue = query.get('accountContext') || undefined;
            if (acconutContextValue) {
                setVendorAccountContext(vendorAccountContextForString(acconutContextValue));
            } else {
                setVendorAccountContext(VendorAccountContext.account);
            }
        }
    }, [query]);

    useEffect(() => {
        if (vendorAccountContext) {
            const accountContext = vendorAccountContext ?? VendorAccountContext.account;
            var allowsAccountCreation = false;
            var generateLinks = false;
            switch (task) {
                case VendorTask.view:
                    allowsAccountCreation = false;
                    generateLinks = true;
                    break;
                case VendorTask.verifyAccount:
                    allowsAccountCreation = false;
                    generateLinks = false;
                    break;
                case VendorTask.refreshLinks:
                    allowsAccountCreation = false;
                    generateLinks = true;
            }
            user.fetchVendorAccount(allowsAccountCreation, generateLinks, accountContext)
                .then(([vendorAccount, vendorOnboarding]) => {
                    if (task == VendorTask.verifyAccount) {
                        if (vendorAccount.chargesEnabled) {
                            switch (accountContext) {
                                case VendorAccountContext.createEvent:
                                    history.push(`/createEvent`);
                                    break;
                            }
                        } else {
                            setErrorMessage('Account not verfied, please try again');
                        }
                    }
                    setVendorAccount(vendorAccount);
                    if (vendorOnboarding) {
                        setVendorOnboarding(vendorOnboarding);
                        if (task == VendorTask.refreshLinks) {
                            window.location.href = vendorOnboarding.onboardingURL;
                        }
                    }
                })
                .catch((error) => {
                    setErrorMessage(`Failed to resolved vendor account: ${error}`);
                });
        }
    }, [user.id, vendorAccountContext]);

    return (
        <Container>
            <Col>
                {errorMessage && <Row>{errorMessage}</Row>}
                {vendorOnboarding && (
                    <Row className="mb-2">
                        <Button
                            onClick={() => {
                                window.location.href = vendorOnboarding.onboardingURL;
                            }}
                        >
                            Update payment account
                        </Button>
                    </Row>
                )}
                {vendorAccount ? (
                    <>
                        <Row>
                            Payments from teams enabled:{' '}
                            {vendorAccount.chargesEnabled ? 'true' : 'false'}
                        </Row>
                        <Row>
                            Payments to your account enabled:{' '}
                            {vendorAccount.payoutsEnabled ? 'true' : 'false'}
                        </Row>
                    </>
                ) : (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </Col>
        </Container>
    );
}
