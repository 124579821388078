import { CompetitionPlayerLeaderboard, CompetitionPlayerSummary } from '../interfaces/Competition';
import { RosterEntry } from '../interfaces/RosterEntry';
import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { CompetitionPlayerStatDescription } from '../interfaces/ViewDescriptions/MatchViewDescription';
import { Stat } from '../interfaces/Stat';
import firebase from 'firebase';

export class FirebaseBackedCompetitionLeadersProvider
    implements ListProvider<CompetitionPlayerLeaderboard>
{
    databaseRef: firebase.database.Reference;
    playerLeaderDescriptions: CompetitionPlayerStatDescription[];
    rosterEntryProvider: (playerID: string, competitionEntryID: string) => Promise<RosterEntry>;
    statResolverProvider: (statID) => Resolver<Stat>;

    constructor(
        databaseRef: firebase.database.Reference,
        playerLeaderDescriptions: CompetitionPlayerStatDescription[],
        rosterEntryProvider: (playerID: string, competitionEntryID: string) => Promise<RosterEntry>,
        statResolverProvider: (statID) => Resolver<Stat>
    ) {
        this.databaseRef = databaseRef;
        this.playerLeaderDescriptions = playerLeaderDescriptions;
        this.rosterEntryProvider = rosterEntryProvider;
        this.statResolverProvider = statResolverProvider;
    }

    addListener(callback) {
        return this.databaseRef.on('value', (snapshot) => {
            this.leadersFromSnapshot(snapshot).then((leaderBoards) => {
                callback(leaderBoards);
            });
        });
    }

    removeListener(token) {
        this.databaseRef.off('value', token);
    }

    once() {
        return this.databaseRef.once('value').then((snapshot) => {
            return this.leadersFromSnapshot(snapshot);
        });
    }

    leadersFromSnapshot(snapshot: firebase.database.DataSnapshot) {
        const val = snapshot.val();
        var leaderboardPromises: Promise<CompetitionPlayerLeaderboard>[] = [];
        for (const snapshotChildID in val) {
            this.playerLeaderDescriptions.forEach((playerLeaderDescription) => {
                if (playerLeaderDescription.id === snapshotChildID) {
                    const playerLeaderValues = val[snapshotChildID];
                    var playerPromises: Promise<CompetitionPlayerSummary>[] = [];
                    for (const playerID in playerLeaderValues) {
                        const valueForPlayer = playerLeaderValues[playerID];
                        const statDictionary = valueForPlayer['stats'];
                        const statIDs: string[] = [];
                        for (const statID in statDictionary) {
                            statIDs.push(statID);
                        }
                        const weight = valueForPlayer['weight'];
                        const teamID = valueForPlayer['team'];
                        const playerPromise = this.rosterEntryProvider(playerID, teamID);
                        playerPromises.push(
                            playerPromise.then((player) => {
                                return {
                                    player: player,
                                    weight: weight,
                                    statResolvers: statIDs.map((statID) => {
                                        return this.statResolverProvider(statID);
                                    }),
                                };
                            })
                        );
                    }
                    leaderboardPromises.push(
                        Promise.allSettled(playerPromises)
                            .then((playerSummaryResults) => {
                                return playerSummaryResults.flatMap((playerSummaryResult) => {
                                    if (playerSummaryResult.status === 'fulfilled') {
                                        return [playerSummaryResult.value];
                                    } else {
                                        return [];
                                    }
                                });
                            })
                            .then((competitionPlayerSummaries) => {
                                return {
                                    id: playerLeaderDescription.id,
                                    sortOrder: playerLeaderDescription.sortOrder,
                                    title: playerLeaderDescription.title,
                                    players: competitionPlayerSummaries
                                        .filter((summary) => {
                                            return (
                                                summary.weight > 0 &&
                                                summary.statResolvers.length > 0
                                            );
                                        })
                                        .sort((left, right) => {
                                            return right.weight - left.weight;
                                        }),
                                    displayTextForStatResolvers: (statResolvers) => {
                                        return playerLeaderDescription.textFormatter(statResolvers);
                                    },
                                };
                            })
                    );
                }
            });
        }
        return Promise.all(leaderboardPromises);
    }
}
