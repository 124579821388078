import { FirebaseItemTranslator } from './FirebaseItemResolver';
import { PublicUser } from '../../interfaces/User';
import firebase from 'firebase';
import { SportProvider } from '../../interfaces/SportProvider';
import { FirebaseBackedListProvider } from '../FirebaseBackedListProvider';

export class FirebasePublicUserTranslator implements FirebaseItemTranslator<PublicUser> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;

    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (PublicUser) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val() || {};
        const name = val['name'] || {};
        const firstName = name['firstName'];
        const lastName = name['lastName'];
        const userPlayersReference = this.database.ref(`/users/${this.id}/players`);
        const backedPlayers = new FirebaseBackedListProvider(userPlayersReference, (eventID) => {
            return this.sportProvider.playerResolver(eventID);
        });
        if (firstName && lastName) {
            const publicUser: PublicUser = {
                id: this.id,
                name: {
                    firstName: firstName,
                    lastName: lastName,
                },
                backedPlayers: backedPlayers,
            };
            onSuccess(publicUser);
        } else {
            onFailure(`Failed to load user name: ${this.id}`);
        }
    }
}
