import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { SportProvider } from '../interfaces/SportProvider';
import { Stat, SortedStatProvider, MatchSegmentStats } from '../interfaces/Stat';

export class FirebaseBackedMatchStatProvider implements SortedStatProvider {
    listProvider: ListProvider<Resolver<Stat>>;
    sportProvider: SportProvider;

    constructor(sportProvider: SportProvider, listProvider: ListProvider<Resolver<Stat>>) {
        this.sportProvider = sportProvider;
        this.listProvider = listProvider;
    }

    addListener(callback) {
        return this.listProvider.addListener((statResolvers) => {
            const promises: Array<Promise<Stat | string>> = this.statPromises(statResolvers);
            Promise.all(promises).then((resolvedPromises) => {
                var bucketedStats = new Map<number, Stat[]>();
                resolvedPromises.forEach((resolvedObject) => {
                    if (typeof resolvedObject === 'string') {
                    } else {
                        const matchSegment = resolvedObject.matchSegment;
                        var statsForMatchSegment = bucketedStats.get(matchSegment.databaseValue);
                        if (!statsForMatchSegment) {
                            statsForMatchSegment = [resolvedObject];
                        } else {
                            statsForMatchSegment.push(resolvedObject);
                        }
                        bucketedStats.set(matchSegment.databaseValue, statsForMatchSegment);
                    }
                });
                var matchStats = new Array<MatchSegmentStats>();
                bucketedStats.forEach((stats, _) => {
                    if (stats.length > 0) {
                        const stat = stats[0];
                        const matchSegment = stat.matchSegment;
                        if (matchSegment) {
                            matchStats.push({
                                matchSegment: matchSegment,
                                stats: stats.sort((left, right) => {
                                    return left.timestamp - right.timestamp;
                                }),
                            });
                        }
                    }
                });
                matchStats = matchStats.sort((left, right) => {
                    return right.matchSegment.databaseValue - left.matchSegment.databaseValue;
                });
                callback(matchStats);
            });
        });
    }

    removeListener(token) {
        this.listProvider.removeListener(token);
    }

    once() {
        return new Promise<Array<MatchSegmentStats>>((resolve, reject) => {
            this.listProvider.once().then((statResolvers) => {
                const promises: Array<Promise<Stat | string>> = this.statPromises(statResolvers);
                Promise.all(promises).then((resolvedPromises) => {
                    var bucketedStats = new Map<number, Stat[]>();
                    resolvedPromises.forEach((resolvedObject) => {
                        if (typeof resolvedObject === 'string') {
                        } else {
                            const matchSegment = resolvedObject.matchSegment;
                            var statsForMatchSegment = bucketedStats.get(
                                matchSegment.databaseValue
                            );
                            if (!statsForMatchSegment) {
                                statsForMatchSegment = [resolvedObject];
                            } else {
                                statsForMatchSegment.push(resolvedObject);
                            }
                            bucketedStats.set(matchSegment.databaseValue, statsForMatchSegment);
                        }
                    });
                    var matchStats = new Array<MatchSegmentStats>();
                    bucketedStats.forEach((stats, _) => {
                        if (stats.length > 0) {
                            const stat = stats[0];
                            const matchSegment = stat.matchSegment;
                            if (matchSegment) {
                                matchStats.push({
                                    matchSegment: matchSegment,
                                    stats: stats.sort((left, right) => {
                                        return left.timestamp - right.timestamp;
                                    }),
                                });
                            }
                        }
                    });
                    matchStats = matchStats.sort((left, right) => {
                        return right.matchSegment.databaseValue - left.matchSegment.databaseValue;
                    });
                    resolve(matchStats);
                });
            });
        });
    }

    statPromises(statResolvers) {
        return statResolvers.map((statResolver) => {
            return new Promise<Stat | string>((resolve, reject) => {
                statResolver.resolve(
                    (stat) => {
                        resolve(stat);
                    },
                    (errorMessage) => {
                        // Note, allSettled isn't implemented so we just resolve all and typecheck later
                        resolve(errorMessage);
                    }
                );
            });
        });
    }
}
