import { ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MatchView from '../../../components/containers/MatchView';
import { Competition } from '../../../models/interfaces/Competition';
import { Match } from '../../../models/interfaces/Match';

interface Props {
    competition: Competition;
    matches?: Match[];
    noMatchText: string;
}

export default function EventCompetitionMatchView({ competition, matches, noMatchText }: Props) {
    return matches ? (
        matches.length == 0 ? (
            <h5 className="mt-1">
                <i>{noMatchText}</i>
            </h5>
        ) : (
            <ListGroup>
                {matches.map((match) => {
                    return (
                        <ListGroupItem key={'competitionMatch' + match.id}>
                            <Link to={`/matches/${match.id}`}>
                                <MatchView match={match} showScore showCompetition={false} />
                            </Link>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        )
    ) : (
        <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
    );
}
